import { TextField, Button } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { setQrPrintConfig } from '../../Actions';
import { AlertContext } from '../../Context/AlertContextProvider';

export const QrPrintSetting = (props) => {
  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);

  const [margins, setMargins] = useState(props.margins);
  const [marginsY, setMarginsY] = useState({ ...margins.y });
  const [marginsX, setMarginsX] = useState({ ...margins.x });
  const offsetY = [180, 160, 140, 120, 100, 80];
  const defaultMargins = {
    x: [46, 168, 292, 415, 537, 660],
    y: [60, 192, 324, 454],
  };

  const Grid = styled.div`
    width: 75px;
    height: 75px;
    border-radius: 10px;
    position: absolute;
    border: 1px solid red;
  `;
  const Row = styled.div`
    width: 75px;
    height: 75px;
    border-radius: 10px;
    box-shadow: 0 0 7px;
    position: absolute;
    background-color: #fff;
    cursor: pointer;
    margin: 0px;
  `;

  const handleSubmit = () => {
    setQrPrintConfig(marginsX, marginsY)
      .then(() => {
        setMessage('Margins updated');
        setMessageType('success');
        handleAlertOpen();
      })
      .catch(() => {
        setMessage('Failed to update margins');
        setMessageType('error');
        handleAlertOpen();
      });
  };

  const handleDefault = () => {
    setMarginsX(defaultMargins.x);
    setMarginsY(defaultMargins.y);
  };

  const handleReset = () => {
    setMarginsY({ ...margins.y });
    setMarginsX({ ...margins.x });
  };

  const handleRowOne = (e) => {
    setMarginsX((marginsX) => ({
      ...marginsX,
      0: e,
    }));
  };

  const handleRowTwo = (e) => {
    setMarginsX((marginsX) => ({
      ...marginsX,
      1: e,
    }));
  };

  const handleRowThree = (e) => {
    setMarginsX((marginsX) => ({
      ...marginsX,
      2: e,
    }));
  };

  const handleRowFour = (e) => {
    setMarginsX((marginsX) => ({
      ...marginsX,
      3: e,
    }));
  };

  const handleRowFive = (e) => {
    setMarginsX((marginsX) => ({
      ...marginsX,
      4: e,
    }));
  };

  const handleRowSix = (e) => {
    setMarginsX((marginsX) => ({
      ...marginsX,
      5: e,
    }));
  };

  const handleColOne = (e) => {
    setMarginsY((marginsY) => ({
      ...marginsY,
      0: e,
    }));
  };

  const handleColTwo = (e) => {
    setMarginsY((marginsY) => ({
      ...marginsY,
      1: e,
    }));
  };

  const handleColThree = (e) => {
    setMarginsY((marginsY) => ({
      ...marginsY,
      2: e,
    }));
  };

  const handleColFour = (e) => {
    setMarginsY((marginsY) => ({
      ...marginsY,
      3: e,
    }));
  };
  //((marginsY[0] - 60)* .1).toFixed(2)
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ display: 'flex', paddingTop: 20 }}>
          <TextField
            type="range"
            value={marginsY[0]}
            label={((marginsY[0] - 60) * 0.25).toFixed(2) + 'mm'}
            style={{ width: 50, left: 385 }}
            InputProps={{
              inputProps: { min: 30, max: 90 },
              disableUnderline: true,
            }}
            onChange={(e) => handleColOne(Number(e.target.value))}
          />
          <TextField
            type="range"
            value={marginsY[1]}
            label={((marginsY[1] - 192) * 0.25).toFixed(2) + 'mm'}
            style={{ width: 50, left: 440 }}
            InputProps={{
              inputProps: { min: 162, max: 222 },
              disableUnderline: true,
            }}
            onChange={(e) => handleColTwo(Number(e.target.value))}
          />
          <TextField
            type="range"
            value={marginsY[2]}
            label={((marginsY[2] - 324) * 0.25).toFixed(2) + 'mm'}
            style={{ width: 50, left: 500 }}
            InputProps={{
              inputProps: { min: 294, max: 354 },
              disableUnderline: true,
            }}
            onChange={(e) => handleColThree(Number(e.target.value))}
          />
          <TextField
            type="range"
            value={marginsY[3]}
            label={((marginsY[3] - 454) * 0.25).toFixed(2) + 'mm'}
            style={{ width: 50, left: 560 }}
            InputProps={{
              inputProps: { min: 424, max: 484 },
              disableUnderline: true,
            }}
            onChange={(e) => handleColFour(Number(e.target.value))}
          />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            type="range"
            value={marginsX[0]}
            label={((marginsX[0] - 46) * 0.25).toFixed(2) + 'mm'}
            InputProps={{
              inputProps: { min: 16, max: 76 },
              disableUnderline: true,
            }}
            style={{ width: 50, top: 180 }}
            onChange={(e) => handleRowOne(Number(e.target.value))}
          />
          <TextField
            type="range"
            value={marginsX[1]}
            label={((marginsX[1] - 168) * 0.25).toFixed(2) + 'mm'}
            InputProps={{
              inputProps: { min: 138, max: 198 },
              disableUnderline: true,
            }}
            style={{ width: 50, top: 230 }}
            onChange={(e) => handleRowTwo(Number(e.target.value))}
          />
          <TextField
            type="range"
            value={marginsX[2]}
            label={((marginsX[2] - 292) * 0.25).toFixed(2) + 'mm'}
            InputProps={{
              inputProps: { min: 262, max: 322 },
              disableUnderline: true,
            }}
            style={{ width: 50, top: 280 }}
            onChange={(e) => handleRowThree(Number(e.target.value))}
          />
          <TextField
            type="range"
            value={marginsX[3]}
            label={((marginsX[3] - 415) * 0.25).toFixed(2) + 'mm'}
            InputProps={{
              inputProps: { min: 385, max: 445 },
              disableUnderline: true,
            }}
            style={{ width: 50, top: 340 }}
            onChange={(e) => handleRowFour(Number(e.target.value))}
          />
          <TextField
            type="range"
            value={marginsX[4]}
            label={((marginsX[4] - 537) * 0.25).toFixed(2) + 'mm'}
            InputProps={{
              inputProps: { min: 507, max: 567 },
              disableUnderline: true,
            }}
            style={{ width: 50, top: 390 }}
            onChange={(e) => handleRowFive(Number(e.target.value))}
          />
          <TextField
            type="range"
            value={marginsX[5]}
            label={((marginsX[5] - 660) * 0.25).toFixed(2) + 'mm'}
            InputProps={{
              inputProps: { min: 630, max: 690 },
              disableUnderline: true,
            }}
            style={{ width: 50, top: 440 }}
            onChange={(e) => handleRowSix(Number(e.target.value))}
          />
        </div>
        <div
          style={{
            height: 750,
            width: 500,
            border: '2px #000 solid',
            margin: 70,
          }}
        >
          <Row style={{ top: marginsX[0] + 180, left: marginsY[0] + 310 }} />
          <Row style={{ top: marginsX[1] + 160, left: marginsY[0] + 310 }} />
          <Row style={{ top: marginsX[2] + 140, left: marginsY[0] + 310 }} />
          <Row style={{ top: marginsX[3] + 120, left: marginsY[0] + 310 }} />
          <Row style={{ top: marginsX[4] + 100, left: marginsY[0] + 310 }} />
          <Row style={{ top: marginsX[5] + 80, left: marginsY[0] + 310 }} />

          <Row style={{ top: marginsX[0] + 180, left: marginsY[1] + 280 }} />
          <Row style={{ top: marginsX[1] + 160, left: marginsY[1] + 280 }} />
          <Row style={{ top: marginsX[2] + 140, left: marginsY[1] + 280 }} />
          <Row style={{ top: marginsX[3] + 120, left: marginsY[1] + 280 }} />
          <Row style={{ top: marginsX[4] + 100, left: marginsY[1] + 280 }} />
          <Row style={{ top: marginsX[5] + 80, left: marginsY[1] + 280 }} />

          <Row style={{ top: marginsX[0] + 180, left: marginsY[2] + 260 }} />
          <Row style={{ top: marginsX[1] + 160, left: marginsY[2] + 260 }} />
          <Row style={{ top: marginsX[2] + 140, left: marginsY[2] + 260 }} />
          <Row style={{ top: marginsX[3] + 120, left: marginsY[2] + 260 }} />
          <Row style={{ top: marginsX[4] + 100, left: marginsY[2] + 260 }} />
          <Row style={{ top: marginsX[5] + 80, left: marginsY[2] + 260 }} />

          <Row style={{ top: marginsX[0] + 180, left: marginsY[3] + 240 }} />
          <Row style={{ top: marginsX[1] + 160, left: marginsY[3] + 240 }} />
          <Row style={{ top: marginsX[2] + 140, left: marginsY[3] + 240 }} />
          <Row style={{ top: marginsX[3] + 120, left: marginsY[3] + 240 }} />
          <Row style={{ top: marginsX[4] + 100, left: marginsY[3] + 240 }} />
          <Row style={{ top: marginsX[5] + 80, left: marginsY[3] + 240 }} />

          {/* Grid */}

          {defaultMargins.x.map((col, i) => {
            return (
              <Grid
                key={i}
                style={{
                  top: col + offsetY[i],
                  left: defaultMargins.y[0] + 310,
                }}
              />
            );
          })}

          {defaultMargins.x.map((col, i) => {
            return (
              <Grid
                key={i}
                style={{
                  top: col + offsetY[i],
                  left: defaultMargins.y[1] + 280,
                }}
              />
            );
          })}

          {defaultMargins.x.map((col, i) => {
            return (
              <Grid
                key={i}
                style={{
                  top: col + offsetY[i],
                  left: defaultMargins.y[2] + 260,
                }}
              />
            );
          })}

          {defaultMargins.x.map((col, i) => {
            return (
              <Grid
                key={i}
                style={{
                  top: col + offsetY[i],
                  left: defaultMargins.y[3] + 240,
                }}
              />
            );
          })}
        </div>
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'center', marginBottom: 50 }}
      >
        <Button
          variant="contained"
          style={{ margin: 10, background: '#EEEEEE', color: '#009D47' }}
          onClick={handleSubmit}
        >
          Save
        </Button>
        <Button
          variant="contained"
          style={{ margin: 10, background: '#EEEEEE', color: '#ff682c' }}
          autofocus
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button
          variant="contained"
          style={{ margin: 10, background: '#EEEEEE', color: '#ff682c' }}
          onClick={handleDefault}
        >
          Default
        </Button>
      </div>
    </div>
  );
};

export default QrPrintSetting;
