import React, { useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { baseUrl } from '../Contants';

// styles
import './styles/Notfound.css';
// import { AppContext } from "../Context/AppContextProvide";

const Notfound = () => {
  // const { isLogout } = useContext(AppContext);
  const history = useHistory();
  const handleOpenSignIn = () => {
    history.push('/signin');
  };

  useEffect(() => {}, []);

  return (
    <div className="notfound__container">
      <div>
        <div className="lading-page__main">
          {/* <div className="landing-page__customers">
            <div>
              <Typography
                className="landing-page__customer-title"
                variant="subtitle2"
                align="center"
              >
                Customers:
              </Typography>
              <div className="landing-page__cus-button">
                <Button
                  className="landing-page__btn"
                  target="block"
                  href="https://www.championfloor.com/"
                >
                  <Typography variant="button"> Visit Website</Typography>
                </Button>
              </div>
            </div>
          </div>
          <Divider className="landing-page__divider" variant="middle" /> */}
          <div className="landing-page__employee">
            <div>
              <Typography
                className="landing-page__employee-title"
                variant="h4"
                align="center"
              >
                Login
                <br></br>
                <br></br>
              </Typography>
              <div className="landing-page__employee-button">
                <Button
                  className="landing-page__btn"
                  variant="contained"
                  // href={`${baseUrl}/api/auth/google`}
                  onClick={handleOpenSignIn}
                >
                  <Typography variant="button">Login</Typography>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Notfound);
