import React, {
  useState,
  createContext,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import axios from 'axios';
import { baseUrl } from '../Contants';
import { AppContext } from './AppContextProvide';
import { HubSpokeContext } from './HubSpokeContextProvider';

export const ProductsViewedContext = createContext();

export const ProductsViewedContextProvider = ({ children }) => {
  const { user } = useContext(AppContext);
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([]);
  const [limit, setLimit] = useState(20);
  var tmpViews = useMemo(() => [], []);
  const { selectedSpoke } = useContext(HubSpokeContext);

  // /admin/accesstypes
  const isLoggedIn = user.user_id;

  useEffect(() => {
    async function getRecentlyViewedProducts() {
      const result = await axios(
        `${baseUrl}/api/stats/products/recent?limit=${limit}&siteName=${selectedSpoke}`
      );
      setRecentlyViewedProducts(result.data.data);
    }
    if (isLoggedIn) {
      getRecentlyViewedProducts();
    }
  }, [isLoggedIn, limit, tmpViews, selectedSpoke]);

  return (
    <ProductsViewedContext.Provider
      value={{ recentlyViewedProducts, setLimit }}
    >
      {children}
    </ProductsViewedContext.Provider>
  );
};
