import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import { getUser } from '../helpers';
import { baseUrl } from '../Contants';

export const TaxDisclaimerContext = createContext();

export const TaxDisclaimerContextProvider = ({ children }) => {
  const [user] = useState(getUser());
  const [tDisclaimer, setTaxDisclaimer] = useState([]);
  const [update, setTaxDisclaimerUpdate] = useState(false);

  const isAdmin = user.access_type_cd === 'a' ? true : false;

  useEffect(() => {
    async function getTDisclaimer() {
      const result = await axios(`${baseUrl}/api/admin/taxdisclaimer`);
      setTaxDisclaimer(result.data.data);
    }
    if (isAdmin) {
      getTDisclaimer();
      setTaxDisclaimerUpdate(false);
    }
  }, [isAdmin, update]);

  return (
    <TaxDisclaimerContext.Provider
      value={{ tDisclaimer, setTaxDisclaimerUpdate }}
    >
      {children}
    </TaxDisclaimerContext.Provider>
  );
};
