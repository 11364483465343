import React, { useState, useEffect, useContext } from 'react';

// MUI
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { download, JsonToCsvConverter } from '../../../Util/utilsFunctions';

import GetAppIcon from '@material-ui/icons/GetApp';

// config
import { ProductCheckoutsConfig } from '../../config';
import CustomTable from '../../Custom/CustomTable';

// Actions
import {
  getCheckoutsHistory,
  getRoles,
  getMaterials,
  getAccessTypes,
  getLocations,
  getUserNames,
  getCheckoutStatusNames,
} from '../../../Actions';

// dependents
import { HubSpokeContext } from '../../../Context/HubSpokeContextProvider';

const handleDownload = (data) => {
  var tempArray = [];
  data.map((row, i) => {
    delete row.picture;
    delete row.product_uuid;
    tempArray.push(row);
  });

  const date = new Date();
  const filename =
    'Checkout-History_' +
    date.getFullYear() +
    '-' +
    date.getMonth() +
    '-' +
    date.getDate();
  const csvData = JsonToCsvConverter(tempArray);
  download(filename, csvData);
};

const CheckoutsHistory = () => {
  var tmpViews = [];
  const [recentClicks, setRecentClicks] = useState([]);
  const [days, setDays] = useState([]);
  const [hours, setHours] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
      .toISOString()
      .substring(0, 10)
  );
  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 86400000).toISOString().substring(0, 10)
  );
  const [viewByDay, setViewByDay] = useState(0);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [selectedAccessType, setSelectedAccessType] = useState([]);
  const [accessTypes, setAccessTypes] = useState([]);
  const [materialClass, setMaterialClass] = useState([]);
  const [selectedMaterialClass, setSelectedMaterialClass] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [locations, setLocations] = useState([]);
  const [userNames, setUserNames] = useState([]);
  const [statusNames, setStatusNames] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [checkboxState, setCheckboxState] = useState({
    pending: true,
    checkedout: true,
    returned: true,
    checkedin: true,
    pastdue: true,
  });

  const { selectedSpoke } = useContext(HubSpokeContext);

  const StyledCheckbox = withStyles({
    root: {
      color: '#4c4c4c',
      '&$checked': {
        color: '#FF682C',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  useEffect(() => {
    getCheckoutStatusNames()
      .then((result) => {
        setStatusNames(result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getCheckoutsHistory(
      startDate,
      endDate,
      selectedRole,
      selectedAccessType,
      selectedMaterialClass,
      selectedLocations,
      selectedNames,
      selectedStatus,
      selectedSpoke
    )
      .then((result) => {
        setRecentClicks(result.data.checkouts);
        setDays(result.data.checkouts);
        result.data.checkouts.forEach((lines) => {
          Object.keys(lines).forEach((keys) => {
            if (keys === 'name') {
              tmpViews.push({ ...lines, name: lines.name });
            }
          });
        });
        setRecentClicks(tmpViews);
        setDays(tmpViews);
      })
      .catch((err) => {
        console.log(err);
      });

    const userList = new Promise((resolve) => {
      resolve(getUserNames());
    });

    userList.then((result) => {
      setUserNames(result.data.data);
    });

    getLocations()
      .then((result) => {
        setLocations(result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    const roleList = new Promise((resolve) => {
      resolve(getRoles());
    });

    roleList.then((result) => {
      setRoles(result.data.roleList);
    });

    const acList = new Promise((resolve) => {
      resolve(getAccessTypes());
    });

    acList.then((result) => {
      setAccessTypes(result.data.data);
    });

    const materialClassList = new Promise((resolve) => {
      resolve(getMaterials());
    });

    materialClassList.then((result) => {
      setMaterialClass(result.data.data);
    });
  }, [selectedSpoke]);

  const handleNameSelectChange = (event, value) => {
    setSelectedNames(value);
  };

  const handleRoleSelectChange = (event, value) => {
    setSelectedRole(value);
  };

  const handleAcSelectChange = (event, value) => {
    setSelectedAccessType(value);
  };

  const handleMaterialClassChange = (event, value) => {
    setSelectedMaterialClass(value);
  };

  const handleLocationChange = (event, value) => {
    setSelectedLocations(value);
  };

  const handleStatusChange = (event, value) => {
    setSelectedStatus(value);
  };

  const handleStartDate = (date) => {
    setStartDate(date.toISOString().substring(0, 10));
  };

  const handleEndDate = (date) => {
    setEndDate(date.toISOString().substring(0, 10));
  };

  const handleSubmit = () => {
    setHours([]);
    setViewByDay(0);
    getCheckoutsHistory(
      startDate,
      endDate,
      selectedRole,
      selectedAccessType,
      selectedMaterialClass,
      selectedLocations,
      selectedNames,
      selectedStatus,
      selectedSpoke
    )
      .then((result) => {
        setRecentClicks(result.data.checkouts);
        setDays(result.data.checkouts);
        result.data.checkouts.forEach((lines) => {
          Object.keys(lines).forEach((keys) => {
            if (keys === 'name') {
              tmpViews.push({ ...lines, name: lines.name });
            }
          });
        });
        setRecentClicks(tmpViews);
        setDays(tmpViews);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (event) => {
    setCheckboxState({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    });
  };

  const renderCheckboxes = (status) => {
    return status.map((row, i) => (
      <FormGroup column="true" key={i} style={{ width: 150 }}>
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={
                checkboxState[row.status_name.toLowerCase().replace(/\s/g, '')]
              }
              onChange={handleChange}
              name={row.status_name.toLowerCase().replace(/\s/g, '')}
            />
          }
          label={row.status_name}
        />
      </FormGroup>
    ));
    //   return (
    //     <FormGroup column>
    //       <FormControlLabel
    //         control={<StyledCheckbox checked={checkboxState.checkedout} onChange={handleChange} name="checkedout" />}
    //         label="Checked Out"
    //       />
    //       <FormControlLabel
    //         control={
    //           <StyledCheckbox
    //             checked={checkboxState.returned}
    //             onChange={handleChange}
    //             name="returned"
    //           />
    //         }
    //         label="Returned"
    //       />

    //     </FormGroup>
    //   );
  };

  const renderClickTable = (clicks) => {
    return (
      <div className="stats__rvp-container">
        <Paper elevation={0}>
          <Divider />
          <Paper elevation={0}>
            <Grid container spacing={3} style={{ padding: '10px' }}>
              <Grid item xs={2}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    variant="inline"
                    format="MM/DD/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Start Date"
                    value={startDate}
                    onChange={handleStartDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputProps={{ readOnly: true }}
                    autoOk={true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={2}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    variant="inline"
                    format="MM/DD/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="End Date"
                    value={endDate}
                    onChange={handleEndDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputProps={{ readOnly: true }}
                    autoOk={true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  style={{ marginTop: 16 }}
                  // className="export-form-item"
                  multiple
                  options={userNames}
                  // getOptionLabel={option => "Username: "  + option.username +  " - " + "Name: " + option.name}
                  getOptionLabel={(option) => option.name}
                  value={selectedNames}
                  onChange={handleNameSelectChange}
                  renderInput={(params) => (
                    <TextField {...params} label={'Name'} />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  style={{ marginTop: 16 }}
                  // className="export-form-item"
                  multiple
                  options={roles}
                  getOptionLabel={(option) => option.role_name}
                  value={selectedRole}
                  onChange={handleRoleSelectChange}
                  renderInput={(params) => (
                    <TextField {...params} label={'Role'} />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  style={{ marginTop: 16 }}
                  // className="export-form-item"
                  multiple
                  options={accessTypes}
                  getOptionLabel={(option) => option.access_type}
                  value={selectedAccessType}
                  onChange={handleAcSelectChange}
                  renderInput={(params) => (
                    <TextField {...params} label={'Access Type'} />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  style={{ marginTop: 16 }}
                  // className="export-form-item"
                  multiple
                  options={materialClass}
                  getOptionLabel={(option) => option.material_class}
                  value={selectedMaterialClass}
                  onChange={handleMaterialClassChange}
                  renderInput={(params) => (
                    <TextField {...params} label={'Material Class'} />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  style={{ marginTop: 16 }}
                  // className="export-form-item"
                  multiple
                  options={locations}
                  getOptionLabel={(option) => option.location_name}
                  value={selectedLocations}
                  onChange={handleLocationChange}
                  renderInput={(params) => (
                    <TextField {...params} label={'Location'} />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  style={{ marginTop: 16 }}
                  // className="export-form-item"
                  multiple
                  options={statusNames}
                  getOptionLabel={(option) => option.status_name}
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  renderInput={(params) => (
                    <TextField {...params} label={'Status'} />
                  )}
                />
              </Grid>
              {/* <Grid item xs={6} style={{display:"flex"}}>
              {renderCheckboxes(statusNames)}
              </Grid> */}
              <Grid item xs={12}>
                <Button
                  className="stats__search-button"
                  onClick={handleSubmit}
                  variant="contained"
                  autoFocus
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {clicks && clicks.length > 0 ? (
            <CustomTable data={clicks} headers={ProductCheckoutsConfig} />
          ) : (
            <div style={{ textAlign: 'center', color: 'red' }}>
              <Typography variant={'h3'}>
                Nothing matches your search criteria
              </Typography>
            </div>
          )}
        </Paper>
        <Divider />
        <Paper className="stats__mvp-data-download-container">
          <Button
            variant="contained"
            className="stats__mvp-data-download-button"
            onClick={(e) => handleDownload(clicks)}
            endIcon={<GetAppIcon />}
          >
            Download
          </Button>
        </Paper>
      </div>
    );
  };

  return (
    <div className="stats__rvp-container">
      <Paper elevation={0} className="stats__rvp-container-1">
        <Typography align="center" variant="h5" className="stats__mvp-header">
          Product Checkouts
        </Typography>
        <Divider />
        <Paper>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {renderClickTable(days)}
            </Grid>
          </Grid>
        </Paper>
      </Paper>
      <Divider />
    </div>
  );
};

export default CheckoutsHistory;
