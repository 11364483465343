import {
  Box,
  Button,
  Checkbox,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TableHead,
  TextField,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
} from '@material-ui/core';
import { getUser } from '../../../helpers';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useContext, useState, useEffect } from 'react';
import {
  productsTableConfigMain,
  // productsTableConfigSecondary,
  productsTableConfigSecondaryRPO,
} from '../../config';
// import { useHistory } from "react-router-dom";

// icons
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

// dependencies
import {
  getProductDetails,
  updateRPO,
  deleteCostRecordsByProduct,
  deleteCostRecordsByCostId,
} from '../../../Actions';
import { AlertContext } from '../../../Context/AlertContextProvider';
import { AppContext } from '../../../Context/AppContextProvide';
import { ProductsWithSearchContext } from '../../../Context/ProductsWithSearchContextProvider';

// style
import '../../styles/Row.css';

// cost disclaimer
const costDisclaimer = 'Call for price.';

// config
const Row = (props) => {
  const { product, isItemSelected, handleClick } = props;
  const [productInfo, setProductInfo] = useState([]);
  const [open, setOpen] = useState(false);
  const labelId = `enhanced-table-checkbox-${product.product_uuid}`;
  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);
  const { roleId, roleName, rolePrefix } = useContext(AppContext);
  const { updateProduct, setUpdateProduct, setGetProductsList } = useContext(
    ProductsWithSearchContext
  );
  const fontcolor = {
    style: { color: '#333' },
  };
  const [disabled, setDisabled] = useState(true);
  const [tempRPO] = useState({});
  const [user] = useState(getUser());
  const [alertOpen, setAlertOpen] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [deleteProductUuid, setDeleteProductUuid] = useState('');
  const [selected, setSelected] = useState([]);
  const [ounceWeight, setOunceWeight] = useState(false);
  const [wearLayer, setWearLayer] = useState(false);
  const [thickness, setThickness] = useState(false);
  const [promoCost, setPromoCost] = useState(false);
  const [promoRetail, setPromoRetail] = useState(false);
  const today = new Date().toISOString().slice(0, 10);
  let costArray = [];
  let priceArray = [];

  const productsTableConfigSecondary = [
    { id: 'product_sku', label: 'SKU', minWidth: 80 },
    { id: 'color_name', label: 'Color', minWidth: 80 },
    { id: 'cost', label: 'Cost', minWidth: 80 },
    { id: 'promo_cost', label: 'Promo Cost', minWidth: 80 },
    { id: 'retail_price', label: 'Retail\u00a0Price', minWidth: 80 },
    { id: 'promo_retail_price', label: 'Promo \u00a0Price', minWidth: 80 },
    { id: 'selling_unit', label: 'Selling\u00a0Unit', minWidth: 80 },
    { id: 'thickness', label: 'Thickness', minWidth: 80 },
    { id: 'wear_layer', label: 'Wear Layer', minWidth: 80 },
    { id: 'ounce_weight', label: 'Ounce Weight', minWidth: 80 },
    { id: 'margin', label: 'Margin', minWidth: 80 },
    { id: 'uom', label: 'UOM', minWidth: 100 },
    { id: 'basis_cd', label: 'Basis', minWidth: 100 },
    // { id: "distributor", label: "Distributor", minWidth: 80 },
    {
      id: 'last_modified_dttm',
      label: 'Last Modified Date/Time',
      minWidth: 80,
    },
    { id: 'delete', label: 'Delete', minWidth: 80 },
  ];

  useEffect(() => {
    process.on('warning', (e) => console.warn(e.stack));
    if (updateProduct !== undefined) {
      setOpen(false);
      setUpdateProduct(undefined);
    }
  }, [open, setUpdateProduct, updateProduct]);

  const isDisabled = () => {
    if (disabled === false) {
      setDisabled(true);
    } else if (disabled === true) {
      setDisabled(false);
    }
  };

  // const handleUpdate = (productId) => {
  //   history.push(`/product/${productId}`);
  // };

  const handleOpen = async (productId, roleId) => {
    if (!open) {
      try {
        const data = await getProductDetails(productId, roleId);
        setProductInfo(data.data.data[0].options);
        setPromoRetail(false);
        setPromoCost(false);
        data.data.data[0].options.forEach((result, index) => {
          costArray.push(result.promotion.cost);
          priceArray.push(result.promotion.retail_price);

          if (
            result.wear_layer !== '' &&
            result.wear_layer !== null &&
            result.wear_layer !== undefined
          ) {
            setWearLayer(true);
          }
          if (
            result.ounce_weight !== '' &&
            result.ounce_weight !== null &&
            result.ounce_weight !== undefined
          ) {
            setOunceWeight(true);
          }
          if (
            result.thickness !== '' &&
            result.thickness !== null &&
            result.thickness !== undefined
          ) {
            setThickness(true);
          }
          if (
            costArray.some((c) => c !== null) &&
            user.access_type_cd !== 'ro'
          ) {
            setPromoCost(true);
          }
          if (
            result.promotion.retail_price !== null &&
            result.promotion.effective_start_date !== null &&
            result.promotion.effective_end_date !== null
          ) {
            setPromoRetail(true);
          }
        });
      } catch (e) {
        setMessageType('error');
        setMessage('Error retrieving data');
        handleAlertOpen();
      }
    }
    setOpen(!open);
  };

  const handleUpdateRPO = (event, cost_id) => {
    if (/[a-zA-z]/.test(event)) {
      console.log('NOT A NUMBER');
    } else {
      tempRPO[cost_id] = event;
      var productArray = [...productInfo];
      productArray = productArray.map((x) => {
        if (x.cost_id === cost_id) x.retail_price_override = event;
        return x;
      });
      setProductInfo(productArray);
    }
  };

  const handleSetRPO = () => {
    setDisabled(true);
    updateRPO(tempRPO)
      .then(() => {
        setMessage('Retail price override set');
        setMessageType('success');
        handleAlertOpen();
        setUpdateProduct(1);
      })
      .catch(() => {
        setMessage('Error updating retail price override');
        setMessageType('error');
        handleAlertOpen();
      });
  };

  const handleConfirmDelete = () => {
    selected.length > 0
      ? deleteCostRecordsByCostId(selected)
          .then(() => {
            setMessage('Product deleted');
            setMessageType('success');
            handleAlertOpen();
            handleDeleteAlertClose();
            setUpdateProduct(1);
            setSelected([]);
            setGetProductsList(true);
          })
          .catch((err) => {
            setMessage('Something went wrong, failed to delete product.');
            setMessageType('error');
            handleAlertOpen();
            handleDeleteAlertClose();
            console.log(err);
          })
      : deleteCostRecordsByProduct(deleteProductUuid)
          .then(() => {
            setMessage('Product deleted');
            setMessageType('success');
            handleAlertOpen();
            handleDeleteAlertClose();
            setUpdateProduct(1);
            setSelected([]);
            setGetProductsList(true);
          })
          .catch((err) => {
            setMessage('Something went wrong, failed to delete product.');
            setMessageType('error');
            handleAlertOpen();
            handleDeleteAlertClose();
            console.log(err);
          });
  };

  const handleCautionAlertOpen = (productUuid) => {
    setDeleteProductUuid(productUuid);
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleDeleteAlertClose = () => {
    setDeleteAlertOpen(false);
  };

  const handleDeleteAlertOpen = () => {
    setDeleteAlertOpen(true);
    setAlertOpen(false);
  };

  const handleProductSelect = (event, id) => {
    handleClick(event, id);
  };

  const handleDeleteSku = (event, id) => {
    const selectedIndex = selected.indexOf(id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const alert = () => {
    return (
      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">DELETE PRODUCTS!</DialogTitle>
        <DialogContent>
          {selected.length > 0 ? (
            <DialogContentText id="alert-dialog-description">
              This will delete the selected SKUs!
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              This will delete ALL SKUs associated with this product! (
              <span style={{ color: '#FF0000' }}>
                Any printed QR codes will no longer work
              </span>
              )
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus={true}
            onClick={handleAlertClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleDeleteAlertOpen}
            style={{ color: 'red' }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const confirmDeleteAlert = () => {
    return (
      <Dialog
        open={deleteAlertOpen}
        onClose={handleDeleteAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">DELETE PRODUCTS!</DialogTitle>
        <DialogContent>
          {selected.length > 0 ? (
            <DialogContentText id="alert-dialog-description">
              Are you absolutely sure you want to delete the selected SKUs?
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              Are you absolutely sure you want to delete{' '}
              <span style={{ color: '#FF0000' }}>ALL</span> SKUs associated with
              this product?
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus={true}
            onClick={handleDeleteAlertClose}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmDelete}
            style={{ color: 'red' }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleShowHeader = () => {
    if (wearLayer === false) {
      for (var i = 0; i < productsTableConfigSecondary.length; i++) {
        if (productsTableConfigSecondary[i].id === 'wear_layer') {
          productsTableConfigSecondary.splice(i, 1);
        }
      }
    }

    if (thickness === false) {
      for (var t = 0; t < productsTableConfigSecondary.length; t++) {
        if (productsTableConfigSecondary[t].id === 'thickness') {
          productsTableConfigSecondary.splice(t, 1);
        }
      }
    }

    if (ounceWeight === false) {
      for (var o = 0; o < productsTableConfigSecondary.length; o++) {
        if (productsTableConfigSecondary[o].id === 'ounce_weight') {
          productsTableConfigSecondary.splice(o, 1);
        }
      }
    }

    if (promoCost === false) {
      for (var i = 0; i < productsTableConfigSecondary.length; i++) {
        if (productsTableConfigSecondary[i].id === 'promo_cost') {
          productsTableConfigSecondary.splice(i, 1);
        }
      }
    }

    if (promoRetail === false) {
      for (var i = 0; i < productsTableConfigSecondary.length; i++) {
        if (productsTableConfigSecondary[i].id === 'promo_retail_price') {
          productsTableConfigSecondary.splice(i, 1);
        }
      }
    }

    if (user.access_type_cd === 'ro') {
      for (var i = 0; i < productsTableConfigSecondary.length; i++) {
        if (productsTableConfigSecondary[i].id === 'cost') {
          productsTableConfigSecondary.splice(i, 1);
        }
      }
    }

    if (user.access_type_cd === 'ro') {
      for (var i = 0; i < productsTableConfigSecondary.length; i++) {
        if (productsTableConfigSecondary[i].id === 'cost') {
          productsTableConfigSecondary.splice(i, 1);
        }
      }
    }

    return disabled
      ? productsTableConfigSecondary.map((header) => {
          if (header.id === 'retail_price') {
            if (roleName === undefined || Number(rolePrefix) === 0) {
              header.label = ' Price';
            } else if (Number(rolePrefix) === 1) {
              header.label = roleName + ' Price';
            }
          }
          return <TableCell key={header.id}>{header.label}</TableCell>;
        })
      : productsTableConfigSecondaryRPO.map((header) => {
          if (header.id === 'retail_price') {
            if (roleName === undefined || Number(rolePrefix) === 0) {
              header.label = ' Price';
            } else if (Number(rolePrefix) === 1) {
              header.label = roleName + ' Price';
            }
          }
          return <TableCell key={header.id}>{header.label}</TableCell>;
        });
  };

  return (
    <React.Fragment>
      <TableRow
        className="row__table-row"
        hover
        tabIndex={-1}
        role="checkbox"
        aria-checked={isItemSelected}
        key={`${product.product_uuid}`}
      >
        <TableCell className="row__table-cell" padding="checkbox">
          <Checkbox
            onClick={(event) =>
              handleProductSelect(event, product.product_uuid)
            }
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
            className="row__checkbox"
          />
        </TableCell>
        <TableCell className="row__table-cell">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleOpen(product.product_uuid, roleId)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {productsTableConfigMain.map((column) => {
          const value = product[column.id];
          return (
            <TableCell className="row__table-cell" key={column.id}>
              {column.id === 'update' ? (
                <a
                  href={`/product/${product.product_uuid}`}
                  target="blank"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="contained"
                    className="product-view-button"
                    // onClick={() => handleUpdate(product.product_uuid)}
                  >
                    View
                  </Button>
                </a>
              ) : (
                value
              )}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <TableCell
            className="row__table-cell"
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
          >
            <Box margin={1} style={{ width: '90vw', marginLeft: '4vw' }}>
              <Tooltip title="Selecting this option will set the retail price while overriding all other margins.">
                <Button
                  className="row__edit-btn"
                  variant="contained"
                  onClick={isDisabled}
                  disabled={user.access_type_cd === 'ro' ? true : false}
                >
                  {!disabled ? 'Cancel' : 'Edit Price'}
                </Button>
              </Tooltip>
              {!disabled ? (
                <Button
                  className="row__update-button"
                  variant="contained"
                  hidden={disabled}
                  onClick={handleSetRPO}
                >
                  Update
                </Button>
              ) : (
                ''
              )}
              {user.access_type_cd === 'a' && selected.length > 0 ? (
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                  <Button
                    className="row__delete-btn"
                    variant="contained"
                    style={{ color: '#FF0000', marginBottom: 10 }}
                    onClick={() => handleCautionAlertOpen(product.cost_id)}
                  >
                    Delete SKU
                  </Button>
                </div>
              ) : user.access_type_cd === 'a' ? (
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                  <Button
                    className="row__delete-btn"
                    variant="contained"
                    style={{ color: '#FF0000', marginBottom: 10 }}
                    onClick={() => handleCautionAlertOpen(product.product_uuid)}
                  >
                    Delete Product
                  </Button>
                </div>
              ) : (
                <div></div>
              )}
              <Typography
                variant="h6"
                align="center"
                gutterBottom
                component="div"
              >
                Product Info
              </Typography>
              {product.selling_unit_quantity && (
                <Typography
                  variant="h6"
                  align="center"
                  gutterBottom
                  component="div"
                >
                  Sold In Quantities of {product.selling_unit_quantity}{' '}
                  {product.selling_unit_uom}
                </Typography>
              )}

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>{handleShowHeader()}</TableRow>
                </TableHead>
                <TableBody>
                  {productInfo.map((product, i) => {
                    const isDeleteSelected = isSelected(product.cost_id);
                    var cost = product.cost;
                    if (Number(cost) === 0.0) {
                      cost = costDisclaimer;
                    } else {
                      cost = '$' + product.cost;
                    }
                    var retail = product.retail_price;
                    if (Number(retail) === 0.0) {
                      retail = costDisclaimer;
                    } else {
                      retail = '$' + product.retail_price;
                    }

                    return (
                      <TableRow className="product-detail-table" key={i}>
                        <TableCell component="th" scope="row">
                          {product.product_sku}
                        </TableCell>
                        <TableCell>{product.color_name}</TableCell>
                        {user.user_id &&
                          user.access_type_cd !== 'c' &&
                          user.access_type_cd !== 'ro' && (
                            <TableCell>{cost}</TableCell>
                          )}

                        {promoCost === true &&
                        disabled &&
                        product.promotion.effective_end_date > today &&
                        product.promotion.effective_start_date < today ? (
                          <TableCell style={{ minWidth: 50 }}>
                            {product.promotion.cost != null
                              ? `$ ${product.promotion.cost}`
                              : ''}
                          </TableCell>
                        ) : promoCost === true && disabled ? (
                          <TableCell></TableCell>
                        ) : (
                          ''
                        )}

                        <TableCell>{retail}</TableCell>
                        {!disabled ? (
                          <TableCell>
                            $
                            <TextField
                              className="text-field"
                              inputProps={{ fontcolor, maxLength: 7 }}
                              value={
                                product.retail_price_override
                                  ? product.retail_price_override
                                  : ''
                              }
                              onChange={(event) =>
                                handleUpdateRPO(
                                  event.target.value,
                                  product.cost_id
                                )
                              }
                            ></TextField>
                          </TableCell>
                        ) : (
                          <TableCell style={{ display: 'none' }}></TableCell>
                        )}

                        {promoRetail === true &&
                        disabled &&
                        product.promotion.effective_end_date >= today &&
                        product.promotion.effective_start_date <= today &&
                        product.promotion.retail_price > 0 ? (
                          <TableCell style={{ minWidth: 190 }}>
                            {product.promotion.retail_price != null
                              ? `$ ${product.promotion.retail_price} - Ending ${product.promotion.effective_end_date}`
                              : ''}
                          </TableCell>
                        ) : promoRetail === true && disabled ? (
                          <TableCell></TableCell>
                        ) : (
                          ''
                        )}

                        <TableCell>
                          {product.selling_unit_quantity}{' '}
                          {product.selling_unit_uom}
                        </TableCell>
                        {thickness === true && disabled && (
                          <TableCell>{product.thickness}</TableCell>
                        )}
                        {wearLayer === true && disabled && (
                          <TableCell>{product.wear_layer}</TableCell>
                        )}
                        {ounceWeight === true && disabled && (
                          <TableCell>{product.ounce_weight}</TableCell>
                        )}
                        <TableCell>{product.margin}</TableCell>
                        <TableCell>{product.uom}</TableCell>
                        <TableCell>{product.basis_cd}</TableCell>
                        {/* <TableCell>{product.distributor}</TableCell> */}
                        <TableCell>
                          {product.last_modified_dttm !== null ||
                          product.last_modified_dttm !== undefined
                            ? product.last_modified_dttm.split('.')[0]
                            : 'Never'}
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            onClick={(event) =>
                              handleDeleteSku(
                                event.target.checked,
                                product.cost_id
                              )
                            }
                            checked={isDeleteSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            className="row__checkbox"
                          />
                        </TableCell>
                        {product.discontinued === 1 ? (
                          <TableCell>
                            <Tooltip title="Product Discontinued">
                              <InfoIcon color="secondary" />
                            </Tooltip>
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </TableCell>
        </Collapse>
      </TableRow>
      {alert()}
      {confirmDeleteAlert()}
    </React.Fragment>
  );
};

export default Row;
