import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../Context/AppContextProvide';
import { useHistory } from 'react-router-dom';

// styles
import '../styles/LeadManagement.css';

// MUI
import {
  Typography,
  Paper,
  Tab,
  Tabs,
  Box,
  Button,
  Grid,
} from '@material-ui/core';

// Components
import AssignedLeads from './AssignedLeads';
import AllLeads from './AllLeads';
import ContactInfo from './ContactInfo';
import UnassignedLeads from './UnassignedLeads';
import ArchivedLeads from './ArchivedLeads';
import CreateLeadForm from './CreateLeadForm';

function TabPanel(props) {
  const styles = {
    ...props.style,
    display: props.isOpen ? 'block' : 'none',
    padding: '20px',
  };

  return <Box style={styles}>{props.children}</Box>;
}

const LeadManagement = () => {
  const history = useHistory();
  const { user: userData } = useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  const changeTab = (value) => {
    setSelectedTab(value);
  };

  useEffect(() => {
    const checkWindowSize = () => {
      window.matchMedia('screen and (max-width: 1050px)').matches
        ? setIsMobile(true)
        : setIsMobile(false);
    };

    checkWindowSize();
    window.addEventListener('resize', checkWindowSize);
    return () => window.removeEventListener('resize', checkWindowSize);
  }, []);

  if (!userData) return <div>{history.push('/signin')}</div>;

  const tabListStyles = { backgroundColor: '#142E3E', color: '#28C4FC' };
  const tabStyles = { fontWeight: 'bold', opacity: 1 };

  if (isMobile) {
    tabListStyles.textAlign = 'center';
    tabStyles.margin = 'auto';
  }

  return (
    <div className="lead-management__container">
      <Paper>
        <Typography variant="h4" style={{ padding: '20px 16px' }}>
          Lead Management
        </Typography>
        <Tabs
          orientation={isMobile ? 'vertical' : 'horizontal'}
          value={selectedTab}
          onChange={(e, value) => changeTab(value)}
          style={tabListStyles}
          TabIndicatorProps={{ style: { backgroundColor: '#FF682C' } }}
        >
          <Tab label="All Leads" value={1} style={tabStyles} />
          <Tab label="Unassigned" value={2} style={tabStyles} />
          <Tab label="My Assigned" value={3} style={tabStyles} />
          {/* <Tab label="Transfer Requests" value={4} style={tabStyles}/> */}
          <Tab label="My Archived" value={5} style={tabStyles} />
          <Tab label="Create Lead" value={6} style={tabStyles} />
          <Tab label="Contact Info" value={7} style={tabStyles} />
        </Tabs>
        <TabPanel isOpen={selectedTab === 1}>
          <AllLeads
            user={userData}
            isOpen={selectedTab === 1}
            isMobile={isMobile}
          />
        </TabPanel>
        <TabPanel isOpen={selectedTab === 2}>
          <UnassignedLeads
            user={userData}
            isOpen={selectedTab === 2}
            isMobile={isMobile}
          />
        </TabPanel>
        <TabPanel isOpen={selectedTab === 3}>
          <AssignedLeads
            user={userData}
            isOpen={selectedTab === 3}
            isMobile={isMobile}
          />
        </TabPanel>
        {/* <TabPanel isOpen={selectedTab === 4}>
          Transfer Requests
        </TabPanel> */}
        <TabPanel isOpen={selectedTab === 5}>
          <ArchivedLeads
            user={userData}
            isOpen={selectedTab === 5}
            isMobile={isMobile}
          />
        </TabPanel>
        <TabPanel isOpen={selectedTab === 6}>
          <div
            style={{ minWidth: '60%', maxWidth: '600px', margin: '30px auto' }}
          >
            <CreateLeadForm
              user={userData}
              isOpen={selectedTab === 1}
              formType={'create'}
              isModal={false}
            />
          </div>
        </TabPanel>
        <TabPanel
          isOpen={selectedTab === 7}
          style={{ backgroundColor: '#DFDCDC' }}
        >
          <ContactInfo isOpen={selectedTab === 7} />
        </TabPanel>
      </Paper>
    </div>
  );
};

export default LeadManagement;
