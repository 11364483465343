import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import { getUser } from '../helpers';
import { baseUrl } from '../Contants';

export const ProductDisclaimerContext = createContext();

export const ProductDisclaimerContextProvider = ({ children }) => {
  const [user] = useState(getUser());
  const [disclaimer, setDisclaimer] = useState([]);
  const [update, setUpdate] = useState(false);

  const isAdmin = user.access_type_cd === 'a' ? true : false;

  useEffect(() => {
    async function getPDisclaimer() {
      const result = await axios(`${baseUrl}/api/admin/disclaimer/product`);
      setDisclaimer(result.data.data);
    }
    if (isAdmin) {
      getPDisclaimer();
      setUpdate(false);
    }
  }, [isAdmin, update]);

  return (
    <ProductDisclaimerContext.Provider value={{ disclaimer, setUpdate }}>
      {children}
    </ProductDisclaimerContext.Provider>
  );
};
