import React, { useContext, useState } from 'react';

// MUI
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';

// icon
import InfoIcon from '@material-ui/icons/Info';

// styles
import '../../styles/Admin.css';

// actions
import { deleteAnnouncement } from '../../../Actions';

// dependents
import EditAnnouncement from './EditAnnouncement';
import { AnnouncementsContext } from '../../../Context/AnnouncementsContextProvider';
import { AlertContext } from '../../../Context/AlertContextProvider';

const ManageAnnouncements = () => {
  const { announcements, setUpdate } = useContext(AnnouncementsContext);
  const [open] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);

  const popOverOpen = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getStateClass = (state) => {
    if (state === 0) {
      return 'active';
    }
    if (state === 1) {
      return 'future';
    }
    if (state === -1) {
      return 'inactive';
    }
  };

  const handleDeleteAnnouncement = (id) => {
    deleteAnnouncement(id)
      .then((result) => {
        setMessageType('success');
        setMessage('Announcement Deleted!');
        handleAlertOpen();
        setUpdate(true);
      })
      .catch((err) => {
        setMessageType('error');
        setMessage('Error Deleting Announcement!');
        handleAlertOpen();
      });
  };

  const handlePoperOverClose = (event) => {
    setAnchorEl(null);
  };

  const handlePopOverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const renderAnnouncements = (announcements) => {
    return announcements.length ? (
      announcements.map((announcement) => {
        return (
          <div key={announcement.announcement_id}>
            <ListItem
              className={`admin__announcement-list-item ${getStateClass(
                announcement.announcement_state
              )}`}
              alignItems="flex-start"
            >
              <ListItemAvatar className="admin__announcement-avatar">
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText
                primary={
                  announcement.title !== null
                    ? announcement.title
                    : 'Announcement!'
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {}
                    </Typography>
                    {announcement.announcement}
                  </React.Fragment>
                }
              />
              <EditAnnouncement
                announcement={announcement}
                open={open}
                type={'Update'}
              />
              <Button
                onClick={(e) =>
                  handleDeleteAnnouncement(announcement.announcement_id)
                }
                className="admin__delete-announcement"
                variant="contained"
              >
                Delete
              </Button>
            </ListItem>
            <Divider variant="inset" component="li" />
          </div>
        );
      })
    ) : (
      <div>No announcements</div>
    );
  };

  return (
    <div>
      <div className="admin__announcement-info-container">
        <IconButton
          onClick={handlePopOverOpen}
          className="admin__announcement-info-button"
        >
          <InfoIcon className="info-icon" />
        </IconButton>
        {/* PopOver */}
        <Popover
          id={id}
          open={popOverOpen}
          anchorEl={anchorEl}
          onClose={handlePoperOverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className="admin___popover-container">
            <Typography className="admin__info-expired"> Expired</Typography>
            <Typography className="admin__info-active">Active</Typography>
            <Typography className="admin__info-future">Future </Typography>
          </div>
        </Popover>
        {/* PopOver */}
      </div>
      <Paper>
        <div className="admin__announcements-container">
          <List>{renderAnnouncements(announcements)}</List>
        </div>
        <div className="admin__announcements-actions">
          <EditAnnouncement open={open} type={'Create'} />
        </div>
      </Paper>
    </div>
  );
};

export default ManageAnnouncements;
