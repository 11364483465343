import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import { getUser } from '../helpers';
import { baseUrl } from '../Contants';

export const AdminMaterialClassMarginContext = createContext();

export const AdminMaterialClassMarginContextProvider = ({ children }) => {
  const [user] = useState(getUser());
  const [materialClassMargins, setMaterialClassMargins] = useState([]);
  const [update, setUpdate] = useState(false);
  const [id, setId] = useState();

  const isAdmin = user.access_type_cd === 'a' ? true : false;

  useEffect(() => {
    //   async function getMaterialClassMargins() {
    //     const result = await axios(`${baseUrl}/api/admin/margin/material`);
    //     setMaterialClassMargins(result.data.data);
    //   }

    async function getRoleByMargin(role_id) {
      const result = await axios(
        `${baseUrl}/api/admin/roles/${role_id}/margins`
      );
      setMaterialClassMargins(result.data.margins);
    }
    // async function getLoadedMargin() {
    //   const result = await axios(`${baseUrl}/api/admin/margin/loaded`);
    //   setLoadedMargin(result.data.data);
    // }
    if (isAdmin) {
      // getMaterialClassMargins();
      getRoleByMargin(id);
      // getLoadedMargin()
      setUpdate(false);
    }
  }, [isAdmin, update, id]);

  return (
    <AdminMaterialClassMarginContext.Provider
      value={{ materialClassMargins, setUpdate, setId }}
    >
      {children}
    </AdminMaterialClassMarginContext.Provider>
  );
};
