import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

// chartjs
import { Pie } from 'react-chartjs-2';

// MUI
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { AppBar } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

// styles
import '../styles/Home.css';

// dependents
import { ProductStatsContext } from '../../Context/ProductsStatsContextProvider';
import Divider from '@material-ui/core/Divider';

const SimpleStats = () => {
  const { productStats, setDays } = useContext(ProductStatsContext);
  // setDays('100000');
  const history = useHistory();

  const handleOpenStats = () => {
    history.push(`/stats`);
  };

  const renderSimplePieChart = (productStatsData) => {
    if (productStatsData.length) {
      const productData = productStatsData.slice(0, 6);
      const data = {
        labels: productData.map((p) => p.trade_name),
        datasets: [
          {
            data: productData.map((p) => p.times_viewed),
            backgroundColor: productData.map((p) => {
              const r = Math.floor(Math.random() * 200);
              const g = Math.floor(Math.random() * 200);
              const b = Math.floor(Math.random() * 200);
              const color = `rgba(${r}, ${g}, ${b}, 0.5)`;
              return color;
            }),
          },
        ],
      };
      return <Pie data={data} />;
    } else {
      return <div>Loading...</div>;
    }
  };

  return (
    <div>
      <AppBar position="static" className="home__appbar">
        <Toolbar variant="dense">
          <Typography
            className="home__announcement-header"
            variant="h6"
            color="inherit"
          >
            Product Statistics
          </Typography>
        </Toolbar>
      </AppBar>
      <h2 className="home__simple-pie-title">Most Viewed Products</h2>
      {renderSimplePieChart(productStats)}
      <Divider />
      <div>
        <Paper className="stats__mvp-data-download-container">
          <Button
            className="stats__mvp-data-download-button"
            variant="contained"
            onClick={handleOpenStats}
          >
            View More
          </Button>
        </Paper>
      </div>
    </div>
  );
};

export default SimpleStats;
