import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppContext } from '../Context/AppContextProvide';

function AdminAuthRoute({ component: Component, authed, ...rest }) {
  const { user, isLoggedOut, loading } = useContext(AppContext);

  const isAuthenticated = user && new Date().getTime() < user.will_expire_at;
  const isAdmin = user.access_type_cd === 'a';

  if (isLoggedOut) {
    return <Redirect to={{ pathname: '/signin' }} />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && isAdmin) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{ pathname: '/notfound', state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
}

export default AdminAuthRoute;
