import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import { getUser } from '../helpers';
import { baseUrl } from '../Contants';

export const AdminContext = createContext();

export const AdminContextProvider = ({ children }) => {
  const [user] = useState(getUser());
  const [users, setUsers] = useState();
  const [roles, setRoles] = useState();
  const [accesstypes, setAccesstypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [update, setUpdate] = useState(false);

  // /admin/accesstypes

  const isAdmin = user.access_type_cd === 'a' ? true : false;

  useEffect(() => {
    async function getUsers() {
      const result = await axios(`${baseUrl}/api/admin/users`);
      setUsers(result.data.data);
    }

    async function getRoles() {
      const result = await axios(`${baseUrl}/api/admin/roles`);
      setRoles(result.data.roleList);
    }

    async function getAccesstypes() {
      const result = await axios(`${baseUrl}/api/admin/accesstypes`);
      setAccesstypes(result.data.data);
    }

    async function getLocations() {
      const result = await axios.get(`${baseUrl}/api/admin/locations`);
      setLocations(result.data.data);
    }
    if (isAdmin) {
      getUsers();
      getRoles();
      getAccesstypes();
      getLocations();
      setUpdate(false);
    }
  }, [isAdmin, update]);

  return (
    <AdminContext.Provider
      value={{ roles, users, locations, setUpdate, accesstypes }}
    >
      {children}
    </AdminContext.Provider>
  );
};
