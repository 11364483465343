import React, { useContext, useEffect, useState } from 'react';

// MUI
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

// actions
import { createAnnouncement, updateAnnouncement } from '../../../Actions';

// styles
import '../../styles/Admin.css';
import { AnnouncementsContext } from '../../../Context/AnnouncementsContextProvider';
import { AlertContext } from '../../../Context/AlertContextProvider';

// dependents

// component
const EditAnnouncement = (props) => {
  const { type } = props;

  const { setUpdate } = useContext(AnnouncementsContext);
  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);

  const [open, setOpen] = useState(props.open);

  const [announcementText, setAnnouncementText] = useState('');
  const [announcementTitle, setAnnouncementTitle] = useState('');
  const [announcementId, setAnnouncementId] = useState(0);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date('2054-08-29T21:11:54'));

  useEffect(() => {
    if (type === 'Update') {
      setAnnouncementText(props.announcement.announcement);
      setAnnouncementTitle(
        props.announcement.title === null
          ? 'Announcement!'
          : props.announcement.title
      );
      setAnnouncementId(props.announcement.announcement_id);
      setStartDate(props.announcement.effective_start_date);
      setEndDate(props.announcement.effective_end_date);
    }
  }, [props.announcement, type]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setAnnouncementText('');
    setOpen(false);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handleSubmit = () => {
    const sdate =
      startDate.length < 11
        ? startDate
        : startDate.toISOString().substring(0, 10);
    const edate =
      endDate.length < 11 ? endDate : endDate.toISOString().substring(0, 10);
    if (type === 'Create') {
      createAnnouncement(announcementTitle, announcementText, sdate, edate)
        .then((result) => {
          setMessageType('success');
          setMessage('Successfull Created an Announcement');
          handleAlertOpen(true);
          setUpdate(true);
          handleClose();
        })
        .catch((err) => {
          setMessageType('error');
          setMessage('Error creating an Announcement');
          handleAlertOpen(true);
        });
    }
    if (type === 'Update') {
      updateAnnouncement(
        announcementId,
        announcementTitle,
        announcementText,
        sdate,
        edate
      )
        .then((result) => {
          setMessageType('success');
          setMessage('Successfull updated Announcement');
          handleAlertOpen(true);
          setUpdate(true);
          handleClose();
        })
        .catch((err) => {
          setMessageType('error');
          setMessage('Error updating Announcement');
          handleAlertOpen(true);
        });
    }
  };

  const getAnnouncementText = () => {
    return announcementText;
  };

  const getAnnouncementTitle = () => {
    return announcementTitle;
  };

  const handleAnnouncement = (event) => {
    const value = event.target.value;
    setAnnouncementText(value);
  };

  const handleAnnouncementTitle = (event) => {
    const value = event.target.value;
    setAnnouncementTitle(value);
  };

  const canCreate = () => {
    if (announcementText.length > 0 && announcementTitle.length > 0) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <Button
        className="admin__announcements-create"
        onClick={handleOpen}
        variant="contained"
      >
        {type}
      </Button>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle
          className="Admin__create-user-header"
          id="alert-dialog-title"
        >
          {`${props.type} Announcement`}
        </DialogTitle>
        <DialogContent dividers>
          {/* <Paper className="Admin_create-user-form"> */}
          <form>
            <TextField
              id="filled-multiline-static"
              className="admin__input-announcement"
              label="Announcement Title"
              value={getAnnouncementTitle()}
              onChange={handleAnnouncementTitle}
              placeholder="Write an Announcement Title!"
              variant="filled"
            />
            <TextField
              id="filled-multiline-static"
              className="admin__input-announcement"
              label="Announcement"
              multiline
              value={getAnnouncementText()}
              rows={4}
              onChange={handleAnnouncement}
              placeholder="Write an Announcement!"
              variant="filled"
            />
            <div className="admin__date-picker">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/DD/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />

                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/DD/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </form>
          {/* </Paper> */}
          <p></p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className="admin__bulletin-buttons"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            className="Admin__create-user"
            variant="contained"
            autoFocus
            disabled={canCreate()}
          >
            {type}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditAnnouncement;
