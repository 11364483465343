import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// MUI
import {
  AppBar,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  ListSubheader,
  Button,
  Chip,
  Paper,
} from '@material-ui/core';

// Icons
import ColorLensIcon from '@material-ui/icons/ColorLens';
import Collapse from '@material-ui/core/Collapse';
import StyleIcon from '@material-ui/icons/Style';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LinkIcon from '@material-ui/icons/Link';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';

// Styles
import '../styles/Favorites.css';

// Actions
import { getLikes, unlikeProduct } from '../../Actions';
import { sortObjectsByAttribute } from '../../Util/utilsFunctions';

export default function CustomerFavorites() {
  const [favs, setFavs] = useState([]);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(undefined);
  const [refresh, setRefresh] = useState(true);
  const [sortStyleNumber, setSortStyleNumber] = useState(false);
  const [sortStyleName, setSortStyleName] = useState(false);
  const [sortColor, setSortColor] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('style_name');

  useEffect(() => {
    if (!refresh) return;

    getLikes()
      .then((result) => {
        setFavs([...result.data.likes]);
      })
      .catch((err) => {
        console.log(err);
      });
    setRefresh(false);
  }, [refresh]);

  const handleSort = (e) => {
    switch (e) {
      case 'Style Name':
        if (sortStyleName === false) {
          setOrder('desc');
          setOrderBy('style_name');
          setSortStyleName(true);
          setSortStyleNumber(false);
          setSortColor(false);
        } else {
          setSortStyleName(false);
          setOrder('asc');
        }
        break;
      case 'Style Number':
        if (sortStyleNumber === false) {
          setOrder('desc');
          setOrderBy('style_number');
          setSortStyleNumber(true);
          setSortStyleName(false);
          setSortColor(false);
        } else {
          setSortStyleNumber(false);
          setOrder('asc');
        }
        break;
      case 'Color':
        if (sortColor === false) {
          setOrder('desc');
          setOrderBy('color_name');
          setSortStyleName(false);
          setSortColor(true);
          setSortStyleNumber(false);
        } else {
          setSortColor(false);
          setOrder('asc');
        }
        break;
      default:
        setSortColor(false);
        setSortStyleNumber(false);
        setSortStyleName(false);
    }
  };

  const handleClick = (index) => {
    open === index ? setOpen(undefined) : setOpen(index);
  };

  const onClickfavorite = (product_id, product_sku) => {
    unlikeProduct(product_id, product_sku)
      .then(() => {
        setValue(value + 1);
        setRefresh(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return favs.length < 1 ? (
    <div>
      <AppBar position="static">
        <Toolbar variant="dense" className="favorites__header">
          <Typography variant="h4" color="inherit" style={{ padding: '15px' }}>
            Favorites
          </Typography>
        </Toolbar>
      </AppBar>
      <Paper className="link-container">
        <Typography variant="h6" style={{ padding: '15px' }}>
          You have no favorites saved. Scan a product and press the heart button
          to save products.
        </Typography>
      </Paper>
    </div>
  ) : (
    <div>
      {/* Full resolution */}
      <div className="pending__container-full">
        <AppBar position="static">
          <Toolbar variant="dense" className="favorites__header">
            <Typography variant="h6" color="inherit">
              Favorites
            </Typography>
          </Toolbar>
        </AppBar>
        {/* {renderTabs()} */}
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h5" className="fav-header-text">
                    Style Name
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => handleSort('Style Name')}
                  >
                    {sortStyleName === true ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" style={{ float: 'left' }}>
                    Style Number
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => handleSort('Style Number')}
                  >
                    {sortStyleNumber === true ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" className="fav-header-text">
                    Color
                  </Typography>
                  <Button variant="text" onClick={() => handleSort('Color')}>
                    {sortColor === true ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" className="fav-header-text">
                    List Price
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" className="fav-header-text">
                    Unlike?
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {favs
                .sort((prev, curr) =>
                  sortObjectsByAttribute(prev, curr, order, orderBy)
                )
                .map((fav, index) => {
                  return (
                    <TableRow key={'desktop-' + fav.product_like_id}>
                      <TableCell>
                        <Link
                          to={`/product/${fav.product_uuid}`}
                          className="favorite__links"
                          target="blank"
                        >
                          {fav.style_name}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/product/${fav.product_uuid}`}
                          className="favorite__links"
                          target="blank"
                        >
                          {fav.style_number}
                        </Link>
                      </TableCell>
                      <TableCell>{fav.color_name}</TableCell>
                      <TableCell>{fav.retail_price}</TableCell>
                      <TableCell>
                        <Button
                          className="product__icon-buttons"
                          onClick={() =>
                            onClickfavorite(fav.product_uuid, fav.sku)
                          }
                        >
                          <Chip
                            icon={<CloseIcon style={{ color: '#FF0000' }} />}
                            label={'Unlike?'}
                            fontSize="large"
                            className="product__like_icon"
                            variant="outlined"
                          />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Paper>
      </div>

      {/* Mobile site */}
      <div className="favorites__container-mobile">
        {/* <AppBar position="static">
                <Toolbar variant="dense" className="favorites__header">
                    <Typography
                    variant="h6"
                    color="inherit"
                    >
                    Favorites
                    </Typography>
                </Toolbar>
            </AppBar> */}
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              className="favorites__list-sub-header"
            >
              Favorites
            </ListSubheader>
          }
        >
          {favs
            .sort((prev, curr) =>
              sortObjectsByAttribute(prev, curr, order, orderBy)
            )
            .map((fav, index) => {
              return (
                <List key={'mobile-' + fav.product_like_id}>
                  <ListItem
                    button
                    onClick={() => handleClick(fav.product_like_id)}
                    className="favorites__list-menu"
                  >
                    <StyleIcon style={{ marginRight: 20, color: '#FF682C' }} />
                    <ListItemText primary={fav.style_name} />
                    {open === fav.product_like_id ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItem>
                  {open === fav.product_like_id ? (
                    <Collapse in={true} timeout="auto" unmountOnExit>
                      <List style={{ backgroundColor: '#FFF' }}>
                        <ListItem>
                          <ListItemIcon>
                            <StyleIcon />
                          </ListItemIcon>
                          <Link
                            to={`/product/${fav.product_uuid}`}
                            className="favorite__links"
                            target="blank"
                          >
                            <ListItemText primary={fav.style_number} />
                          </Link>
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemIcon>
                            <ColorLensIcon />
                          </ListItemIcon>
                          <ListItemText primary={fav.color_name} />
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemIcon>
                            <AttachMoneyIcon />
                          </ListItemIcon>
                          <ListItemText primary={fav.retail_price} />
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <Button
                            className="product__icon-buttons"
                            onClick={() =>
                              onClickfavorite(fav.product_uuid, fav.sku)
                            }
                          >
                            <CancelIcon
                              style={{ color: 'FF0000', marginLeft: -40 }}
                            />
                            {/* <Chip icon={<CloseIcon  style={{ color: "#FF0000" }}/>}
                    label={"Unlike?"}
                    fontSize="large" 
                    className="product__like_icon" 
                    variant="outlined"
                    /> */}
                          </Button>
                          <ListItemText
                            primary="Unlike?"
                            style={{ marginLeft: -8 }}
                          />
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemIcon>
                            <LinkIcon />
                          </ListItemIcon>
                          <Link
                            to={`/product/${fav.product_uuid}`}
                            className="favorite__links"
                            target="blank"
                          >
                            <ListItemText primary={'View product'} />
                          </Link>
                        </ListItem>
                      </List>
                    </Collapse>
                  ) : (
                    <></>
                  )}
                </List>
              );
            })}
        </List>
      </div>
    </div>
  );
}
