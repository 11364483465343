import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../Contants';

// MUI
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Grid,
  Avatar,
} from '@material-ui/core';

// Icons
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

const UserDetails = (props) => {
  const [userId, setUserId] = useState(props.userId);
  const [name, setName] = useState(props.name);
  const [email, setEmail] = useState(props.email);
  const [phone, setPhone] = useState(props.phone);
  const [picture, setPicture] = useState(props.picture);
  const [rsa, setRSA] = useState();
  const [hasImage, setHasImage] = useState(false);

  const getLeadById = async (userId) => {
    const result = await axios.get(`${baseUrl}/api/lead/${userId}`);

    if (!result.data.lead) return;

    setName(result.data.lead?.name);
    setEmail(result.data.lead?.email);
    setPhone(result.data.lead?.phone);
    setPicture(result.data.lead?.picture);
    setRSA(result.data.lead?.rsa_name);
  };

  useEffect(() => {
    if (!userId) return;

    getLeadById(userId);
  }, [userId]);

  return (
    <div>
      <Grid container spacing={3} style={{ marginTop: 50 }}>
        <Grid
          item
          lg={3}
          md={6}
          sm={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginRight: 20,
            marginLeft: 20,
          }}
        >
          <Avatar className="user__avatar" src={picture}></Avatar>
        </Grid>
        <Grid item lg={8} md={6} sm={12}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <PersonIcon />
                </TableCell>
                <TableCell style={{ width: '5em' }} multiline={true}>
                  <Typography variant="h5">Name:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" style={{ marginLeft: 30 }}>
                    {name}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <EmailIcon />
                </TableCell>
                <TableCell style={{ wordWrap: 'break-word' }}>
                  <Typography variant="h5">Email:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" style={{ marginLeft: 30 }}>
                    <a href={`mailto:${email}`} style={{ color: '#000' }}>
                      {email}
                    </a>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <PhoneIphoneIcon />
                </TableCell>
                <TableCell>
                  <Typography variant="h5">Phone:</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" style={{ marginLeft: 30 }}>
                    <a href={`tel: ${phone}`} style={{ color: '#000' }}>
                      {phone}
                    </a>
                  </Typography>
                </TableCell>
              </TableRow>

              {rsa ? (
                <TableRow>
                  <TableCell>
                    <PhoneIphoneIcon />
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">Assigned To:</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" style={{ marginLeft: 30 }}>
                      {rsa}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : undefined}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserDetails;
