import { useEffect, useState } from 'react';

export const useThirdPartyScript = (url, async, type) => {
  let [state, setState] = useState(url ? 'loading' : 'error');

  useEffect(() => {
    const handleScript = (e) => {
      setState(e.type === 'load' ? 'ready' : 'error');
    };

    if (!url) {
      setState('error');
      return;
    }

    let script = document.querySelector(`script[src="${url}"]`);

    if (!script) {
      script = document.createElement('script');
      script.type = type;
      script.src = url;
      script.async = async;
      document.head.appendChild(script);
      script.addEventListener('load', handleScript);
      script.addEventListener('error', handleScript);
    } else {
      setState('error');
      return;
    }

    script?.addEventListener('load', handleScript);
    script?.addEventListener('error', handleScript);

    return () => {
      script?.removeEventListener('load', handleScript);
      script?.removeEventListener('error', handleScript);
    };
  }, [url]);

  return state;
};
