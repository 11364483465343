import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../Context/AppContextProvide';
import Quill from 'quill';
import { Button, Grid, Typography } from '@material-ui/core';
import 'quill/dist/quill.snow.css';
import './quill.css';

const convertSYSDATEToUTC = (sysdate) => {
  if (!sysdate) return;

  return sysdate.replace(' ', 'T') + 'Z';
};

const CHARACTER_LIMIT = 500;

export function NewNoteInput(props) {
  const [isFocused, setIsFocused] = useState(props.isFocused ?? false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [charCount, setCharCount] = useState(0);

  const editorRef = useRef(null);
  const containerRef = useRef(null);
  const toolbarRef = useRef(null);

  const checkText = () => {
    const noteText = editorRef.current?.getText();

    setCharCount(noteText.length === 1 ? 0 : noteText.length);
    setCanSubmit(noteText.trim().length && noteText.length <= CHARACTER_LIMIT);
  };

  useEffect(() => {
    const options = {
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
        ],
      },
      placeholder: 'Add a note...',
      theme: 'snow',
    };

    editorRef.current = new Quill(containerRef.current, options);
    editorRef.current.on('text-change', () => checkText());
    toolbarRef.current = containerRef.current.previousSibling;

    if (isFocused) {
      toolbarRef.current.style.display = 'block';
      containerRef.current.style.borderTop = '0px';
    } else {
      toolbarRef.current.style.display = 'none';
      containerRef.current.style.borderTop = window
        .getComputedStyle(containerRef.current)
        .getPropertyValue('border-bottom');
    }

    return () => {
      editorRef.current = null;
      if (containerRef.current) containerRef.current.innerHtml = '';
      if (toolbarRef.current) toolbarRef.current.remove();
    };
  }, [isFocused]);

  const clearNote = () => {
    setIsFocused(false);
    editorRef.current?.setContents(undefined);
    props.onCancel?.();
  };

  return (
    <Grid container spacing={1} style={{ marginBottom: '15px' }}>
      <Grid item xs={12}>
        <div
          ref={containerRef}
          onFocus={() => setIsFocused(true)}
          style={{ height: isFocused ? '150px' : 'auto' }}
        ></div>
        {isFocused ? (
          <Typography
            variant="caption"
            style={{
              display: 'block',
              textAlign: 'right',
              marginTop: '5px',
              color: charCount > CHARACTER_LIMIT ? 'red' : 'initial',
            }}
          >
            {charCount} / {CHARACTER_LIMIT} Characters
          </Typography>
        ) : undefined}
      </Grid>
      {isFocused ? (
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            style={{ marginRight: '20px' }}
            onClick={() => clearNote()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: '#4caf50', color: '#FFFFFF' }}
            onClick={() =>
              props.onSubmit?.(JSON.stringify(editorRef.current?.getContents()))
            }
            disabled={!canSubmit}
          >
            Save
          </Button>
        </Grid>
      ) : undefined}
    </Grid>
  );
}

export function NoteInput(props) {
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [canSubmit, setCanSubmit] = useState(false);
  const [isDefaultSet, setIsDefaultSet] = useState(false);
  const [charCount, setCharCount] = useState(0);

  const { user } = useContext(AppContext);

  const editorRef = useRef(null);
  const containerRef = useRef(null);
  const toolbarRef = useRef(null);

  const checkText = () => {
    const noteText = editorRef.current?.getText();

    setCharCount(noteText.length === 1 ? 0 : noteText.length);
    setCanSubmit(noteText.trim().length && noteText.length <= CHARACTER_LIMIT);
  };

  useEffect(() => {
    const options = {
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ align: ['', 'center', 'right'] }],
        ],
      },
      theme: 'snow',
    };

    editorRef.current = new Quill(containerRef.current, options);

    if (!isDefaultSet) {
      try {
        editorRef.current?.setContents(JSON.parse(props.note.note));
      } catch (e) {
        // Catching to handle backwards compatibility for strings
        editorRef.current?.setText(props.note.note);
      }

      setIsDefaultSet(true);
    }

    editorRef.current?.on('text-change', () => checkText());
    toolbarRef.current = containerRef.current.previousSibling;

    if (!isReadOnly) {
      editorRef.current?.enable();
      checkText();
      toolbarRef.current.style.display = 'block';
      containerRef.current.style.borderTop = '0px';
    } else {
      editorRef.current?.disable();
      toolbarRef.current.style.display = 'none';
      containerRef.current.style.borderTop = window
        .getComputedStyle(containerRef.current)
        .getPropertyValue('border-bottom');
    }

    return () => {
      editorRef.current = null;
      if (containerRef.current) containerRef.current.innerHtml = '';
      if (toolbarRef.current) toolbarRef.current.remove();
    };
  }, [isReadOnly]);

  const cancelEdit = () => {
    setIsReadOnly(true);

    try {
      editorRef.current?.setContents(JSON.parse(props.note.note));
    } catch (e) {
      // Catching to handle backwards compatibility for strings
      editorRef.current?.setText(props.note.note);
    }
  };

  return (
    <Grid
      container
      spacing={1}
      style={{ marginTop: '10px', marginBottom: '10px' }}
    >
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          <b>{props.note?.created_by}</b>
        </Typography>
        <Typography variant="subtitle2">
          {new Date(
            convertSYSDATEToUTC(props.note.created_dttm)
          ).toLocaleString()}
          {props.note.created_dttm !== props.note.last_modified_dttm
            ? ' (Edited)'
            : ''}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <div
          ref={containerRef}
          style={{ height: 'auto', maxHeight: '600px', overflowY: 'auto' }}
        ></div>
        {!isReadOnly ? (
          <Typography
            variant="caption"
            style={{ display: 'block', textAlign: 'right', marginTop: '5px' }}
          >
            {charCount} / {CHARACTER_LIMIT} Characters
          </Typography>
        ) : undefined}
      </Grid>
      {!isReadOnly ? (
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            size="small"
            variant="contained"
            style={{ marginRight: '15px' }}
            onClick={() => cancelEdit()}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: '#4caf50', color: '#FFFFFF' }}
            onClick={() => {
              setIsReadOnly(true);
              props.onUpdate?.(
                JSON.stringify(editorRef.current?.getContents())
              );
            }}
            disabled={!canSubmit}
          >
            Update
          </Button>
        </Grid>
      ) : undefined}
      {isReadOnly && props.note.created_by_user_id === user.user_id ? (
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => props.onDelete?.()}
            style={{
              marginRight: '15px',
              backgroundColor: '#f44336',
              color: '#FFFFFF',
            }}
          >
            Delete
          </Button>
          <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: '#142e3e', color: '#28c4fc' }}
            onClick={() => setIsReadOnly(false)}
          >
            Edit
          </Button>
        </Grid>
      ) : undefined}
    </Grid>
  );
}
