import React, { useContext } from 'react';
import { UserBulletinContext } from '../../../Context/UserBulletinContextProvider';

// MUI
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

// style
import '../../styles/HomeBulletins.css';

// dependends
import Bulletin from './Bulletin';

const Bulletins = () => {
  const { bulletins } = useContext(UserBulletinContext);

  const renderBulletins = (data) => {
    return data.length ? (
      data.map((bulletin, index) => {
        return <Bulletin key={index} bulletin={bulletin} />;
      })
    ) : (
      <div>No Bulletins to display</div>
    );
  };

  return (
    <div>
      <AppBar position="static" className="home__appbar">
        <Toolbar variant="dense">
          <Typography
            className="home__announcement-header"
            variant="h6"
            color="inherit"
          >
            Bulletins
          </Typography>
        </Toolbar>
      </AppBar>
      <div className="tiles">{renderBulletins(bulletins)}</div>
    </div>
  );
};

export default Bulletins;
