import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../Contants';

// styles
import '../styles/LeadManagement.css';

// MUI
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';

import { AlertContext } from '../../Context/AlertContextProvider';
import FavoriteHistory from './FavoriteHistory';
import CheckoutHistory from './CheckoutHistory';
import ExportLeadDataDialog from './ExportLeadDataDialog';
import { NewNoteInput, NoteInput } from './NoteInputs';

function NewNote(props) {
  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);

  const createNote = async (lead_id, note) => {
    if (!lead_id) {
      setMessageType('error');
      setMessage('Error creating note');
      handleAlertOpen();
      return;
    }

    const result = await axios.post(`${baseUrl}/api/leads/${lead_id}/notes`, {
      note,
    });

    if (result.status !== 200) {
      setMessageType('error');
      setMessage('Error creating note');
      handleAlertOpen();
      return;
    }

    setMessageType('success');
    setMessage('New note created!');
    handleAlertOpen();
    props.refreshParent();
    props.setIsNewNoteOpen(false);
  };

  return (
    <Dialog
      open={props.isNewNoteOpen}
      onClose={() => props.setIsNewNoteOpen(false)}
      aria-labelledby="New Note Dialog"
      aria-describedby="New Note Dialog"
    >
      <DialogTitle id="new-note-dialog-title">New Note</DialogTitle>
      <DialogContent className="lead-management__dialog-content">
        <NewNoteInput
          leadId={props.lead?.lead_id}
          isFocused={true}
          onCancel={() => props.setIsNewNoteOpen(false)}
          onSubmit={(note) => createNote(props.lead?.lead_id, note)}
        />
      </DialogContent>
    </Dialog>
  );
}

function NoteRecord(props) {
  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);

  const updateNote = async (lead_id, note_id, note) => {
    if (!lead_id || !note_id) {
      setMessageType('error');
      setMessage('Error updating note');
      handleAlertOpen();
      return;
    }

    const result = await axios.put(
      `${baseUrl}/api/leads/${lead_id}/notes/${note_id}`,
      { note }
    );

    if (result.status !== 200) {
      setMessageType('error');
      setMessage('Error updating note');
      handleAlertOpen();
      return;
    }

    setMessageType('success');
    setMessage('Note updated!');
    handleAlertOpen();
    props.refreshParent();
  };

  const deleteNote = async (lead_id, note_id) => {
    if (!lead_id || !note_id) {
      setMessageType('error');
      setMessage('Error deleting note');
      handleAlertOpen();
      return;
    }

    const result = await axios.delete(
      `${baseUrl}/api/leads/${lead_id}/notes/${note_id}`
    );

    if (result.status !== 200) {
      setMessageType('error');
      setMessage('Error deleting note');
      handleAlertOpen();
      return;
    }

    setMessageType('success');
    setMessage('Note deleted!');
    handleAlertOpen();
    props.refreshParent();
  };

  return (
    <NoteInput
      leadId={props.note?.lead_id}
      note={props.note}
      onUpdate={(note) =>
        updateNote(props.note?.lead_id, props.note?.note_id, note)
      }
      onDelete={() => deleteNote(props.note?.lead_id, props.note?.note_id)}
    />
  );
}

function TabPanel(props) {
  const styles = {
    ...props.style,
    display: props.isOpen ? 'block' : 'none',
    padding: '20px',
    minHeight: '400px',
    maxHeight: '600px',
    overflowY: 'auto',
    border: '1px solid #142E3E',
  };

  return <Box style={styles}>{props.children}</Box>;
}

export default function ViewLeadModal(props) {
  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);
  const [rsaList, setRsaList] = useState([]);
  const [selectedRsa, setSelectedRsa] = useState('');
  const [isNewNoteOpen, setIsNewNoteOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [leadStatuses, setLeadStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);

  const changeTab = (value) => {
    setSelectedTab(value);
  };

  const refreshData = () => {
    setRefresh(true);
  };

  const getStatuses = async () => {
    const result = await axios.get(`${baseUrl}/api/leads/status`);

    if (result.status !== 200) {
      setMessageType('error');
      setMessage('Error retrieving statuses');
      handleAlertOpen();
      setLeadStatuses([]);
      return;
    }

    setLeadStatuses([...result.data.userStatusList]);
  };

  const updateStatus = async (lead_id, status_id) => {
    if (!lead_id || !status_id) {
      setMessageType('error');
      setMessage('Error updating status');
      handleAlertOpen();
      return;
    }

    const result = await axios.post(
      `${baseUrl}/api/leads/${lead_id}/status/${status_id}`
    );

    if (result.status !== 200) {
      setMessageType('error');
      setMessage('Error updating status');
      handleAlertOpen();
      return;
    }

    setMessageType('success');
    setMessage('Status updated!');
    handleAlertOpen();
    props.refreshParent();
    refreshData();
  };

  const getRsas = async () => {
    const result = await axios.get(`${baseUrl}/api/rsas`);

    if (result.status !== 200) {
      setMessageType('error');
      setMessage('Error retrieving RSAs');
      handleAlertOpen();
      setRsaList([]);
      return;
    }

    setRsaList([...result.data.rsaList]);
  };

  const assignLead = async (lead_id, rsa_id) => {
    if (!lead_id || !rsa_id) {
      setMessageType('error');
      setMessage('Error assigning lead');
      handleAlertOpen();
      return;
    }

    const result = await axios.post(
      `${baseUrl}/api/leads/${lead_id}/assign/${rsa_id}`
    );

    if (result.status !== 200) {
      setMessageType('error');
      setMessage('Error assigning lead');
      handleAlertOpen();
      return;
    }

    setMessageType('success');
    setMessage('Lead assigned!');
    handleAlertOpen();
    props.refreshParent();
    refreshData();
  };

  const unassignLead = async (lead_id, rsa_id) => {
    if (!lead_id || !rsa_id) {
      setMessageType('error');
      setMessage('Error unassigning lead');
      handleAlertOpen();
      return;
    }

    const result = await axios.post(
      `${baseUrl}/api/leads/${lead_id}/unassign`,
      { rsa_id }
    );

    if (result.status !== 200) {
      setMessageType('error');
      setMessage('Error unassigning lead');
      handleAlertOpen();
      return;
    }

    setMessageType('success');
    setMessage('Lead unassigned!');
    handleAlertOpen();
    props.refreshParent();
    refreshData();
  };

  const getNotes = async (lead_id) => {
    if (!lead_id) {
      setMessageType('error');
      setMessage('Error retrieving notes');
      handleAlertOpen();
      setNotes([]);
      return;
    }

    const result = await axios.get(`${baseUrl}/api/leads/${lead_id}/notes`);

    if (result.status !== 200) {
      setMessageType('error');
      setMessage('Error retrieving notes');
      handleAlertOpen();
      setNotes([]);
      return;
    }

    setNotes([...result.data.leadNoteList]);
  };

  useEffect(() => {
    if (!props.isModalOpen || !props.lead?.lead_id) return;

    refreshData();
  }, [props.isModalOpen, props.lead?.lead_id]);

  useEffect(() => {
    if (!refresh) return;

    getStatuses();
    getRsas();
    getNotes(props.lead?.lead_id);
    setRefresh(false);
  }, [refresh]);

  useEffect(() => {
    setSelectedStatus(props.lead?.user_status_id ?? '');
    setSelectedRsa(props.lead?.rsa_user_id ?? '');
  }, [props.lead]);

  const rsaOptions = rsaList.map((rsa) => {
    return (
      <MenuItem value={rsa.userId} key={'rsa-' + rsa.userId}>
        <Typography>
          <b>{rsa.name}</b>
          <br></br>
          {rsa.email}
        </Typography>
      </MenuItem>
    );
  });
  const statusOptions = leadStatuses.map((status) => {
    return (
      <MenuItem
        value={status.user_status_id}
        key={'status-' + status.status_cd}
      >
        <Typography>{status.status}</Typography>
      </MenuItem>
    );
  });
  const notesList = notes
    ?.sort((a, b) => {
      return (
        new Date(b.created_dttm).getTime() - new Date(a.created_dttm).getTime()
      );
    })
    .map((note, index) => {
      return (
        <NoteRecord
          key={'note-' + note.note_id}
          note={note}
          refreshParent={refreshData}
        />
      );
    });
  const tabListStyles = { backgroundColor: '#142E3E', color: '#28C4FC' };
  const tabStyles = { fontWeight: 'bold', opacity: 1 };

  if (props.isMobile) {
    tabListStyles.textAlign = 'center';
    tabStyles.margin = 'auto';
  }

  return (
    <Modal
      open={props.isModalOpen}
      onClose={props.handleOnClose}
      aria-labelledby="customer lead modal"
      aria-describedby="modal for managing customer leads"
    >
      <Paper
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          minHeight: '40%',
          maxHeight: '90vh',
          overflow: 'hidden',
          overflowY: 'auto',
          padding: '30px',
          width: '80%',
          maxWidth: '850px',
        }}
      >
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} style={{ width: '100%' }}>
            <CloseRounded
              style={{ float: 'right' }}
              onClick={props.handleOnClose}
            ></CloseRounded>
            <Typography variant="h4">
              <b>{props.lead?.name}</b>
            </Typography>
            <Typography>
              <a href={'tel:' + props.lead?.phone}>{props.lead?.phone}</a>
            </Typography>
            <Typography>
              <a href={'mailto:' + props.lead?.email}>{props.lead?.email}</a>
            </Typography>
            <Typography>{props.lead?.location_name}</Typography>
            {props.lead?.rsa_user_id ? (
              <Typography>RSA: {props.lead?.rsa_name}</Typography>
            ) : undefined}
            <Button
              onClick={() => setIsExportDialogOpen(true)}
              variant="contained"
              style={{ marginTop: '20px' }}
            >
              Export Lead Data
            </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Tabs
              orientation={props.isMobile ? 'vertical' : 'horizontal'}
              value={selectedTab}
              onChange={(e, value) => changeTab(value)}
              style={tabListStyles}
              TabIndicatorProps={{ style: { backgroundColor: '#FF682C' } }}
            >
              <Tab label="Actions" value={1} style={tabStyles} />
              <Tab label="Notes" value={2} style={tabStyles} />
              <Tab label="Checkouts" value={3} style={tabStyles} />
              <Tab label="Favorites" value={4} style={tabStyles} />
            </Tabs>
            <TabPanel isOpen={selectedTab === 1}>
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'top',
                  margin: '10px 50px 10px 0px',
                }}
              >
                <Typography variant="h5">Status</Typography>
                <FormControl variant="filled" style={{ width: '190px' }}>
                  <InputLabel id="status-list-label">Status List</InputLabel>
                  <Select
                    labelId="status-list-label"
                    id="status-list"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    {statusOptions}
                  </Select>
                </FormControl>
                <p>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      updateStatus(props.lead?.lead_id, selectedStatus)
                    }
                    style={{ color: 'rgba(9, 150, 42, 0.7)' }}
                  >
                    Update Status
                  </Button>
                </p>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  verticalAlign: 'top',
                  margin: '10px 50px 10px 0px',
                }}
              >
                <Typography variant="h5">Assignment Functions</Typography>
                {props.user.access_type_cd === 'a' ? (
                  <FormControl
                    variant="filled"
                    style={{ width: '50%', minWidth: '260px' }}
                  >
                    <InputLabel id="rsa-list-label">RSA List</InputLabel>
                    <Select
                      labelId="rsa-list-label"
                      id="rsa-list"
                      value={selectedRsa}
                      onChange={(e) => setSelectedRsa(e.target.value)}
                    >
                      <MenuItem aria-label="None" value="">
                        <em>Please Select One Of The Following RSAs</em>
                      </MenuItem>
                      {rsaOptions}
                    </Select>
                  </FormControl>
                ) : undefined}
                <p>
                  {props.user.access_type_cd === 'a' ? (
                    <Button
                      variant="outlined"
                      style={{ margin: '0px 20px 10px 0px' }}
                      onClick={() => {
                        if (selectedRsa !== props.lead?.rsa_user_id) {
                          assignLead(props.lead?.lead_id, selectedRsa);
                        }
                      }}
                    >
                      {props.lead?.rsa_user_id
                        ? 'Reassign Lead'
                        : 'Assign Lead'}
                    </Button>
                  ) : undefined}
                  {!props.lead?.rsa_user_id ? (
                    <Button
                      variant="outlined"
                      style={{ margin: '0px 20px 10px 0px' }}
                      onClick={() =>
                        assignLead(props.lead?.lead_id, props.user?.user_id)
                      }
                    >
                      Assign Lead to Me
                    </Button>
                  ) : props.lead?.rsa_user_id === props.user?.user_id ? (
                    <Button
                      variant="outlined"
                      style={{
                        color: 'rgba(255, 0, 0, 0.8)',
                        margin: '0px 20px 10px 0px',
                      }}
                      onClick={() =>
                        unassignLead(props.lead?.lead_id, props.user?.user_id)
                      }
                    >
                      Unassign Lead
                    </Button>
                  ) : undefined}
                </p>
              </div>
            </TabPanel>
            <TabPanel isOpen={selectedTab === 2}>
              <div>
                <Typography variant="h5" style={{ display: 'inline-block' }}>
                  Notes
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => setIsNewNoteOpen(true)}
                  style={{ float: 'right' }}
                >
                  Add Note
                </Button>
              </div>
              {isNewNoteOpen ? (
                <NewNote
                  isNewNoteOpen={isNewNoteOpen}
                  setIsNewNoteOpen={setIsNewNoteOpen}
                  lead={props.lead}
                  refreshParent={refreshData}
                />
              ) : undefined}
              {notesList?.length ? (
                <div
                  style={{
                    overflowY: 'auto',
                    backgroundColor: '#FFFFFF',
                    padding: '0px 20px 30px',
                    border: '1px solid #ccc',
                    marginTop: '20px',
                    height: '80%',
                    maxHeight: '450px',
                  }}
                >
                  {notesList}
                </div>
              ) : (
                <Typography style={{ margin: '20px 0px' }}>
                  No notes currently available for this lead
                </Typography>
              )}
            </TabPanel>
            <TabPanel isOpen={selectedTab === 3}>
              <CheckoutHistory lead={props.lead} />
            </TabPanel>
            <TabPanel isOpen={selectedTab === 4}>
              <FavoriteHistory lead={props.lead} />
            </TabPanel>
          </Grid>
        </Grid>
        <ExportLeadDataDialog
          isOpen={isExportDialogOpen}
          lead={props.lead}
          handleOnClose={() => setIsExportDialogOpen(false)}
        />
      </Paper>
    </Modal>
  );
}
