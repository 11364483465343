import React, { useState, useContext } from 'react';

import { getUser } from '../../../helpers';
import { AlertContext } from '../../../Context/AlertContextProvider';

// MUI
import {
  ListItemText,
  TextField,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListSubheader,
  List,
  ListItem,
  Divider,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
// import {
//     KeyboardDatePicker,
//     MuiPickersUtilsProvider,
//   } from "@material-ui/pickers";
//   import MomentUtils from "@date-io/moment";

// Actions
import { getS3Files, deleteCostHistory, listS3Files } from '../../../Actions';

// styles
import '../../styles/Home.css';
import '../../styles/FileHistory.css';

// Icons
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SearchIcon from '@material-ui/icons/Search';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DescriptionIcon from '@material-ui/icons/Description';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LinkIcon from '@material-ui/icons/Link';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// import { createMuiTheme } from "@material-ui/core";
// import { ThemeProvider } from "@material-ui/styles";

// const defaultMaterialTheme = createMuiTheme({
//     palette: {
//       primary: {500: '#FF682C'}
//     },
//   });

const ManualFiles = (props) => {
  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);

  // let ye = new Intl.DateTimeFormat('en', { year: 'numeric'}).format(props.fileList._d);
  // let mo = new Intl.DateTimeFormat('en', { month: '2-digit'}).format(props.fileList._d);
  // let da = new Intl.DateTimeFormat('en', { day: '2-digit'}).format(props.fileList._d);
  // let formattedDate =  `${ye}-${mo}-${da}`;
  const [fileList, setFileList] = useState(props.fileList);
  const [apiFileList] = useState(props.fileList);
  // const [sDate, setSDate] = useState(props.fileList);
  // const [apiSDate, setApiSDate] = useState(props.fileList);
  const [fileName, setFileName] = useState('');
  const [progress, setProgress] = useState(false);
  // const [site] = useState(props.url.split("//")[1]);
  // let header = ["File Name", "Date"]
  // const [startDate, setStartDate] = useState(formattedDate);
  // const [endDate, setEndDate] = useState(formattedDate);
  const [alertOpen, setAlertOpen] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [user] = useState(getUser());
  const [deleteFName, setDeleteFName] = useState('');
  const [listOpen, setListOpen] = useState(undefined);

  const handleClick = (index) => {
    listOpen === index ? setListOpen(undefined) : setListOpen(index);
  };

  const handleGetFile = async (file) => {
    await getS3Files(file)
      .then((response) => {
        const link = window.document.createElement('a');
        link.href = response.data.link;
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  const handleFileSearch = (filter) => {
    setFileName(filter);
    const filterValue = filter.toLowerCase();
    const tempFileName = apiFileList.filter((file) =>
      file.file_name.toLowerCase().includes(filterValue)
    );
    setFileList(tempFileName);
  };

  // const handleStartDate = (date) => {
  //   let ye = new Intl.DateTimeFormat('en', { year: 'numeric'}).format(date._d);
  //   let mo = new Intl.DateTimeFormat('en', { month: '2-digit'}).format(date._d);
  //   let da = new Intl.DateTimeFormat('en', { day: '2-digit'}).format(date._d);
  //   let formattedDate =  `${ye}-${mo}-${da}`;
  //   setStartDate(formattedDate);
  // };

  // const handleEndDate = (date) => {
  //   let ye = new Intl.DateTimeFormat('en', { year: 'numeric'}).format(date._d);
  //   let mo = new Intl.DateTimeFormat('en', { month: '2-digit'}).format(date._d);
  //   let da = new Intl.DateTimeFormat('en', { day: '2-digit'}).format(date._d);
  //   let formattedDate =  `${ye}-${mo}-${da}`;
  //   setEndDate(formattedDate);
  // };

  // const viewFilesByDate = () => {
  //   let tempFileName = []
  //   apiFileList.map((date, i) => {
  //       if (date.LastModified.split("T")[0] <= endDate && date.LastModified.split("T")[0] >= startDate) {
  //           tempFileName.push(apiFileList[i])
  //          }
  //   })
  //   setFileList(tempFileName);
  // };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleCautionAlertOpen = (fName) => {
    setDeleteFName(fName);
    setAlertOpen(true);
  };

  const handleDeleteAlertClose = () => {
    setDeleteAlertOpen(false);
  };

  const handleDeleteAlertOpen = () => {
    setDeleteAlertOpen(true);
    setAlertOpen(false);
  };

  const handleConfirmDelete = () => {
    deleteCostHistory(deleteFName)
      .then((result) => {
        setMessageType('success');
        setMessage(
          `All cost records associated with ${deleteFName} have been deleted`
        );
        handleAlertOpen(true);
        handleDeleteAlertClose();
        listS3Files()
          .then((result) => {
            setFileList(result.data.fileList);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        setMessageType('error');
        setMessage(
          `There was a problem deleting cost records associated with ${deleteFName}`
        );
        handleDeleteAlertClose();
      });
  };

  const alert = () => {
    return (
      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">DELETE PRODUCTS!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will delete ALL SKUs associated with the uploaded file! If you
            do not wish to delete ALL SKUs associated with this file (
            <span style={{ color: '#FF0000' }}>
              Any printed QR codes will no longer work
            </span>
            ), go to the product search page to delete individual products.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus={true}
            onClick={handleAlertClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleDeleteAlertOpen}
            style={{ color: 'red' }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const confirmDeleteAlert = () => {
    return (
      <Dialog
        open={deleteAlertOpen}
        onClose={handleDeleteAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">DELETE PRODUCTS!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you absolutely sure you want to delete{' '}
            <span style={{ color: '#FF0000' }}>ALL</span> SKUs associated with
            this file?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus={true}
            onClick={handleDeleteAlertClose}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirmDelete}
            style={{ color: 'red' }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderManualFiles = (files) => {
    files
      .sort((a, b) => {
        return (
          new Date(a.LastModified).getTime() -
          new Date(b.LastModified).getTime()
        );
      })
      .reverse();
    return files ? (
      files.map((file, index) => {
        return (
          <TableRow key={index}>
            <TableCell className="data-cell-files">
              <Button onClick={() => handleGetFile(file.file_name)}>
                <InsertDriveFileIcon style={{ color: '#4E564E' }} />
                {file.file_name.split('manual/')[1]}
              </Button>
            </TableCell>
            <TableCell className="data-cell-date">
              {file.LastModified.split('T')[0].split('.')[0]}
            </TableCell>
            {user.access_type_cd === 'a' ? (
              <TableCell className="data-cell-delete">
                <Button
                  variant="contained"
                  onClick={() => handleCautionAlertOpen(file.file_name)}
                  style={{ color: '#FF0000' }}
                >
                  Delete
                </Button>
              </TableCell>
            ) : (
              ''
            )}
          </TableRow>
        );
      })
    ) : (
      <div>No Files</div>
    );
  };
  return (
    <div>
      <div className="fh__container-full">
        <div>
          <Typography variant="h4">Manual File Uploads</Typography>
          {progress && <CircularProgress size={70} />}
        </div>
        <List
          // className="siteinfo__files-paper"
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItem>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText>
              <TextField
                type="text"
                label="Search"
                value={fileName}
                onChange={(e) => handleFileSearch(e.target.value)}
                style={{ width: '50%' }}
              />
            </ListItemText>
          </ListItem>
          {/* <ListItem>
                      <ListItemText>
                      <div className="admin__date-picker">
                      <ThemeProvider theme={defaultMaterialTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/DD/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Start Date"
                    value={startDate}
                    onChange={handleStartDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputProps={{
                      style: {
                          color:"4E564E",
                      }
                  }}
                    autoOk={true}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/DD/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="End Date"
                    value={endDate}
                    onChange={handleEndDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    autoOk={true}
                  />
                </MuiPickersUtilsProvider>
                </ThemeProvider>
                <Button
                  variant="contained"
                  onClick={viewFilesByDate}
                  style={{height:"50%", marginTop:25}}>
                      Submit
                </Button>
              </div>
                      </ListItemText>
                  </ListItem> */}
          <Divider />
        </List>

        <Table>
          <TableBody>
            <TableRow className="files-header-row">
              {/* {header.map((data, i) => { 
                  return ( 
                  <TableCell className="header-cell" key={i}>{data}</TableCell>
                  )})} */}
              <TableCell className="data-cell-name-header">File Name</TableCell>
              <TableCell
                style={{ fontWeight: 900, fontSize: 20, textAlign: 'left' }}
              >
                Date
              </TableCell>
              {user.access_type_cd === 'a' ? (
                <TableCell
                  style={{ fontWeight: 900, fontSize: 20, textAlign: 'center' }}
                ></TableCell>
              ) : (
                ''
              )}
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <Divider style={{ height: 5 }} />
      <div className="fh__container-full">
        <div
          style={{ height: 500, overflow: 'auto', overflowWrap: 'break-word' }}
        >
          <Table>
            <TableBody>{renderManualFiles(fileList)}</TableBody>
          </Table>
        </div>
      </div>
      <div className="fh__container-mobile">
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              className="fh__list-sub-header"
            >
              <div style={{ width: '100%' }}>
                <Typography variant="h6">Manual File Uploads</Typography>
              </div>
            </ListSubheader>
          }
        >
          {fileList.map((file, index) => {
            return (
              <List key={index}>
                <ListItem
                  button
                  onClick={() => handleClick(index)}
                  className="fh__list-menu"
                >
                  <ListItemIcon>
                    <InsertDriveFileIcon style={{ color: '#FF682C' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.file_name
                      .split('manual/')[1]
                      .split('_')
                      .slice(1)}
                  />
                  {listOpen === index ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                {listOpen === index ? (
                  <Collapse in={true} timeout="auto" unmountOnExit>
                    <List style={{ backgroundColor: '#FFF' }}>
                      <ListItem>
                        <ListItemIcon>
                          <DateRangeIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="Updated"
                          secondary={
                            file.LastModified.split('T')[0].split('.')[0]
                          }
                        />
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemIcon>
                          <Button
                            onClick={() =>
                              handleCautionAlertOpen(file.file_name)
                            }
                            style={{ marginLeft: -20 }}
                          >
                            <DeleteForeverIcon style={{ color: '#FF0000' }} />
                          </Button>
                        </ListItemIcon>
                        <ListItemText primary={'Delete?'} />
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <Button
                          onClick={() => handleGetFile(file.file_name)}
                          style={{ marginLeft: -15 }}
                        >
                          <ListItemIcon>
                            <LinkIcon />
                          </ListItemIcon>
                        </Button>
                        <ListItemText primary="Download File" />
                      </ListItem>
                    </List>
                  </Collapse>
                ) : (
                  <></>
                )}
              </List>
            );
          })}
        </List>
      </div>
      {alert()}
      {confirmDeleteAlert(fileList)}
    </div>
  );
};

export default ManualFiles;
