import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { baseUrl } from '../../Contants';

// styles
import '../styles/LeadManagement.css';

// MUI
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { CloseRounded, Delete, Edit } from '@material-ui/icons';

import { AlertContext } from '../../Context/AlertContextProvider';
import { NewNoteInput, NoteInput } from './NoteInputs';

function NewNote(props) {
  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);

  const createNote = async (lead_id, note) => {
    if (!lead_id) {
      setMessageType('error');
      setMessage('Error creating note');
      handleAlertOpen();
      return;
    }

    const result = await axios.post(`${baseUrl}/api/leads/${lead_id}/notes`, {
      note,
    });

    if (result.status !== 200) {
      setMessageType('error');
      setMessage('Error creating note');
      handleAlertOpen();
      return;
    }

    setMessageType('success');
    setMessage('New note created!');
    handleAlertOpen();
    props.refreshParent();
    props.setIsNewNoteOpen(false);
  };

  return (
    <Dialog
      open={props.isNewNoteOpen}
      onClose={() => props.setIsNewNoteOpen(false)}
      aria-labelledby="New Note Dialog"
      aria-describedby="New Note Dialog"
    >
      <DialogTitle id="new-note-dialog-title">New Note</DialogTitle>
      <DialogContent className="lead-management__dialog-content">
        <NewNoteInput
          leadId={props.lead?.lead_id}
          isFocused={true}
          onCancel={() => props.setIsNewNoteOpen(false)}
          onSubmit={(note) => createNote(props.lead?.lead_id, note)}
        />
      </DialogContent>
    </Dialog>
  );
}

function NoteRecord(props) {
  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);

  const updateNote = async (lead_id, note_id, note) => {
    if (!lead_id || !note_id) {
      setMessageType('error');
      setMessage('Error updating note');
      handleAlertOpen();
      return;
    }

    const result = await axios.put(
      `${baseUrl}/api/leads/${lead_id}/notes/${note_id}`,
      { note }
    );

    if (result.status !== 200) {
      setMessageType('error');
      setMessage('Error updating note');
      handleAlertOpen();
      return;
    }

    setMessageType('success');
    setMessage('Note updated!');
    handleAlertOpen();
    props.refreshParent();
  };

  const deleteNote = async (lead_id, note_id) => {
    if (!lead_id || !note_id) {
      setMessageType('error');
      setMessage('Error deleting note');
      handleAlertOpen();
      return;
    }

    const result = await axios.delete(
      `${baseUrl}/api/leads/${lead_id}/notes/${note_id}`
    );

    if (result.status !== 200) {
      setMessageType('error');
      setMessage('Error deleting note');
      handleAlertOpen();
      return;
    }

    setMessageType('success');
    setMessage('Note deleted!');
    handleAlertOpen();
    props.refreshParent();
  };

  return (
    <NoteInput
      leadId={props.note?.lead_id}
      note={props.note}
      onUpdate={(note) =>
        updateNote(props.note?.lead_id, props.note?.note_id, note)
      }
      onDelete={() => deleteNote(props.note?.lead_id, props.note?.note_id)}
    />
  );
}

export default function ViewNotesModal(props) {
  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);
  const [isNewNoteOpen, setIsNewNoteOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const refreshData = () => {
    setRefresh(true);
  };

  const getNotes = async (lead_id) => {
    if (!lead_id) return;
    const result = await axios(`${baseUrl}/api/leads/${lead_id}/notes`);

    if (result.status !== 200) {
      setMessageType('error');
      setMessage('Error retrieving notes');
      handleAlertOpen();
      setNotes([]);
      return;
    }

    setNotes([...result.data.leadNoteList]);
  };

  useEffect(() => {
    if (!props.isModalOpen || !props.lead?.lead_id) {
      setNotes([]);
      return;
    }

    refreshData();
  }, [props.isModalOpen, props.lead?.lead_id]);

  useEffect(() => {
    if (!refresh) return;

    getNotes(props.lead?.lead_id);
    setRefresh(false);
  }, [refresh]);

  const notesList = notes
    ?.sort((a, b) => {
      return (
        new Date(b.created_dttm).getTime() - new Date(a.created_dttm).getTime()
      );
    })
    .map((note, index) => {
      return (
        <NoteRecord
          key={'note-' + note.note_id}
          note={note}
          refreshParent={refreshData}
        />
      );
    });

  return (
    <Modal
      open={props.isModalOpen}
      onClose={props.handleOnClose}
      aria-labelledby="customer notes modal"
      aria-describedby="modal for managing customer notes"
    >
      <Paper
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          minHeight: '60%',
          width: '90%',
          padding: '20px 0px',
        }}
      >
        <div style={{ margin: '0px 15px' }}>
          <CloseRounded
            style={{ float: 'right' }}
            onClick={props.handleOnClose}
          ></CloseRounded>
          <Typography variant="h5">Notes</Typography>
          <Button
            variant="outlined"
            onClick={() => setIsNewNoteOpen(true)}
            style={{ marginTop: '15px' }}
          >
            Add Note
          </Button>
        </div>
        <div>
          {isNewNoteOpen ? (
            <NewNote
              isNewNoteOpen={isNewNoteOpen}
              setIsNewNoteOpen={setIsNewNoteOpen}
              lead={props.lead}
              refreshParent={refreshData}
            />
          ) : undefined}
          {notesList?.length ? (
            <div
              style={{
                overflowY: 'auto',
                backgroundColor: '#FFFFFF',
                padding: '0px 15px 30px',
                marginTop: '20px',
                maxHeight: '70vh',
              }}
            >
              {notesList}
            </div>
          ) : (
            <Typography style={{ margin: '20px 15px' }}>
              No notes currently available for this lead
            </Typography>
          )}
        </div>
      </Paper>
    </Modal>
  );
}
