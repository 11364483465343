import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import { getUser } from '../helpers';
import { baseUrl } from '../Contants';

export const AdminLoadedMarginContext = createContext();

export const AdminLoadedMarginContextProvider = ({ children }) => {
  const [user] = useState(getUser());
  const [loadedMargin, setLoadedMargin] = useState([]);
  const [update, setUpdate] = useState(false);

  const isAdmin = user.access_type_cd === 'a' ? true : false;

  useEffect(() => {
    async function getLoadedMargin() {
      const result = await axios(`${baseUrl}/api/admin/margin/loaded`);
      setLoadedMargin(result.data.data);
    }
    if (isAdmin) {
      getLoadedMargin();
      setUpdate(false);
    }
  }, [isAdmin, update]);

  return (
    <AdminLoadedMarginContext.Provider value={{ loadedMargin, setUpdate }}>
      {children}
    </AdminLoadedMarginContext.Provider>
  );
};
