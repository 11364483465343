import { makeStyles } from '@material-ui/core';

const productsStyles = makeStyles((theme) => ({
  productsSearchPaper: {
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: 13,
  },
  productFilterButton: {
    background: '#EEEEEE',
    margin: 10,
    color: '#FF682C',
  },
  tableFilter: {
    margin: 5,
    [theme.breakpoints.down('xl')]: {
      margin: 5,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 0,
    },
  },
  productsListContainer: {
    width: '100%',
    marginLeft: 0,
    marginRight: 'auto',
    marginTop: 150,
  },
  productsClearQueueY: {
    color: '#De0040',
  },
  productsClearQueueN: {
    color: '#15782c',
  },
  productsCheckbox: {
    color: '#FF682C',
  },
  productsPrintQueue: {
    marginTop: 50,
    width: '100%',
  },
  productsProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  productsFooter: {
    padding: 30,
  },
  productsSearchAccordian: {
    marginTop: 15,
  },
  productsRoleSelectGrid: {
    marginBottom: 20,
  },
  productsPrintQueueAppBar: {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: '#FFFFFF',
  },
  productsPrintQueueDivider: {
    height: 40,
  },
  productsClearQueueButton: {
    marginLeft: 10,
    color: '#FF0000',
    paddingRight: 20,
  },
  productsPrintQueueContent: {
    justifyContent: 'center',
    width: '100%',
    padding: '20px 0px',
    borderTop: 'solid 1px #333',
    borderBottom: 'solid 1px #333',
    background: '#e8e8e8',
  },
  productPrintQueueList: {
    paddingRight: 0,
    width: '100%',
    margin: 'auto',
    maxHeight: 550,
    overflow: 'auto',
    background: '#fff',
  },
  productsPrintFooter: {
    marginBottom: 10,
    textAlign: 'center',
    textDecoration: 'underline',
  },
  productsContainerFull: {
    [theme.breakpoints.down('xl')]: {
      display: 'block,',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  productsContainerMobile: {
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
}));

export { productsStyles };
