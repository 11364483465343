import React, { useState, createContext, useEffect, useContext } from 'react';
import axios from 'axios';

// url
import { baseUrl } from '../Contants';

import { ProgressContext } from '../Context/ProgressContextProvider';

export const ProductsWithSearchContext = createContext();

export const ProductsWithSearchContextProvider = ({ children }) => {
  const { handleProgressOpen, handleProgressClose } =
    useContext(ProgressContext);
  const [productsList, setProductsList] = useState([]);
  // Filter variables
  const [manufacturerFilter, setManufacturerFilter] = useState('');
  const [distributorFilter, setDistributorFilter] = useState('');
  const [tradeNameFilter, setTradeNameFilter] = useState('');
  const [styleFilter, setStyleFilter] = useState('');
  const [SKUFilter, setSKUFilter] = useState('');
  const [colorFilter, setColorFilter] = useState('');
  const [colorNumberFilter, setColorNumberFilter] = useState('');
  const [materialFilter, setMaterialFilter] = useState('');
  const [materialSubFilter, setMaterialSubFilter] = useState('');
  const [collectionFilter, setCollectionFilter] = useState('');
  const [displayNameFilter, setDisplayNameFilter] = useState('');
  const [privateStyleNameFilter, setPrivateStyleNameFilter] = useState('');
  const [privateStyleNumberFilter, setPrivateStyleNumberFilter] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [getProductsList, setGetProductsList] = useState(false);
  const [roleId, setRId] = useState();
  const [updateProduct, setUpdateProduct] = useState();
  const [rpoFilter, setRpoFilter] = useState(false);
  const [discontinuedFilter, setDiscontinuedFilter] = useState(false);
  const [minPriceFilter, setMinPriceFilter] = useState('');
  const [maxPriceFilter, setMaxPriceFilter] = useState('');

  useEffect(() => {
    async function getProducts() {
      let url = '?offset=0';
      url =
        manufacturerFilter.length > 0
          ? url + `&manufacturer=${manufacturerFilter}`
          : url;
      url =
        distributorFilter.length > 0
          ? url + `&distributor=${distributorFilter}`
          : url;
      url =
        tradeNameFilter.length > 0
          ? url + `&tradeName=${tradeNameFilter}`
          : url;
      url = styleFilter.length > 0 ? url + `&style=${styleFilter}` : url;
      url = SKUFilter.length > 0 ? url + `&sku=${SKUFilter}` : url;
      url = colorFilter.length > 0 ? url + `&color=${colorFilter}` : url;
      url =
        colorNumberFilter.length > 0
          ? url + `&color_number=${colorNumberFilter}`
          : url;
      url =
        materialFilter.length > 0
          ? url + `&materialClass=${materialFilter}`
          : url;
      url =
        materialSubFilter.length > 0
          ? url + `&materialSubClass=${materialSubFilter}`
          : url;
      url = roleId != null ? url + `&role_id=${roleId}` : url;
      url =
        collectionFilter.length > 0
          ? url + `&collection=${collectionFilter}`
          : url;
      url =
        displayNameFilter.length > 0
          ? url + `&displayName=${displayNameFilter}`
          : url;
      url =
        privateStyleNameFilter.length > 0
          ? url + `&privateStyleName=${privateStyleNameFilter}`
          : url;
      url =
        privateStyleNumberFilter.length > 0
          ? url + `&privateStyleNumber=${privateStyleNumberFilter}`
          : url;
      url = rpoFilter === true ? url + `&rpo=${rpoFilter}` : url;
      url =
        discontinuedFilter === true
          ? url + `&discontinued=${discontinuedFilter}`
          : url;
      url =
        minPriceFilter.length > 0 ? url + `&minPrice=${minPriceFilter}` : url;
      url =
        maxPriceFilter.length > 0 ? url + `&maxPrice=${maxPriceFilter}` : url;
      const result = await axios(`${baseUrl}/api/user/products${url}`);

      setProductsList(result.data.data);

      Number(result.status) === 200
        ? handleProgressClose()
        : handleProgressOpen();
    }
    if (getProductsList) {
      getProducts();
      setGetProductsList(false);
    }
  }, [
    manufacturerFilter,
    distributorFilter,
    tradeNameFilter,
    styleFilter,
    SKUFilter,
    colorFilter,
    colorNumberFilter,
    materialFilter,
    materialSubFilter,
    getProductsList,
    collectionFilter,
    discontinuedFilter,
    displayNameFilter,
    privateStyleNameFilter,
    privateStyleNumberFilter,
    roleId,
    rpoFilter,
    minPriceFilter,
    maxPriceFilter,
  ]);

  return (
    <ProductsWithSearchContext.Provider
      value={{
        productsList,
        setManufacturerFilter,
        setDistributorFilter,
        setTradeNameFilter,
        setStyleFilter,
        setSKUFilter,
        setColorFilter,
        setColorNumberFilter,
        setMaterialFilter,
        setMaterialSubFilter,
        setGetProductsList,
        setRId,
        setPrivateStyleNumberFilter,
        setPrivateStyleNameFilter,
        setUpdateProduct,
        setCollectionFilter,
        setDisplayNameFilter,
        setRpoFilter,
        setDiscontinuedFilter,
        setMinPriceFilter,
        setMaxPriceFilter,
        handleProgressOpen,
        handleProgressClose,
        manufacturerFilter,
        distributorFilter,
        tradeNameFilter,
        styleFilter,
        SKUFilter,
        colorFilter,
        colorNumberFilter,
        materialFilter,
        materialSubFilter,
        updateProduct,
        collectionFilter,
        displayNameFilter,
        privateStyleNumberFilter,
        privateStyleNameFilter,
        rpoFilter,
        discontinuedFilter,
        minPriceFilter,
        maxPriceFilter,
      }}
    >
      {children}
    </ProductsWithSearchContext.Provider>
  );
};
