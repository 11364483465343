import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AdminCompanyMarginContext } from '../../../Context/AdminCompanyMarginContextProvider';
import { AdminLoadedMarginContext } from '../../../Context/AdminLoadedMarginContextProvider';
import { AdminMaterialClassMarginContext } from '../../../Context/AdminMaterialClassMarginContextProvider';
import { AdminContext } from '../../../Context/AdminContextProvider';

import { InfoButtonContextProvider } from '../../Shared/InfoButton';

import { TaxUpdate } from '../ManageTax/TaxUpdate';
import { SalesTax } from '../ManageTax/SalesTax';

// Styles
import '../../styles/MND.css';

// MUI
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
// import InfoIcon from "@material-ui/icons/Info";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Button,
  NativeSelect,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  DialogTitle,
  DialogContent,
  Dialog,
  Switch,
} from '@material-ui/core';
import {
  updateCompanyMargin,
  updateLoadedMargin,
  upsertRoleToMargin,
  getRetailRoundUp,
  setRetailRoundUp,
  getUsePromoPrice,
  setUsePromoPrice,
} from '../../../Actions';
import { AlertContext } from '../../../Context/AlertContextProvider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

const CompanyMarginUpdate = () => {
  const { roles } = useContext(AdminContext);
  const { margin, setUpdate } = useContext(AdminCompanyMarginContext);
  const { loadedMargin, setUpdate: setLoadedUpdated } = useContext(
    AdminLoadedMarginContext
  );
  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);
  const {
    setId,
    materialClassMargins,
    setUpdate: setMaterialClassMargins,
  } = useContext(AdminMaterialClassMarginContext);

  const [marginRate, setCompanyMargin] = useState(0);
  const [loadedMarginRate, setLoadedMargin] = useState(0);
  const [materialMarginRate, setMaterialMarginRate] = useState({});
  const [productDisclaimer, setProductDisclaimer] = useState({});
  const [addToCost, setAddToCost] = useState({});
  const [addToPrice, setAddToPrice] = useState({});
  const [roleId, setRoleId] = useState();
  const [RoundUp, setRoundUp] = useState('0');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [roleName, SetRoleName] = useState();
  const history = useHistory();
  const [switchState, setSwitchState] = useState(false);

  //const [loadedMarginRate, setCompanyMargin] = useState(0);
  useEffect(() => {
    getUsePromoPrice().then((result) => {
      if (parseInt(result.data.data[0].app_value_text) === 1) {
        setSwitchState(true);
      } else {
        setSwitchState(false);
      }
    });

    getRetailRoundUp().then((result) => {
      setRoundUp(result.data.data[0].round_up);
    });

    if (margin.length) {
      setCompanyMargin(margin[0].company_margin);
    }
    if (loadedMargin.length) {
      setLoadedMargin(loadedMargin[0].loaded_margin);
    }
    if (materialClassMargins.length) {
      let tempMarClassMargins = {};
      let tempAddToCost = {};
      let tempAddToPrice = {};
      let tempProductDisclaimer = {};
      materialClassMargins.forEach((element) => {
        tempMarClassMargins[element['material_class']] = element['margin'];
        tempAddToCost[element['material_class']] = element['add_to_cost'];
        tempAddToPrice[element['material_class']] = element['add_to_price'];
        if (element['disclaimer'] == null) {
          tempProductDisclaimer[element['material_class']] = '';
        } else {
          tempProductDisclaimer[element['material_class']] =
            element['disclaimer'];
        }
      });
      setMaterialMarginRate(tempMarClassMargins);
      setAddToCost(tempAddToCost);
      setAddToPrice(tempAddToPrice);
      setProductDisclaimer(tempProductDisclaimer);
    }
  }, [margin, loadedMargin, materialClassMargins]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    history.push('/admin');
  };

  const handleRetailRoundUp = (e) => {
    setRoundUp(e.target.value);
  };

  const handleSetRetailRoundUp = () => {
    setRetailRoundUp(RoundUp)
      .then(() => {
        setMessage('Successfully updated retail round up');
        setMessageType('success');
        handleAlertOpen();
      })
      .catch(() => {
        setMessage('Error updating retail round up');
        setMessageType('error');
        handleAlertOpen();
      });
  };

  const handleRoleSelect = (e) => {
    setRoleId(JSON.parse(e.target.value).role_id);
    SetRoleName(JSON.parse(e.target.value).role_name);
    setId(JSON.parse(e.target.value).role_id);
    handleDialogOpen();
  };

  const handleUpdateCompanyMargin = (e) => {
    setCompanyMargin(e.target.value);
  };

  const handleCompanyMarginUpdate = () => {
    updateCompanyMargin(marginRate)
      .then(() => {
        setMessage('Successfully update company margin');
        setMessageType('success');
        handleAlertOpen();
        setUpdate(true);
      })
      .catch(() => {
        setMessage('Error updating company margin');
        setMessageType('error');
        handleAlertOpen();
      });
  };

  const handleCompanyMarginSubmit = (e) => {
    e.preventDefault();
    handleCompanyMarginUpdate();
  };

  //////////////////////////////////////////

  const handleUpdateLoadedMargin = (e) => {
    setLoadedMargin(e.target.value);
  };

  const handleLoadedMarginUpdate = () => {
    updateLoadedMargin(loadedMarginRate)
      .then(() => {
        setMessage('Successfully update loaded margin');
        setMessageType('success');
        handleAlertOpen();
        setLoadedUpdated(true);
      })
      .catch(() => {
        setMessage('Error updating loaded margin');
        setMessageType('error');
        handleAlertOpen();
      });
  };

  const handleLoadedMarginSubmit = (e) => {
    e.preventDefault();
    handleLoadedMarginUpdate();
  };

  //////////////////////////////////////////

  const handleUpdateMaterialMargin = (e, materialClass) => {
    setMaterialMarginRate({
      ...materialMarginRate,
      [materialClass]: e.target.value,
    });
  };

  const handleUpdateProductDisclaimer = (e, materialClass) => {
    setProductDisclaimer({
      ...productDisclaimer,
      [materialClass]: e.target.value,
    });
  };

  const handleUpdateAddToCost = (e, materialClass) => {
    setAddToCost({
      ...addToCost,
      [materialClass]: e.target.value,
    });
  };

  const handleUpdateAddToPrice = (e, materialClass) => {
    setAddToPrice({
      ...addToPrice,
      [materialClass]: e.target.value,
    });
  };

  // const handleMaterialMarginUpdate = (materialClass, materialMarginRate) => {
  //   updateMaterialClassMargin(materialClass, materialMarginRate)
  //     .then(() => {
  //       setMessage("Successfully updated material margin");
  //       setMessageType("success");
  //       handleAlertOpen();
  //       setLoadedUpdated(true);
  //       setMaterialClassMargins(true);
  //     })
  //     .catch(() => {
  //       setMessage("Error updating loaded margin");
  //       setMessageType("error");
  //       handleAlertOpen();
  //     });
  // };

  const handleRoleMarginUpsert = (
    roleId,
    materialClass,
    materialMarginRate,
    addToCost,
    addToPrice,
    disclaimer
  ) => {
    upsertRoleToMargin(
      roleId,
      materialClass,
      materialMarginRate,
      addToCost,
      addToPrice,
      disclaimer
    )
      .then(() => {
        setMessage('Successfully updated materialClass margin');
        setMessageType('success');
        handleAlertOpen();
        setLoadedUpdated(true);
        setMaterialClassMargins(true);
      })
      .catch(() => {
        setMessage('Error updating loaded margin');
        setMessageType('error');
        handleAlertOpen();
      });
  };

  const handlePromoSwitch = (event) => {
    setSwitchState(event.target.checked);
    if (Number(event.target.checked) === 1) {
      setUsePromoPrice('1');
    }
    if (Number(event.target.checked) === 0) {
      setUsePromoPrice('0');
    }
  };

  // const getMarginRateValue = (materialClass) => {
  //   return ;
  // };

  // const handleMaterialMarginSubmit = (e, materialClass, materialMarginRate) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   // handleMaterialMarginUpdate(materialClass, materialMarginRate);
  // };

  // const handleAddToCostSubmit = (e, materialClass, addToCost) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // };

  const renderChangeInfo = (data) => {
    if (data.length) {
      const marginData = data[0];
      return [
        `LAST MODIFIED BY: ${marginData.name}`,
        `LAST MODIFIED USER: ${marginData.user_name}`,
        `LAST MODIFIED: ${marginData.last_modified_dttm}`,
      ];
    }
  };

  const renderUsePromoPrice = () => {
    return (
      <div>
        <div className="mnd__header">
          <Typography className="mnd__header-text" variant="h4">
            Promo Cost
          </Typography>
        </div>
        <Grid container className="MND__disclaimer-bg">
          <Grid container spacing={0} style={{ padding: 20 }}>
            <Grid item xs={12}>
              <Grid container spacing={0} style={{ marginTop: 50 }}>
                <Grid item xs={5}>
                  <Typography variant="button" className="promo-switch">
                    Standard
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    checked={switchState}
                    onChange={handlePromoSwitch}
                    color="primary"
                    size="medium"
                  ></Switch>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="button" className="promo-switch">
                    Promo
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h6">Promo Cost</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Use distributor promotional cost in lieu of the standard cost
                  when calculating price.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderRetailRoundup = () => {
    return (
      <div>
        <div className="mnd__header">
          <Typography className="mnd__header-text" variant="h4">
            Retail Round Up
          </Typography>
        </div>
        <Grid container className="MND__disclaimer-bg">
          <Grid item xs={9} className="MND__disclaimer-grids">
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Round up to:
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={RoundUp}
                onChange={handleRetailRoundUp}
              >
                <FormControlLabel
                  value=".00"
                  control={<Radio color="default" />}
                  label="None"
                />
                <FormControlLabel
                  value=".05"
                  control={<Radio color="default" />}
                  label={
                    <>
                      $.05
                      <Typography
                        style={{ color: '#FF682C' }}
                        variant="inherit"
                      >
                        {' '}
                        ($2.00, $2.05, $2.10, $2.15...)
                      </Typography>
                    </>
                  }
                />
                <FormControlLabel
                  value=".09"
                  control={<Radio color="default" />}
                  label={
                    <>
                      $.09
                      <Typography
                        style={{ color: '#FF682C' }}
                        variant="inherit"
                      >
                        {' '}
                        ($2.09, $2.19, $2.29, $2.39...)
                      </Typography>
                    </>
                  }
                />
                <FormControlLabel
                  value=".25"
                  control={<Radio color="default" />}
                  label={
                    <>
                      $.25
                      <Typography
                        style={{ color: '#FF682C' }}
                        variant="inherit"
                      >
                        {' '}
                        ($2.00, $2.25, $2.50, $2.75, $3.00)
                      </Typography>
                    </>
                  }
                />
                <FormControlLabel
                  value=".49"
                  control={<Radio color="default" />}
                  label={
                    <>
                      $.49
                      <Typography
                        style={{ color: '#FF682C' }}
                        variant="inherit"
                      >
                        {' '}
                        ($2.49, $2.99)
                      </Typography>
                    </>
                  }
                />
                <FormControlLabel
                  value=".99"
                  control={<Radio color="default" />}
                  label={
                    <>
                      $.99
                      <Typography
                        style={{ color: '#FF682C' }}
                        variant="inherit"
                      >
                        {' '}
                        ($2.99)
                      </Typography>
                    </>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={4} className="MND__disclaimer-grids">
            <Button
              className="admin__tax-update-btn"
              variant="contained"
              onClick={handleSetRetailRoundUp}
            >
              Update
            </Button>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h6">Retail Round Up</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Select the amount you would like the retail price rounded up
                  to.
                  <br />
                  <br />
                  (E.g $.25 rounds the retail price UP to the nearest 25 cents.)
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </div>
    );
  };

  // const renderCompanyMarginDetails = data => {
  //   // return data.length ? <div> Tax Data </div> : <div>Loading...</div>;
  //   if (data.length) {
  //     return (
  //       <div>
  //         {/* <Card>
  //           <CardContent> */}
  //         <form onSubmit={handleCompanyMarginSubmit}>
  //           <TableContainer className="cost-adjustment-paper">
  //           <div className="mnd__header">
  //             <Typography
  //               className="header-text"
  //               variant="h4">
  //               Company Margin
  //             </Typography>
  //             </div>
  //             <Table className="class-margin-details" aria-label="simple table">
  //               <TableHead>
  //                 <TableRow className="mnd__table-row">
  //                   {/* <TableCell><InfoButtonContextProvider buttonLabel="How does this apply?" heading="Company Margin:" messages={["Company wide default margin"]}/></TableCell> */}
  //                 </TableRow>
  //               </TableHead>
  //               <TableBody>
  //                 <TableRow className="mnd__table-row">
  //                   {/* <TableCell alig="center">
  //                     Company Margin (Percentage)</TableCell> */}
  //                     {/* <TableCell
  //                     className="mnd__table-cell"
  //                     align="center"
  //                     width="10px"
  //                   >
  //                     <InfoButtonContextProvider
  //                       buttonLabel="Modification Details"
  //                       heading="Modification Details:"
  //                       messages={renderChangeInfo(margin)}
  //                     />
  //                   </TableCell> */}
  //                   <TableCell
  //                     className="mnd__table-cell"
  //                     align="center"
  //                     width="170px"
  //                   >
  //                     <InfoButtonContextProvider
  //                       buttonLabel="Company Margin (%)"
  //                       heading="Company Margin:"
  //                       messages={[
  //                         "The company margin will be applied if there does not exist a margin at the material class or for an individual style."
  //                       ]}
  //                     />
  //                   </TableCell>
  //                   <TableCell
  //                     className="mnd__table-cell"
  //                     align="center"
  //                     width="170px"
  //                   >
  //                     <TextField
  //                       variant="filled"
  //                       name="companyMargin"
  //                       type="number"
  //                       label="Company Margin (%)"
  //                       placeholder="Company Margin"
  //                       value={marginRate}
  //                       onChange={handleUpdateCompanyMargin}
  //                       size="small"
  //                     />
  //                     <InfoButtonContextProvider
  //                       buttonLabel="Modification Details"
  //                       heading="Modification Details:"
  //                       messages={renderChangeInfo(margin)}
  //                     />
  //                   </TableCell>
  //                   <TableCell
  //                     className="mnd__table-cell"
  //                     align="center"
  //                     width="170px"
  //                   >
  //                     <Button
  //                       className="admin__tax-update-btn"
  //                       variant="contained"
  //                       onClick={handleCompanyMarginSubmit}
  //                     >
  //                       Update
  //                     </Button>
  //                   </TableCell>
  //                 </TableRow>
  //               </TableBody>
  //             </Table>
  //           </TableContainer>
  //         </form>
  //         {/* </CardContent>
  //         </Card> */}
  //       </div>
  //     );
  //   } else {
  //     return <div>Loading...</div>;
  //   }
  // };

  const renderLoadedMargins = (data) => {
    if (data.length) {
      return (
        <div>
          <div className="mnd__header">
            <Typography className="mnd__header-text" variant="h4">
              Loaded Margin
            </Typography>
          </div>
          <Grid container spacing={3} style={{ marginTop: 30 }}>
            <Grid item xs={7} lg={8} style={{ paddingLeft: 40 }}>
              <TextField
                className="mnd__textfield"
                variant="filled"
                name="companyMargin"
                type="number"
                label={
                  <Typography variant="headline" component="small">
                    Loaded margin
                  </Typography>
                }
                placeholder="Default Margin"
                value={loadedMarginRate}
                onChange={handleUpdateLoadedMargin}
                size="small"
              />
              <InfoButtonContextProvider
                buttonLabel="Modification Details"
                heading="Modification Details:"
                messages={renderChangeInfo(loadedMargin)}
              />
            </Grid>
            <Grid
              item
              xs={5}
              lg={4}
              style={{ display: 'flex', alignContent: 'Bottom' }}
            >
              <Button
                style={{ height: 45, color: '#FF682C', marginRight: 10 }}
                // className="admin__tax-update-btn"
                variant="contained"
                onClick={handleLoadedMarginSubmit}
              >
                Update
              </Button>
            </Grid>
            {/* <Grid item xs={12} style={{marginTop:50}}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h5">Loaded Margin (%)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                The Loaded Margin will be applied after Tax Rate. 
                The cost shown throughout the application is a loaded cost. 
                If you wish to not use a loaded cost set the loaded margin to 0 (zero).
                </Typography>
              </AccordionDetails>
            </Accordion>
            </Grid> */}
          </Grid>
        </div>
      );
    } else {
      <div>Loading...</div>;
    }
  };

  const renderCompanyMargins = (data) => {
    if (data.length) {
      return (
        <div>
          <div className="mnd__header">
            <Typography className="mnd__header-text" variant="h4">
              Company Margin
            </Typography>
          </div>
          <Grid container spacing={3} style={{ marginTop: 30 }}>
            <Grid item xs={7} lg={8} style={{ paddingLeft: 40 }}>
              <TextField
                className="mnd__textfield"
                variant="filled"
                name="companyMargin"
                type="number"
                label={
                  <Typography variant="headline" component="small">
                    Company margin
                  </Typography>
                }
                placeholder="Default Margin"
                value={marginRate}
                onChange={handleUpdateCompanyMargin}
                size="small"
              />
              <InfoButtonContextProvider
                buttonLabel="Modification Details"
                heading="Modification Details:"
                messages={renderChangeInfo(margin)}
              />
            </Grid>
            <Grid
              item
              xs={5}
              lg={4}
              style={{ display: 'flex', alignContent: 'Bottom' }}
            >
              <Button
                style={{ height: 45, color: '#FF682C', marginRight: 10 }}
                // className="admin__tax-update-btn"
                variant="contained"
                onClick={handleCompanyMarginSubmit}
              >
                Update
              </Button>
            </Grid>
            {/* <Grid item xs={12} style={{marginTop:50}}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography variant="h5">Company Margin (%)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                The company margin will be applied if there does not exist a margin at the material class or for an individual style.

                </Typography>
              </AccordionDetails>
            </Accordion>
            </Grid> */}
          </Grid>
        </div>
      );
    } else {
      <div>Loading...</div>;
    }
  };

  // const renderLoadedMarginDetails = data => {
  //   // return data.length ? <div> Tax Data </div> : <div>Loading...</div>;
  //   if (data.length) {
  //     return (
  //       <div>
  //         {/* <Card><paper
  //           <CardContent> */}
  //         <form onSubmit={handleLoadedMarginSubmit}>
  //           <TableContainer className="cost-adjustment-paper">
  //             <div className="mnd__header">
  //             <Typography
  //               className="mnd__header-text"
  //               variant="h4">
  //               Loaded Margin
  //             </Typography>
  //             </div>

  //             <Table className="class-margin-details" aria-label="simple table">
  //               <TableHead>
  //                 <TableRow className="mnd__table-row">
  //                   {/* <TableCell><InfoButtonContextProvider buttonLabel="How is this applied?" heading="Company Margin:" messages={["Company wide default margin"]}/></TableCell> */}
  //                 </TableRow>
  //               </TableHead>
  //               <TableBody>
  //                 <TableRow className="mnd__table-row">
  //                   <TableCell
  //                     className="mnd__table-cell"
  //                     align="center"
  //                     width="170px"
  //                   >
  //                     <InfoButtonContextProvider
  //                       buttonLabel="Loaded Margin (%)"
  //                       heading="Loaded Margin:"
  //                       messages={[
  //                         "The Loaded Margin will be applied after Tax Rate. The cost shown throughout the application is a loaded cost. If you wish to not use a loaded cost set the loaded margin to 0 (zero)."
  //                       ]}
  //                     />
  //                   </TableCell>
  //                   <TableCell
  //                     className="mnd__table-cell"
  //                     align="center"
  //                     width="130px"
  //                   >
  //                     <TextField
  //                       variant="filled"
  //                       name="companyMargin"
  //                       type="number"
  //                       label="Loaded Margin (%)"
  //                       placeholder="Default Margin"
  //                       value={loadedMarginRate}
  //                       onChange={handleUpdateLoadedMargin}
  //                       size="small"
  //                     />
  //                      <InfoButtonContextProvider
  //                       buttonLabel="Modification Details"
  //                       heading="Modification Details:"
  //                       messages={renderChangeInfo(margin)}
  //                     />
  //                   </TableCell>
  //                   {/* <TableCell
  //                     className="mnd__table-cell"
  //                     align="center"
  //                     width="10px"
  //                   >
  //                     <InfoButtonContextProvider
  //                       buttonLabel="Modification Details"
  //                       heading="Modification Details:"
  //                       messages={renderChangeInfo(margin)}
  //                     />
  //                   </TableCell> */}
  //                   <TableCell
  //                     className="mnd__table-cell"
  //                     align="center"
  //                     width="170px"
  //                   >
  //                     <Button
  //                       className="admin__tax-update-btn"
  //                       variant="contained"
  //                       onClick={handleLoadedMarginSubmit}
  //                     >
  //                       Update
  //                     </Button>
  //                   </TableCell>
  //                 </TableRow>
  //               </TableBody>
  //             </Table>
  //           </TableContainer>
  //         </form>
  //         {/* </CardContent>
  //         </Card> */}
  //       </div>
  //     );
  //   } else {
  //     return <div>Loading...</div>;
  //   }
  // };

  const renderMarginSelect = () => {
    return (
      <div>
        <div className="mnd__header">
          <Typography className="mnd__header-text" variant="h4">
            Material Class Margins
          </Typography>
        </div>
        <Grid container spacing={3} style={{ marginTop: 30 }}>
          <Grid item xs={12} sm={6} md={4} lg={8} style={{ paddingLeft: 40 }}>
            <NativeSelect
              // className="admin__margin-role-select"
              style={{ width: '100%' }}
              onChange={handleRoleSelect}
              defaultValue={'Select Role'}
            >
              <option disabled>Select Role</option>
              {roles.map((r) => {
                return (
                  <option key={r.role_id} value={JSON.stringify(r)}>
                    {r.role_name}
                  </option>
                );
              })}
            </NativeSelect>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={2}
            style={{ display: 'flex', alignContent: 'Bottom' }}
          >
            <InfoButtonContextProvider
              style={{ float: 'left' }}
              buttonLabel="Modification Details"
              heading="Modification Details:"
              messages={renderChangeInfo(margin)}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderClassMarginDetails = (data) => {
    return (
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ textAlign: 'center' }}>
          <Typography variant={'h4'}>
            Material Class Margins -{' '}
            <span style={{ color: '#FF682C' }}>
              {roleName}
              <Button
                variant="outlined"
                style={{
                  color: '#FF682C',
                  marginLeft: 20,
                  fontSize: 30,
                  float: 'right',
                }}
                onClick={handleDialogClose}
              >
                X
              </Button>
            </span>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div>
            <TableContainer>
              <Table className="class-margin-details" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="mnd__table-cell"
                      component="th"
                      scope="row"
                      align="center"
                    >
                      <InfoButtonContextProvider
                        buttonLabel="Material Class"
                        heading="Material Class:"
                        messages={[
                          'The material class is a general type of material to categorize a product. Margins can be set at this level. If there are no margins set for individual Style this will be used.',
                          'Additional costs such as installation, padding, hardware, etc. can be added using the "Added costs $" field.',
                        ]}
                      />
                    </TableCell>
                    {/* <TableCell
                    className="mnd__table-cell"
                    component="th"
                    scope="row"
                    align="center"
                  >
                    <InfoButtonContextProvider
                      buttonLabel="Modification"
                      heading="Modification Details:"
                      messages={[
                        "Modification details shows who the last user was to change a value and what date/time that change occurred."
                      ]}
                    />
                  </TableCell> */}
                    <TableCell
                      className="mnd__table-cell"
                      component="th"
                      scope="row"
                      align="center"
                    >
                      <InfoButtonContextProvider
                        buttonLabel="Pre-margin Added Costs / UOM"
                        heading="Pre-margin Added Costs / UOM:"
                        messages={[
                          'This is used to add a dollar amount to the final cost based on the selling unit of measure before the margin has been applied.',
                          'For example: adding $1.25 for carpet will add $1.25 per square foot to the total price. This is typically used to cover pad and install OR freight.',
                        ]}
                      />
                    </TableCell>
                    <TableCell
                      className="mnd__table-cell"
                      component="th"
                      scope="row"
                      align="center"
                    >
                      <InfoButtonContextProvider
                        buttonLabel="Material Class Margin"
                        heading="Material Class Margin:"
                        messages={[
                          'This is the margin to be applied based on the material class code and selected role. If no margin is set it will default to the Company Margin.',
                        ]}
                      />
                    </TableCell>
                    <TableCell
                      className="mnd__table-cell"
                      component="th"
                      scope="row"
                      align="center"
                    >
                      <InfoButtonContextProvider
                        buttonLabel="Post-margin Added Costs / UOM"
                        heading="Post-margin Added Costs / UOM:"
                        messages={[
                          'This is used to add a dollar amount to the final retail price based on the selling unit of measure after the margin has been applied.',
                          'For example: adding $1.25 for carpet will add $1.25 per square foot to the total price. This is typically used to cover pad and install OR freight.',
                        ]}
                      />
                    </TableCell>
                    <TableCell
                      className="mnd__table-cell"
                      component="th"
                      scope="row"
                      align="center"
                    >
                      <InfoButtonContextProvider
                        buttonLabel="Material Class Disclaimer"
                        heading="Material Class Disclaimer:"
                        messages={[
                          'This is the disclaimer to be displayed on the product page for the material class and selected role.',
                        ]}
                      />
                    </TableCell>
                    <TableCell
                      className="mnd__table-cell"
                      component="th"
                      scope="row"
                      align="center"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, i) => {
                    return (
                      <TableRow
                        className="mnd__table-row"
                        key={row.material_class + i}
                      >
                        <TableCell
                          className="mnd__table-cell"
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {row.material_class ? row.material_class : undefined}
                        </TableCell>
                        <TableCell className="mnd__table-cell" align="center">
                          <TextField
                            variant="filled"
                            name="premarginaddedcosts"
                            type="number"
                            label="Added Costs $"
                            placeholder="Added Costs $"
                            value={addToCost[row.material_class]}
                            onChange={(e) =>
                              handleUpdateAddToCost(e, row.material_class)
                            }
                            size="small"
                          />
                          {/* </form> */}
                        </TableCell>
                        <TableCell className="mnd__table-cell" align="center">
                          <TextField
                            variant="filled"
                            name="companyMargin"
                            type="number"
                            placeholder={`${marginRate} %`}
                            value={
                              materialMarginRate[row.material_class] === null
                                ? ''
                                : materialMarginRate[row.material_class]
                            }
                            onChange={(e) =>
                              handleUpdateMaterialMargin(e, row.material_class)
                            }
                            size="small"
                          />
                        </TableCell>
                        <TableCell className="mnd__table-cell" align="center">
                          <TextField
                            variant="filled"
                            name="postmarginaddedcosts"
                            type="number"
                            label="Added Costs $"
                            placeholder="Added Costs $"
                            value={addToPrice[row.material_class]}
                            onChange={(e) =>
                              handleUpdateAddToPrice(e, row.material_class)
                            }
                            size="small"
                          />
                          {/* </form> */}
                        </TableCell>
                        <TableCell className="mnd__table-cell" align="center">
                          <TextField
                            variant="filled"
                            name="productdisclaimer"
                            multiline
                            placeholder="Material Class Disclaimer"
                            value={productDisclaimer[row.material_class]}
                            onChange={(e) =>
                              handleUpdateProductDisclaimer(
                                e,
                                row.material_class
                              )
                            }
                            size="small"
                          />
                          <span>{`\xa0\xa0\xa0\xa0\xa0`}</span>
                          <InfoButtonContextProvider
                            buttonLabel="Modification Details"
                            heading="Modification Details:"
                            messages={[
                              `${row.user_name}`
                                ? `Last Modified By: \xa0\xa0\xa0\xa0\xa0 ${row.user_name}`
                                : `${undefined}`,
                              `${row.name}`
                                ? `Last Modified User: \xa0\xa0\xa0\xa0\xa0 ${row.name}`
                                : `${undefined}`,
                              `${row.last_modified_dttm}`
                                ? `Last Modified: \xa0\xa0\xa0\xa0\xa0 ${row.last_modified_dttm}`
                                : `${undefined}`,
                            ]}
                          />
                        </TableCell>
                        <TableCell className="mnd__table-cell" align="center">
                          <Button
                            // style={{height:45, color:"#FF682C", marginRight:10}}
                            className="admin__tax-update-btn"
                            variant="contained"
                            onClick={(e) =>
                              handleRoleMarginUpsert(
                                roleId,
                                row.material_class_cd,
                                materialMarginRate[row.material_class],
                                addToCost[row.material_class],
                                addToPrice[row.material_class],
                                productDisclaimer[row.material_class]
                              )
                            }
                          >
                            Update
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container style={{ display: 'flex', alignContent: 'center' }}>
          <Grid item xs={12} sm={12} md={6} lg={3} className="grid-item">
            <Paper elevation={5} className="mnd__box-paper">
              <TaxUpdate />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} className="grid-item">
            <Paper elevation={5} className="mnd__box-paper">
              {renderLoadedMargins(margin)}
              <Accordion style={{ marginTop: 75 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Loaded Margin (%)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The Loaded Margin will be applied after Tax Rate. The cost
                    shown throughout the application is a loaded cost. If you
                    wish to not use a loaded cost set the loaded margin to 0
                    (zero).
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} className="grid-item">
            <Paper elevation={5} className="mnd__box-paper">
              {renderCompanyMargins(margin)}
              <Accordion style={{ marginTop: 75 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <Typography variant="h6">Company Margin (%)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    The company margin will be applied if there does not exist a
                    margin at the material class or for an individual style.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} className="grid-item">
            <Paper elevation={5} className="mnd__box-paper">
              <SalesTax />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} className="grid-item">
            <Paper elevation={5}>{renderRetailRoundup()}</Paper>
          </Grid>
          {roles.length ? (
            <Grid item xs={12} sm={12} md={6} lg={3} className="grid-item">
              <Paper elevation={2} className="mnd__mat-margin-sel">
                {renderMarginSelect()}
                <Accordion style={{ marginTop: 75 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <Typography variant="h6">Material Class</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      "The material class is a general type of material to
                      categorize a product. Margins can be set at this level. If
                      there are no margins set for individual Style this will be
                      used.", 'Additional costs such as installation, padding,
                      hardware, etc. can be added using the "Added costs $"
                      field.'
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Paper>

              <Paper elevation={2} className="mnd__mat-margin-sel">
                {renderClassMarginDetails(materialClassMargins)}
              </Paper>
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={12} sm={12} md={4} lg={3} className="grid-item">
            <Paper
              elevation={5}
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: 'auto',
              }}
            >
              {renderUsePromoPrice()}
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {/* <Card>
            <CardContent> */}
      {/* <Paper className="admin__tax-header">
        <Typography
          className="admin__tax-mnd__header-text"
          variant="h5"
          align="center"
        >
          Company Margin
        </Typography>
      </Paper> */}

      {/* <h1 align="center" className="admin__tax-content-label">
        {<AttachMoneySharpIcon />} Tax Adjustment
      </h1> */}

      {/* <Paper elevation={2} className="mnd__mat-margin-sel">
          <TaxUpdate/>
        </Paper> */}

      {/* <h1 align="center" className="admin__tax-content-label">
        {<AttachMoneySharpIcon />} Margin Adjustment
      </h1> */}

      {/* <Paper elevation={2} className="mnd__mat-margin-sel">
        {renderLoadedMarginDetails(margin)}
      </Paper> */}

      {/* <Paper elevation={2} className="mnd__mat-margin-sel">
        {renderCompanyMarginDetails(margin)}
      </Paper> */}

      {/* <Paper elevation={2} className="mnd__mat-margin-sel">
        <SalesTax/>
      </Paper> */}

      {/* </CardContent>
      </Card> */}
    </div>
  );
};

export default CompanyMarginUpdate;
