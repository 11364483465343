import React, { useContext, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

// styles
import '../styles/Admin.css';

// denpendents
import { AdminContext } from '../../Context/AdminContextProvider';
import ManageUsers from './Users/ManageUsers';
import ManageAnnouncements from './Announcements/ManageAnnouncements';
import ManageBulletin from './Bulletin/ManageBulletin';
import CompanyMarginUpdate from './Margins/CompanyMargin';
import DisclaimerUpdate from './Disclaimer/Disclaimer';

const Admin = () => {
  const [value, setValue] = useState('users');
  const { users } = useContext(AdminContext);
  const { roles, locations } = useContext(AdminContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  return (
    <div className="admin__container">
      <Paper className="admin__tab-controller">
        {/* Full screen menu */}
        <Tabs
          TabIndicatorProps={{
            style: { backgroundColor: '#FF682C', color: '#FF682C' },
          }}
          className="admin__full-screen-tabs"
          value={value}
          onChange={handleChange}
          centered
        >
          <Tab
            className="admin__tabs"
            value="users"
            label="Users"
            {...a11yProps('users')}
          />
          <Tab
            className="admin__tabs"
            value="announcement"
            label="Announcement"
            {...a11yProps('announcement')}
          />
          <Tab
            className="admin__tabs"
            value="bulletin"
            label="Bulletin"
            {...a11yProps('bulletin')}
          />
          {/* <Tab value="more" label="Tax" {...a11yProps("more")} /> */}
          <Tab
            className="admin__tabs"
            value="CompanyMargin"
            label="Cost Adjustments"
            {...a11yProps('CompanyMargin')}
          />
          <Tab
            className="admin__tabs"
            value="DisclaimerUpdate"
            label="Disclaimers"
            {...a11yProps('DisclaimerUpdate')}
          />
        </Tabs>
        {/* Mobile menu */}
        <Tabs
          TabIndicatorProps={{ style: { backgroundColor: '#FF682C' } }}
          className="admin__mobile-tabs"
          value={value}
          onChange={handleChange}
          orientation="vertical"
        >
          <Tab
            className="admin__tabs"
            value="users"
            label="Users"
            {...a11yProps('users')}
          />
          <Tab
            className="admin__tabs"
            value="announcement"
            label="Announcement"
            {...a11yProps('announcement')}
          />
          <Tab
            className="admin__tabs"
            value="bulletin"
            label="Bulletin"
            {...a11yProps('bulletin')}
          />
          {/* <Tab value="more" label="Tax" {...a11yProps("more")} /> */}
          <Tab
            className="admin__tabs"
            value="CompanyMargin"
            label="Cost Adjustments"
            {...a11yProps('CompanyMargin')}
          />
          <Tab
            className="admin__tabs"
            value="DisclaimerUpdate"
            label="Disclaimers"
            {...a11yProps('DisclaimerUpdate')}
          />
        </Tabs>
      </Paper>
      <TabPanel value={value} index="users">
        <ManageUsers users={users} roles={roles} locations={locations} />
      </TabPanel>
      <TabPanel value={value} index="announcement">
        <ManageAnnouncements />
      </TabPanel>
      <TabPanel value={value} index="bulletin">
        <ManageBulletin />
      </TabPanel>
      {/* <TabPanel value={value} index="more">
        <TaxUpdate />
      </TabPanel> */}
      <TabPanel value={value} index="CompanyMargin">
        <CompanyMarginUpdate />
      </TabPanel>
      <TabPanel value={value} index="DisclaimerUpdate">
        <DisclaimerUpdate />
      </TabPanel>
    </div>
  );
};

export default Admin;
