import React, { useState, createContext, useEffect } from 'react';
// import axios from "axios";

// url
// import { baseUrl } from "../Contants";

export const HistoryContext = createContext();

export const LookBackContextProvider = ({ children }) => {
  const [changeCount, setChangeCount] = useState(0);

  useEffect(() => {
    // async function getProductLookBack(days) {
    //     const result = await axios(`${baseUrl}/api/user/lookback/${days}`);
    //     setChangeCount(result.data.result.length)
    //     setValue(1);
    //   }
    //getProductLookBack(15);
  });

  return (
    <HistoryContext.Provider value={{ changeCount, setChangeCount }}>
      {children}
    </HistoryContext.Provider>
  );
};
