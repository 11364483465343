export const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const JsonToCsvConverter = (data) => {
  const fields = Object.keys(data[0]);
  const replacer = function (key, value) {
    return !value ? '' : value;
  };

  let csv = data.map(function (row) {
    return fields
      .map(function (fieldName) {
        return JSON.stringify(row[fieldName], replacer);
      })
      .join(',');
  });

  return [fields.join(','), ...csv].join('\r\n');
};

export const download = (filename, data) => {
  const blob = new Blob([data], { type: `text/csv` });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  const encodedFilename = encodeURIComponent(filename.replace(' ', '-'));
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', `${encodedFilename}.csv`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const getStrippedNumber = (number) => {
  const strippedNumber = number?.replace(/\D+/g, '');

  if (!strippedNumber) return '';

  return strippedNumber.length > 11
    ? strippedNumber.substring(strippedNumber.length - 11)
    : strippedNumber;
};

export const sortObjectsByAttribute = (p, c, order, attribute) => {
  const isPhoneSort = attribute.includes('phone');
  let result = 0;
  let prev;
  let curr;

  if (isPhoneSort) {
    prev = getStrippedNumber(p[attribute]) ?? '';
    curr = getStrippedNumber(c[attribute]) ?? '';
  } else {
    prev = p[attribute] ?? '';
    curr = c[attribute] ?? '';
  }

  if (prev < curr) result = -1;
  if (prev > curr) result = 1;

  return order === 'asc' ? result : -1 * result;
};
