import React, { useState, createContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { getUser as getLocalUser, setUser as setLocalUser } from '../helpers';
import { baseUrl } from '../Contants';
import { logout, getCustomerCreateAccountStatus } from '../Actions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const DELAY = 5000;
  const [user, setUser] = useState(getLocalUser());
  const [loading, setLoading] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [authType, setAuthType] = useState('');
  const [canCreate, setCanCreate] = useState(false);
  const [roles, setRoles] = useState([]);
  const [roleId, setRoleId] = useState();
  const [roleName, setRoleName] = useState();
  const [rolePrefix, setRolePrefix] = useState();

  const location = useLocation();
  const intervalRef = useRef(null);

  async function handleLogout() {
    setUser({});
    await logout();
    setIsLoggedOut(true);
    clearInterval(intervalRef.current);
  }

  async function handleSessionCheck() {
    if (!user.will_expire_at) return;

    const currentTime = new Date().getTime();

    if (currentTime >= user.will_expire_at) handleLogout();
  }

  useEffect(() => {
    async function getCustomerCAS() {
      await getCustomerCreateAccountStatus().then((result) => {
        result.data.data[0].app_value > 0
          ? setCanCreate(true)
          : setCanCreate(false);
      });
    }

    async function getUser(url) {
      setLoading(true);
      const result = await axios(url);
      setUser(result.data);
      setLocalUser(result.data);
      setLoading(false);
      setIsLoggedOut(false);
      setAccessToken('');
    }

    getCustomerCAS();

    if (accessToken && authType === 'google')
      getUser(`${baseUrl}/api/auth/google/user`);
    if (accessToken && authType === 'local')
      getUser(`${baseUrl}/api/auth/local/user`);

    intervalRef.current = setInterval(() => handleSessionCheck(), DELAY);

    return () => clearInterval(intervalRef.current);
  }, [accessToken, authType]);

  useEffect(() => {
    if (user.will_expire_at) handleSessionCheck();
  }, [location]);

  useEffect(() => {
    async function getRoles() {
      const result = await axios(`${baseUrl}/api/admin/roles`);
      setRoles(result.data.roleList);
      setRoleId(result.data.requestUserRole.role_id);
      setRoleName(result.data.requestUserRole.role_name);
      setRolePrefix(result.data.requestUserRole.prefix);
      sessionStorage.setItem('roleId', result.data.requestUserRole.role_id);
      sessionStorage.setItem('roles', JSON.stringify(result.data.roleList));
    }

    if (user && ['a', 'rw', 'ro'].includes(user.access_type_cd)) {
      getRoles();
    } else {
      // Clear out role information
      setRoles([]);
      setRoleId('');
      setRoleName('');
      setRolePrefix('');
      sessionStorage.removeItem('roleId');
      sessionStorage.removeItem('roles');
    }
  }, [user, user.access_type_cd]);

  return (
    <AppContext.Provider
      value={{
        canCreate,
        user,
        setAccessToken,
        setUser,
        setLoading,
        loading,
        setIsLoggedOut,
        isLoggedOut,
        setAuthType,
        roles,
        setRoleId,
        setRoleName,
        roleId,
        roleName,
        rolePrefix,
        setRolePrefix,
        handleLogout,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
