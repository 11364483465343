import React, { useState, useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { AppContext } from '../Context/AppContextProvide';

//MUI
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

function RegistrationAuthRoute({ component: Component, authed, ...rest }) {
  const { user, loading, isLoggedOut, canCreate } =
    React.useContext(AppContext);
  const isUser =
    user.access_type_cd === 'rw' || user.access_type_cd === 'ro' ? true : false;
  const isAdmin = user.access_type_cd === 'a' ? true : false;
  const isCustomer = user.access_type_cd === 'c' ? true : false;
  const history = useHistory();
  const [alertOpen, setAlertOpen] = useState(false);

  const isAuthenticated = new Date().getTime() < user.will_expire_at;

  useEffect(() => {
    setTimeout(() => {
      setAlertOpen(true);
    }, 1000);
  });

  const handleAlertOpen = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    history.push('/signin');
  };

  const alert = () => {
    return (
      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">New account status</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            We are currently not accepting new accounts, thank you.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleAlertClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (loading || !user.user_id) && !isLoggedOut && canCreate === true ? (
    <Route
      {...rest}
      render={(props) => {
        return (isAuthenticated === true && isUser) || isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/register', state: { from: props.location } }}
          />
        );
      }}
    />
  ) : (loading || !user.user_id) && !isLoggedOut && canCreate === false ? (
    <div>{alert()}</div>
  ) : (
    <Route
      {...rest}
      render={(props) => {
        return (isAuthenticated === true && isUser) || isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/customer-home', state: { from: props.location } }}
          />
        );
      }}
    />
  );
}

export default RegistrationAuthRoute;
