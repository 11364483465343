import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import { getUser } from '../helpers';
import { baseUrl } from '../Contants';

export const AdminTaxContext = createContext();

export const AdminTaxContextProvider = ({ children }) => {
  const [user] = useState(getUser());
  const [tax, setTax] = useState([]);
  const [update, setUpdate] = useState(false);
  const [salesTax, setSalesTax] = useState([]);

  const isAdmin = user.access_type_cd === 'a' ? true : false;

  useEffect(() => {
    async function getTax() {
      const result = await axios(`${baseUrl}/api/admin/taxrate`);
      setTax(result.data.data);
    }

    async function getSalesTaxRate() {
      const result = await axios.get(`${baseUrl}/api/admin/salestaxrate`);
      setSalesTax(result.data.data);
    }
    if (isAdmin) {
      getTax();
      getSalesTaxRate();
      setUpdate(false);
    }
  }, [isAdmin, update]);

  return (
    <AdminTaxContext.Provider value={{ tax, salesTax, setUpdate }}>
      {children}
    </AdminTaxContext.Provider>
  );
};
