import React, { useContext, useState, useEffect } from 'react';

// styles
import '../styles/Admin.css';

// Actions
import { updateLocation, deleteLocation } from '../../Actions';

// MUI
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';

// dependents
import { AdminContext } from '../../Context/AdminContextProvider';
import { AlertContext } from '../../Context/AlertContextProvider';
import {
  FormControl,
  FormLabel,
  Grid,
  Switch,
  Tooltip,
} from '@material-ui/core';

const EditLocation = (props) => {
  // const [users] = useState(props.user)
  const [locationName, setLocationName] = useState(
    props.location.location_name
  );
  const [locationDescription, setLocationDescription] = useState(
    props.location.location_description
  );
  const [location, setLocation] = useState(props.location);

  const [customerLocationName, setCustomerLocationName] = useState(
    props.location.customer_facing_name
  );
  const [customerLocationPhone, setCustomerLocationPhone] = useState(
    props.location.location_phone
  );
  const [customerLocationEmail, setCustomerLocationEmail] = useState(
    props.location.location_email
  );
  const [reminderSwitchState, setReminderSwitchState] = useState(
    !!props.location.sample_reminder
  );
  const reminderSwitchToolTip = `Allow this location to send reminders about samples users have checked out`;

  const { setUpdate } = useContext(AdminContext);
  const {
    handleAlertOpen,
    setMessageType,
    setMessage,
    setUserEditOpen,
    userEditOpen,
  } = useContext(AlertContext);

  useEffect(() => {
    setLocation(props.location);
    setLocationName(props.location.location_name);
    setLocationDescription(props.location.location_description);
    setCustomerLocationName(props.location.customer_facing_name);
    setCustomerLocationPhone(props.location.location_phone);
    setCustomerLocationEmail(props.location.location_email);
    setReminderSwitchState(!!props.location.sample_reminder);
  }, [props.role, props.location]);

  const handleUpdateLocation = (locationId) => {
    updateLocation(
      locationId,
      locationName,
      locationDescription,
      reminderSwitchState,
      customerLocationName,
      customerLocationPhone,
      customerLocationEmail
    )
      .then((result) => {
        setMessage('Updated location');
        setMessageType('success');
        handleAlertOpen();
        setUpdate(true);
      })
      .catch((err) => {
        setMessage('Failed to update location');
        setMessageType('error');
        handleAlertOpen();
      });
  };

  // const handleErrorMessage = (userRoleIds) => {
  //   setMessage("Location attached to user: " + userRoleIds +  ", cannot delete!");
  //   setMessageType("error");
  //   handleAlertOpen();
  // };

  const handleDeleteLocation = (locationId) => {
    // const userId = users.find(u => u.role_id === locationId)
    // userId ? handleErrorMessage(userId.userName) :
    deleteLocation(locationId)
      .then((result) => {
        setMessage('Location deleted');
        setMessageType('success');
        handleAlertOpen();
        setUpdate(true);
      })
      .catch((err) => {
        setMessage('Failed to delete location');
        setMessageType('error');
        handleAlertOpen();
      });
  };

  const handleClose = () => {
    setUserEditOpen(false);
    setUpdate(true);
  };

  const renderLocation = (location) => {
    return location.location_id ? (
      <Dialog open={userEditOpen} onClose={handleClose}>
        <div>
          <Paper className="admin__edit-user-form">
            <Typography className="header-text" variant="h4">
              Location
            </Typography>
            <table className="admin__create-user-table">
              <tbody className="admin__create-user-table-body">
                <tr className="admin__create-user-table-row">
                  {/* <td className="admin__create-user-table-divider">Location:</td> */}
                  <td className="admin__create-user-table-divider">
                    <TextField
                      label="Location"
                      variant="outlined"
                      fullWidth
                      type="text"
                      name="Location"
                      value={locationName}
                      onChange={(e) => setLocationName(e.target.value)}
                    ></TextField>
                  </td>
                </tr>
                <tr className="admin__create-user-table-row">
                  {/* <td className="admin__create-user-table-divider">Location Description:</td> */}
                  <td className="admin__create-user-table-divider">
                    <TextField
                      label="Location Description"
                      variant="outlined"
                      fullWidth
                      type="text"
                      name="Location description"
                      multiline
                      value={locationDescription}
                      onChange={(e) => setLocationDescription(e.target.value)}
                    ></TextField>
                  </td>
                </tr>
                <tr>
                  <td className="admin__create-user-table-divider">
                    <FormControl>
                      <FormLabel>Enable Sample Reminders?</FormLabel>
                      <Grid
                        className="mnd__tax-switch"
                        component="label"
                        container
                        spacing={1}
                      >
                        <Grid item>No</Grid>
                        <Grid item>
                          <Tooltip arrow={true} title={reminderSwitchToolTip}>
                            <Switch
                              checked={reminderSwitchState}
                              onChange={(e) =>
                                setReminderSwitchState(e.target.checked)
                              }
                              color="primary"
                              size="small"
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </FormControl>
                  </td>
                </tr>
                <tr className="admin__create-user-table-row">
                  <td className="admin__create-user-table-divider">
                    <Typography variant="h6" style={{ marginTop: '20px' }}>
                      Customer Facing Contact Info
                    </Typography>
                  </td>
                </tr>
                <tr className="admin__create-user-table-row">
                  <td className="admin__create-user-table-divider">
                    <TextField
                      label="Location Name"
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="location name"
                      value={customerLocationName}
                      onChange={(e) => setCustomerLocationName(e.target.value)}
                    ></TextField>
                  </td>
                </tr>
                <tr className="admin__create-user-table-row">
                  <td className="admin__create-user-table-divider">
                    <TextField
                      label="Phone Number"
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="location phone number"
                      value={customerLocationPhone}
                      onChange={(e) => setCustomerLocationPhone(e.target.value)}
                    ></TextField>
                  </td>
                </tr>
                <tr className="admin__create-user-table-row">
                  <td className="admin__create-user-table-divider">
                    <TextField
                      label="Email"
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="location email"
                      value={customerLocationEmail}
                      onChange={(e) => setCustomerLocationEmail(e.target.value)}
                    ></TextField>
                  </td>
                </tr>
                {/* <tr className="admin__create-user-table-row">
                  <td className="admin__create-user-table-divider">Price label role prefix:</td>
                  <td className="admin__create-user-table-divider">
                  <Grid className="mnd__tax-switch" component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                      <Grid item>
                        <Tooltip arrow={true}  title={switchToolTip} >
                          <Switch
                            checked={switchState}
                            onChange={handleSwitchChange}
                            color="primary"
                            size="small"
                            />
                            </Tooltip>
                          </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </td>
                </tr> */}
              </tbody>
            </table>
            <div className="admin__form-actions">
              <Button
                onClick={() => handleUpdateLocation(location.location_id)}
                className="admin__update-user"
                variant="contained"
              >
                Update
              </Button>
              <div style={{ marginLeft: 20 }}>
                <Button
                  onClick={() => handleDeleteLocation(location.location_id)}
                  className="admin__delete-user"
                  variant="contained"
                >
                  Delete
                </Button>
              </div>
            </div>
          </Paper>
        </div>
      </Dialog>
    ) : (
      <div>Select Location</div>
    );
  };

  return (
    <div className="admin-edit-user-paper">{renderLocation(location)}</div>
  );
};

export default EditLocation;
