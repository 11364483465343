import React from 'react';

// styles
import '../styles/ContactCard.css';

// MUI
import { Avatar, Typography, Grid, Paper } from '@material-ui/core';
import { Email, Phone } from '@material-ui/icons';

export default function ContactCard(props) {
  let picture;
  let name;
  let email;
  let phone;

  const getStrippedNumber = (number) => {
    const strippedNumber = number?.replace(/\D+/g, '');

    if (!strippedNumber) return '';

    return strippedNumber.length > 9
      ? strippedNumber.substring(strippedNumber.length - 10)
      : strippedNumber;
  };

  if (props.contactCardConfig.image?.show)
    picture = (
      <Avatar
        className="contact-card__avatar"
        src={props.contactCardConfig.image?.imageUrl}
      ></Avatar>
    );
  if (props.contactCardConfig.name?.show)
    name = (
      <Typography paragraph={true} variant="h6">
        <b>{props.contactCardConfig.name?.alternateName}</b>
      </Typography>
    );
  if (props.contactCardConfig.email?.show)
    email = (
      <Typography paragraph={true}>
        <Email className="contact-card__icon" />{' '}
        <a href={'mailTo:' + props.contactCardConfig.email?.alternateEmail}>
          {props.contactCardConfig.email?.alternateEmail}
        </a>
      </Typography>
    );
  if (props.contactCardConfig.phone?.show) {
    const phoneLink =
      'tel:' + getStrippedNumber(props.contactCardConfig.phone?.alternatePhone);
    phone = (
      <Typography paragraph={true}>
        <Phone className="contact-card__icon" />{' '}
        <a href={phoneLink}>{props.contactCardConfig.phone?.alternatePhone}</a>
      </Typography>
    );
  }

  return (
    <Paper>
      <Grid container className="contact-card__table">
        <Grid item lg={12} md={12} sm={12}>
          <Typography variant="h4" className="contact-card__header">
            {props.header}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          {picture}
          <div className="contact-card__contact-info">
            {name}
            {email}
            {phone}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}
