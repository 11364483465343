import React from 'react';

// MUI
import { Modal, Paper, Typography } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import FavoriteHistory from './FavoriteHistory';

export default function ViewFavoritesModal(props) {
  return (
    <Modal
      open={props.isModalOpen}
      onClose={props.handleOnClose}
      aria-labelledby="customer favorites modal"
      aria-describedby="modal for managing customer favorites"
    >
      <Paper
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          minHeight: '60%',
          width: '90%',
          padding: '20px 0px',
        }}
      >
        <div style={{ margin: '0px 15px' }}>
          <CloseRounded
            style={{ float: 'right' }}
            onClick={props.handleOnClose}
          ></CloseRounded>
          <Typography variant="h5">Favorites</Typography>
        </div>
        <div style={{ margin: '0px 15px' }}>
          <FavoriteHistory lead={props.lead} />
        </div>
      </Paper>
    </Modal>
  );
}
