import React, { createContext, useState, useEffect, useContext } from 'react';

// MUI
import { Alert as MUIAlert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';

export const AlertContext = createContext();

export const AlertProvider = (props) => {
  const [open, setOpen] = useState(false);
  const [userEditOpen, setUserEditOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(undefined);

  useEffect(() => {}, []);

  const handleAlertClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleAlertOpen = () => {
    setOpen(true);
  };

  return (
    <AlertContext.Provider
      value={{
        handleAlertOpen,
        handleAlertClose,
        setMessageType,
        setMessage,
        setUserEditOpen,
        userEditOpen,
      }}
    >
      {props.children}
      <Snackbar
        severity={messageType}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <MUIAlert severity={messageType} onClose={handleAlertClose}>
          {message}
        </MUIAlert>
      </Snackbar>
    </AlertContext.Provider>
  );
};

export function useAlert() {
  const context = useContext(AlertContext);
  if (!context)
    throw new Error(
      '`useAlert()` must be called inside an `AlertProvider` child.'
    );

  return context;
}
