import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// MUI
import {
  AppBar,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  List,
  Button,
  Paper,
} from '@material-ui/core';

// Styles
import '../styles/Checkouts.css';

// Icons
import AssignmentReturnSharpIcon from '@material-ui/icons/AssignmentReturnSharp';
import AssignmentReturnedSharpIcon from '@material-ui/icons/AssignmentReturnedSharp';
import AssignmentTurnedInSharpIcon from '@material-ui/icons/AssignmentTurnedInSharp';
import AssignmentLateSharpIcon from '@material-ui/icons/AssignmentLateSharp';
import AssignmentSharpIcon from '@material-ui/icons/AssignmentSharp';
import Collapse from '@material-ui/core/Collapse';
import StyleIcon from '@material-ui/icons/Style';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LinkIcon from '@material-ui/icons/Link';
import DateRangeIcon from '@material-ui/icons/DateRange';
import WarningIcon from '@material-ui/icons/Warning';
import CancelIcon from '@material-ui/icons/Cancel';

// Actions
import { getCheckoutList, cancelSampleCheckout } from '../../Actions';
import { sortObjectsByAttribute } from '../../Util/utilsFunctions';

export default function Checkouts() {
  const [checkedOutProducts, setCheckedOutProducts] = useState([]);
  const [open, setOpen] = useState(undefined);
  const [refresh, setRefresh] = useState(true);
  const [sortStatus, setSortStatus] = useState(false);
  const [sortStyle, setSortStyle] = useState(false);
  const [sortDaysOut, setSortDaysOut] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('style_name');

  useEffect(() => {
    if (!refresh) return;

    getCheckoutList()
      .then((result) => {
        setCheckedOutProducts([...result.data.result]);
      })
      .catch((err) => {
        console.log(err);
      });
    setRefresh(false);
  }, [refresh]);

  const handleSort = (e) => {
    switch (e) {
      case 'Status':
        if (sortStatus === false) {
          setOrder('desc');
          setOrderBy('status_name');
          setSortStatus(true);
          setSortDaysOut(false);
          setSortStyle(false);
        } else {
          setSortStatus(false);
          setOrder('asc');
        }
        break;
      case 'Style Name':
        if (sortStyle === false) {
          setOrder('desc');
          setOrderBy('style_name');
          setSortStatus(false);
          setSortDaysOut(false);
          setSortStyle(true);
        } else {
          setSortStyle(false);
          setOrder('asc');
        }
        break;
      case 'Days Out':
        if (sortDaysOut === false) {
          setOrder('desc');
          setOrderBy('days_checked_out');
          setSortStatus(false);
          setSortDaysOut(true);
          setSortStyle(false);
        } else {
          setSortDaysOut(false);
          setOrder('asc');
        }
        break;
      default:
        setSortStatus(false);
        setSortDaysOut(false);
        setSortStyle(false);
    }
  };

  const handleClick = (index) => {
    open === index ? setOpen(undefined) : setOpen(index);
  };

  const handleCancelSampleCheckout = (checkoutId) => {
    cancelSampleCheckout(checkoutId)
      .then(() => {
        setRefresh(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return checkedOutProducts.length < 1 ? (
    <div>
      <AppBar position="static">
        <Toolbar variant="dense" className="favorites__header">
          <Typography variant="h4" color="inherit" style={{ padding: '15px' }}>
            Sample Checkouts
          </Typography>
        </Toolbar>
      </AppBar>
      <Paper className="link-container">
        {/* <Link to="/signin" className="link"><InputIcon fontSize="large" style={{color:"#4E564E"}}/> */}
        <Typography variant="h6" style={{ padding: '15px' }}>
          You have no samples checked out, scan a product to checkout samples.
        </Typography>
        {/* </Link> */}
      </Paper>
    </div>
  ) : (
    <div>
      <div className="checkouts__container-full">
        <AppBar position="static">
          <Toolbar variant="dense" className="favorites__header">
            <Typography
              variant="h4"
              color="inherit"
              style={{ padding: '15px' }}
            >
              Sample Checkouts
            </Typography>
          </Toolbar>
        </AppBar>
        {/* {renderTabs()} */}
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="h5"
                    className="checkouts__header-typography"
                  >
                    Style Name
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => handleSort('Style Name')}
                  >
                    {sortStyle === true ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h5"
                    className="checkouts__header-typography"
                  >
                    Status
                  </Typography>
                  <Button variant="text" onClick={() => handleSort('Status')}>
                    {sortStatus === true ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h5"
                    className="checkouts__header-typography"
                  >
                    Days Out
                  </Typography>
                  <Button variant="text" onClick={() => handleSort('Days Out')}>
                    {sortDaysOut === true ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h5"
                    className="checkouts__header-typography"
                  >
                    Cancel Checkout?
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {checkedOutProducts
                .sort((prev, curr) =>
                  sortObjectsByAttribute(prev, curr, order, orderBy)
                )
                .map((product, index) => {
                  return (
                    <TableRow key={'desktop_' + product.sample_checkout_id}>
                      <TableCell className="checkout__table-cell">
                        <Link
                          to={`/product/${product.product_uuid}`}
                          className="checkout__links"
                          target="blank"
                        >
                          {product.status_name === 'Checked Out' ? (
                            <AssignmentReturnSharpIcon
                              style={{ color: '#23B246', marginRight: 20 }}
                            />
                          ) : product.status_name === 'Pending' ? (
                            <AssignmentLateSharpIcon
                              style={{ color: '#FF682C', marginRight: 10 }}
                            />
                          ) : product.status_name === 'Returned' ? (
                            <AssignmentReturnedSharpIcon
                              style={{ color: '#FF682C', marginRight: 10 }}
                            />
                          ) : product.status_name === 'Checked In' ? (
                            <AssignmentTurnedInSharpIcon
                              style={{ color: '#23B246', marginRight: 10 }}
                            />
                          ) : product.status_name === 'Past Due' ? (
                            <AssignmentLateSharpIcon
                              style={{ color: '#FF0000', marginRight: 10 }}
                            />
                          ) : (
                            <AssignmentSharpIcon />
                          )}{' '}
                          {product.style_name}
                        </Link>
                      </TableCell>
                      <TableCell>{product.status_name}</TableCell>
                      <TableCell>
                        {product.days_checked_out}
                        {product.days_checked_out > 2 &&
                        product.days_checked_out <= 6 ? (
                          <WarningIcon
                            style={{ paddingLeft: 20, color: '#EED202' }}
                          />
                        ) : product.days_checked_out >= 7 ? (
                          <WarningIcon
                            style={{ paddingLeft: 20, color: '#D0342C' }}
                          />
                        ) : (
                          <></>
                        )}
                      </TableCell>
                      {product.status_name === 'Pending' ? (
                        <TableCell>
                          <Button
                            onClick={() => {
                              handleCancelSampleCheckout(
                                product.sample_checkout_id
                              );
                            }}
                          >
                            <CancelIcon style={{ color: '#D0342C' }} />
                          </Button>
                        </TableCell>
                      ) : (
                        <TableCell></TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Paper>
      </div>
      {/* Mobile site */}
      <div className="checkouts__container-mobile">
        {/* <AppBar position="static">
            <Toolbar variant="dense" className="checkouts__header">
                <Typography
                variant="h6"
                color="inherit"
                >
                Sample Checkouts
                </Typography>
            </Toolbar>
        </AppBar> */}
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              className="checkouts__list-sub-header"
            >
              Sample Checkouts
            </ListSubheader>
          }
        >
          {checkedOutProducts
            .sort((prev, curr) =>
              sortObjectsByAttribute(prev, curr, order, orderBy)
            )
            .map((product, index) => {
              return (
                <List key={'mobile_' + product.sample_checkout_id}>
                  <ListItem
                    button
                    onClick={() => handleClick(index)}
                    className="checkouts__list-menu"
                  >
                    <StyleIcon style={{ marginRight: 20, color: '#FF682C' }} />
                    <ListItemText primary={product.style_name} />
                    {open === index ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  {open === index ? (
                    <Collapse in={true} timeout="auto" unmountOnExit>
                      <List style={{ backgroundColor: '#FFF' }}>
                        <ListItem>
                          <ListItemIcon>
                            <Link
                              to={`/product/${product.product_uuid}`}
                              className="checkout__links"
                              target="blank"
                            >
                              {product.status_name === 'Checked Out' ? (
                                <AssignmentReturnSharpIcon
                                  style={{ color: '#23B246', marginRight: 20 }}
                                />
                              ) : product.status_name === 'Pending' ? (
                                <AssignmentLateSharpIcon
                                  style={{ color: '#FF682C', marginRight: 10 }}
                                />
                              ) : product.status_name === 'Returned' ? (
                                <AssignmentReturnedSharpIcon
                                  style={{ color: '#FF682C', marginRight: 10 }}
                                />
                              ) : product.status_name === 'Checked In' ? (
                                <AssignmentTurnedInSharpIcon
                                  style={{ color: '#23B246', marginRight: 10 }}
                                />
                              ) : product.status_name === 'Past Due' ? (
                                <AssignmentLateSharpIcon
                                  style={{ color: '#FF0000', marginRight: 10 }}
                                />
                              ) : (
                                <AssignmentSharpIcon />
                              )}
                            </Link>
                          </ListItemIcon>
                          {product.style_name}
                          <ListItemText primary={product.status} />
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemIcon>
                            <DateRangeIcon />
                          </ListItemIcon>
                          <ListItemText primary={product.days_checked_out} />
                          <ListItemIcon>
                            {product.days_checked_out > 10 &&
                            product.days_checked_out <= 15 ? (
                              <WarningIcon
                                style={{ paddingLeft: 20, color: '#EED202' }}
                              />
                            ) : product.days_checked_out >= 16 ? (
                              <WarningIcon
                                style={{ paddingLeft: 20, color: '#D0342C' }}
                              />
                            ) : (
                              <></>
                            )}
                          </ListItemIcon>
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemIcon>
                            {product.status_name === 'Checked Out' ? (
                              <AssignmentReturnSharpIcon
                                style={{ color: '#23B246', marginRight: 20 }}
                              />
                            ) : product.status_name === 'Pending' ? (
                              <AssignmentLateSharpIcon
                                style={{ color: '#FF682C', marginRight: 10 }}
                              />
                            ) : product.status_name === 'Returned' ? (
                              <AssignmentReturnedSharpIcon
                                style={{ color: '#FF682C', marginRight: 10 }}
                              />
                            ) : product.status_name === 'Checked In' ? (
                              <AssignmentTurnedInSharpIcon
                                style={{ color: '#23B246', marginRight: 10 }}
                              />
                            ) : product.status_name === 'Past Due' ? (
                              <AssignmentLateSharpIcon
                                style={{ color: '#FF0000', marginRight: 10 }}
                              />
                            ) : (
                              <AssignmentSharpIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={product.status_name} />
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <ListItemIcon>
                            <LinkIcon />
                          </ListItemIcon>
                          <Link
                            to={`/product/${product.product_uuid}`}
                            className="checkout__links"
                            target="blank"
                          >
                            <ListItemText primary={'View product'} />
                          </Link>
                        </ListItem>
                        {product.status_name === 'Pending' ? (
                          <ListItem>
                            <ListItemIcon>
                              <Button
                                onClick={() => {
                                  handleCancelSampleCheckout(
                                    product.sample_checkout_id
                                  );
                                }}
                              >
                                <CancelIcon
                                  style={{ color: '#D0342C', marginRight: 25 }}
                                />
                              </Button>
                            </ListItemIcon>
                            <ListItemText primary={'Cancel Checkout?'} />
                          </ListItem>
                        ) : (
                          <></>
                        )}
                      </List>
                    </Collapse>
                  ) : (
                    <></>
                  )}
                </List>
              );
            })}
        </List>
      </div>
    </div>
  );
}
