import { makeStyles } from '@material-ui/core';

const generalStyles = makeStyles((theme) => ({
  header: {
    background: '#142E3E',
    textAlign: 'center',
    borderRadius: 1,
    color: '#28C4FC',
    padding: '10px',
  },
  headerText: {
    [theme.breakpoints.up('md')]: {
      fontSize: 30,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: 15,
    },
  },
  xButton: {
    color: '#FF682C',
    marginLeft: 20,
    fontSize: 20,
    float: 'right',
    border: '1px solid #28C4FC',
  },
  toggle: {
    color: '#000000',
    '&.Mui-selected': {
      backgroundColor: '#142E3E !important',
      color: '#28C4FC !important',
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#142E3E !important',
      color: '#28C4FC !important',
    },
  },
}));

export { generalStyles };
