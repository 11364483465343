import React, { useState, useEffect, useContext } from 'react';
import { Line } from 'react-chartjs-2';

// MUI
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { download, JsonToCsvConverter } from '../../../Util/utilsFunctions';

import GetAppIcon from '@material-ui/icons/GetApp';

// config
import { RecentlyViewedProductsConfig } from '../../config';
import CustomTable from '../../Custom/CustomTable';

// Actions
import {
  getViewsByDay,
  getRoles,
  getAccessTypes,
  getMaterials,
  getLocations,
} from '../../../Actions';

// dependents
// import { AdminContext } from "../../../Context/AdminContextProvider";
import { HubSpokeContext } from '../../../Context/HubSpokeContextProvider';

const handleDownload = (data) => {
  const csvData = JsonToCsvConverter(data);
  const date = new Date();
  const filename =
    'Views-By-Day_' +
    date.getFullYear() +
    '-' +
    date.getMonth() +
    '-' +
    date.getDate();
  download(filename, csvData);
};

const ViewsByDay = (props) => {
  var tmpViews = [];
  const { recentlyViewedProducts } = props;
  const [recentClicks, setRecentClicks] = useState([]);
  const [days, setDays] = useState([]);
  const [hours, setHours] = useState([]);
  const [viewData, setViewData] = useState();
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
      .toISOString()
      .substring(0, 10)
  );
  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 86400000).toISOString().substring(0, 10)
  );
  const [viewByDay, setViewByDay] = useState(0);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [selectedAccessType, setSelectedAccessType] = useState([]);
  const [accessTypes, setAccessTypes] = useState([]);
  const [materialClass, setMaterialClass] = useState([]);
  const [selectedMaterialClass, setSelectedMaterialClass] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [locations, setLocations] = useState([]);
  const { selectedSpoke } = useContext(HubSpokeContext);

  useEffect(() => {
    getLocations().then((result) => {
      setLocations(result.data.data);
    });

    const roleList = new Promise((resolve) => {
      resolve(getRoles());
    });

    roleList.then((result) => {
      setRoles(result.data.roleList);
    });

    const acList = new Promise((resolve) => {
      resolve(getAccessTypes());
    });

    acList.then((result) => {
      setAccessTypes(result.data.data);
    });

    const materialClassList = new Promise((resolve) => {
      resolve(getMaterials());
    });

    materialClassList.then((result) => {
      setMaterialClass(result.data.data);
    });

    getViewsByDay(
      startDate,
      endDate,
      selectedRole,
      selectedAccessType,
      selectedMaterialClass,
      selectedLocations,
      selectedSpoke
    )
      .then((result) => {
        setRecentClicks(result.data.views ?? []);
        setDays(result.data.views ?? []);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [recentlyViewedProducts]);

  // this gives an object with dates as keys
  const groups = recentClicks.reduce((groups, clicks) => {
    const date = clicks.click_date.split(' ')[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(clicks);
    return groups;
  }, {});

  // to add it in the array format
  const groupArrays = Object.keys(groups).map((date) => {
    return {
      date,
      views: groups[date],
    };
  });
  const lineOptions = {
    onClick: (e, element) => {
      if (element.length > 0) {
        groupArrays.forEach((d) => {
          if (
            d.date === element[0]._chart.tooltip._data.labels[element[0]._index]
          ) {
            setDays(d.views);
            d.views.forEach((day) => {
              hours.push(day.click_date.split(' ')[1].split(':')[0] + ':00');
              const dayCount = {
                '00:00': 0,
                '01:00': 0,
                '02:00': 0,
                '03:00': 0,
                '04:00': 0,
                '05:00': 0,
                '06:00': 0,
                '07:00': 0,
                '08:00': 0,
                '09:00': 0,
                '10:00': 0,
                '11:00': 0,
                '12:00': 0,
                '13:00': 0,
                '14:00': 0,
                '15:00': 0,
                '16:00': 0,
                '17:00': 0,
                '18:00': 0,
                '19:00': 0,
                '20:00': 0,
                '21:00': 0,
                '22:00': 0,
                '23:00': 0,
              };
              hours.forEach(function (x) {
                dayCount[x] = (dayCount[x] || 0) + 1;
              });
              setViewData(dayCount);
              setViewByDay(1);
            });
          }
        });
      }
    },
    scales: {
      xAxes: [
        {
          // type: 'time',
          // time: {
          // unit: 'day',
          // stepSize: 5,
          // tooltipFormat: 'MM/DD',
          // },
          gridLines: {
            display: true,
          },
          ticks: {
            reverse: false,
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          // stacked: true,
          gridLines: {
            display: true,
          },
          ticks: {
            beginAtZero: true,
            // Return an empty string to draw the tick line but hide the tick label
            // Return `null` or `undefined` to hide the tick line entirely
            userCallback(value) {
              // Convert the number to a string and split the string every 3 charaters from the end
              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);

              // Convert the array to a string and format the output
              value = value.join('.');
              return `${value}`;
            },
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
    },
    elements: {
      line: {
        tension: 0.2,
      },
    },
  };

  const handleRoleSelectChange = (event, value) => {
    setSelectedRole(value);
  };

  const handleAcSelectChange = (event, value) => {
    setSelectedAccessType(value);
  };

  const handleMaterialClassChange = (event, value) => {
    setSelectedMaterialClass(value);
  };

  const handleLocationChange = (event, value) => {
    setSelectedLocations(value);
  };

  const handleStartDate = (date) => {
    setStartDate(date.toISOString().substring(0, 10));
  };

  const handleEndDate = (date) => {
    setEndDate(date.toISOString().substring(0, 10));
  };

  const handleSubmit = () => {
    setHours([]);
    setViewByDay(0);
    getViewsByDay(
      startDate,
      endDate,
      selectedRole,
      selectedAccessType,
      selectedMaterialClass,
      selectedLocations,
      selectedSpoke
    )
      .then((result) => {
        setRecentClicks(result.data.views ?? []);
        setDays(result.data.views ?? []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderLineChart = () => {
    if (groupArrays.length) {
      if (Number(viewByDay) === 1) {
        const data = {
          labels: Object.keys(viewData),
          datasets: [
            {
              fill: false,
              pointBackgroundColor: 'rgb(0, 0, 233)',
              borderColor: 'rgb(54, 190, 239)',
              data: Object.values(viewData),
            },
          ],
        };
        return (
          <div style={{ width: '100%', paddingBottom: 400, marginTop: 15 }}>
            <Line data={data} options={lineOptions} height={75} />
          </div>
        );
      } else {
        const data = {
          labels: groupArrays.map((p) => p.date.split(' ')[0]),
          datasets: [
            {
              fill: false,
              pointBackgroundColor: 'rgb(0, 0, 233)',
              borderColor: 'rgb(54, 190, 239)',
              data: groupArrays.map((d) => d.views.length),
            },
          ],
        };
        return (
          <div style={{ width: '100%', paddingBottom: 400, marginTop: 15 }}>
            <Line data={data} options={lineOptions} height={75} />
          </div>
        );
      }
    } else {
      return (
        <div style={{ textAlign: 'center', color: 'red' }}>
          <Typography variant={'h3'}>
            Nothing matches your search criteria
          </Typography>
        </div>
      );
    }
  };

  const renderClickTable = (clicks) => {
    return (
      <div className="stats__rvp-container">
        <Paper elevation={0}>
          {/* <Typography align="center" variant="h5" className="stats__mvp-header">
            Views By Day
          </Typography> */}
          <Divider />
          <Paper elevation={0}>
            <Grid container spacing={3} style={{ padding: '10px' }}>
              <Grid item xs={2}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    variant="inline"
                    format="MM/DD/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Start Date"
                    value={startDate}
                    onChange={handleStartDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputProps={{ readOnly: true }}
                    autoOk={true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={2}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    variant="inline"
                    format="MM/DD/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="End Date"
                    value={endDate}
                    onChange={handleEndDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputProps={{ readOnly: true }}
                    autoOk={true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  style={{ marginTop: 16 }}
                  // className="export-form-item"
                  multiple
                  options={roles}
                  getOptionLabel={(option) => option.role_name}
                  value={selectedRole}
                  onChange={handleRoleSelectChange}
                  renderInput={(params) => (
                    <TextField {...params} label={'Role'} />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  style={{ marginTop: 16 }}
                  // className="export-form-item"
                  multiple
                  options={accessTypes}
                  getOptionLabel={(option) => option.access_type}
                  value={selectedAccessType}
                  onChange={handleAcSelectChange}
                  renderInput={(params) => (
                    <TextField {...params} label={'Access Type'} />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  style={{ marginTop: 16 }}
                  // className="export-form-item"
                  multiple
                  options={materialClass}
                  getOptionLabel={(option) => option.material_class}
                  value={selectedMaterialClass}
                  onChange={handleMaterialClassChange}
                  renderInput={(params) => (
                    <TextField {...params} label={'Material Class'} />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  style={{ marginTop: 16 }}
                  // className="export-form-item"
                  multiple
                  options={locations}
                  getOptionLabel={(option) => option.location_name}
                  value={selectedLocations}
                  onChange={handleLocationChange}
                  renderInput={(params) => (
                    <TextField {...params} label={'Location'} />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  className="stats__search-button"
                  onClick={handleSubmit}
                  variant="contained"
                  autoFocus
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <CustomTable data={clicks} headers={RecentlyViewedProductsConfig} />
        </Paper>
        <Divider />
        <Paper className="stats__mvp-data-download-container">
          <Button
            variant="contained"
            className="stats__mvp-data-download-button"
            onClick={(e) => handleDownload(clicks)}
            endIcon={<GetAppIcon />}
          >
            Download
          </Button>
        </Paper>
      </div>
    );
  };

  return (
    <div className="stats__rvp-container">
      <Paper elevation={0} className="stats__rvp-container-1">
        <Typography align="center" variant="h5" className="stats__mvp-header">
          Product Views By Day
        </Typography>
        <Divider />
        <Paper>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {renderClickTable(days)}
            </Grid>
            <Grid item xs={12}>
              {renderLineChart()}
            </Grid>
          </Grid>
        </Paper>
      </Paper>
      <Divider />
    </div>
  );
};

export default ViewsByDay;
