import React, { useState, useContext, useRef, useEffect } from 'react';

// MUI
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  withStyles,
} from '@material-ui/core/';

// icon
import PrintIcon from '@material-ui/icons/Print';
import CloseIcon from '@material-ui/icons/Close';

// styles
// import "../../styles/Products.css";
import { productsStyles } from '../../styles/ProductsStyles';
import { generalStyles } from '../../styles/GeneralStyles';

// actions
import {
  clearPrintQueue,
  deleteFromPrintQueue,
  returnSample,
  updatePrintQueue,
} from '../../../Actions';

// dependents
import { PrintQueueContext } from '../../../Context/PrintQueueContextProvider';
import { AlertContext } from '../../../Context/AlertContextProvider';
import PrintableQRcodes from './PrintableQRcodes';
import { baseUrl } from '../../../Contants';
import { DialogActions, DialogTitle } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { AppContext } from '../../../Context/AppContextProvide';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PrintProducts = (props) => {
  const { queue, setUpdate, setSwitchState } = useContext(PrintQueueContext);
  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);
  const { user } = useContext(AppContext);

  const [open, setOpen] = useState(props.open);
  const [openDisclaimer, setOpenDisclaimer] = useState(false);
  const [sorted, setSorted] = useState('added');
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [showLocationDropdown, setShowLocationDropdown] = useState(false);
  const CustomColorTypography = withStyles({
    root: {
      color: '#4E564E',
    },
  })(Typography);

  const productsClasses = productsStyles();
  const generalClasses = generalStyles();
  const isAdmin = user.access_type_cd === 'a';

  useEffect(() => {
    const getLocations = async () => {
      const result = await axios.get(`${baseUrl}/api/admin/locations`);

      if (result.status !== 200) {
        setLocations([]);
        setShowLocationDropdown(false);
        return;
      }

      setLocations(result.data.data);
      setShowLocationDropdown(true);
    };

    if (isAdmin) getLocations();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDisclaimerClose = () => {
    setOpenDisclaimer(false);
  };

  const handleOpenDisclaimer = () => {
    setOpenDisclaimer(true);
  };

  const handleUpdatePrintQueue = (printQueueId) => {
    // var uniqueProductIdList = [...new Set(productIdList)];

    updatePrintQueue(printQueueId)
      .then(() => {
        setUpdate(true);
        setMessageType('success');
        setMessage('Print Queue Updated');
        handleAlertOpen();
      })
      .catch((err) => {
        setMessageType('error');
        setMessage('Error Updating Print Queue');
        handleAlertOpen();
      });
  };

  const handleDelete = (printQueueId) => {
    deleteFromPrintQueue(printQueueId)
      .then(() => {
        setMessage('Product Deleted From Queue');
        setMessageType('success');
        handleAlertOpen();
        setUpdate(true);
      })
      .catch(() => {
        setMessage('Error Deleting Product From Queue');
        setMessageType('error');
        handleAlertOpen();
      });
  };

  const handleClearQueue = () => {
    clearPrintQueue()
      .then(() => {
        setMessage('Print queue cleared!');
        setMessageType('success');
        handleAlertOpen();
        setUpdate(true);
        setOpenDisclaimer(false);
        setOpen(false);
      })
      .catch(() => {
        setMessage('Error clearing print queue');
        setMessageType('error');
        handleAlertOpen();
        setOpenDisclaimer(false);
      });
  };

  const handleToggle = (e, value) => {
    if (!value) return;

    setSwitchState(value === 'alphabetical' ? true : false);
    setSorted(value);
    setUpdate(true);
  };

  const renderConfirmation = () => {
    return (
      <Dialog
        open={openDisclaimer}
        onClose={handleDisclaimerClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Are you sure you want to clear the print queue?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleDisclaimerClose}
            variant="contained"
            className={productsClasses.productsClearQueueN}
            autoFocus
          >
            No
          </Button>
          <Button
            onClick={handleClearQueue}
            variant="contained"
            className={productsClasses.productsClearQueueY}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderPrintList = (data) => {
    return data.length ? (
      data.map((item, i) => {
        const {
          manufacturer_name,
          trade_name,
          private_trade_name,
          product_uuid,
          print_queue_id,
        } = item;
        return (
          <div key={`${product_uuid}+${i}`}>
            <ListItem className={productsClasses.productPrintQueueList}>
              {private_trade_name != null && private_trade_name != '' ? (
                <ListItemText
                  primary={private_trade_name}
                  secondary={`${manufacturer_name} - ${private_trade_name}`}
                />
              ) : (
                <ListItemText
                  primary={trade_name}
                  secondary={`${manufacturer_name} - ${trade_name}`}
                />
              )}
              <ListItemIcon>
                <IconButton
                  onClick={(e) => handleUpdatePrintQueue([product_uuid])}
                >
                  <Badge badgeContent="+">
                    <PrintIcon />
                  </Badge>
                </IconButton>
              </ListItemIcon>
              <ListItemIcon>
                <IconButton onClick={(e) => handleDelete(print_queue_id)}>
                  <Badge badgeContent="-">
                    <PrintIcon />
                  </Badge>
                </IconButton>
              </ListItemIcon>
            </ListItem>
            <Divider />
          </div>
        );
      })
    ) : (
      <div>No Products</div>
    );
  };

  // Put this here until headers can be sent for the print file
  const userData = localStorage.getItem('userDetails');
  const RequestorUserID = userData ? JSON.parse(userData).user_id : undefined;

  const locationOptions = locations.map((l) => {
    return (
      <MenuItem
        key={l.location_name}
        aria-label={l.location_name}
        value={l.location_id}
      >
        <Typography>{l.location_name}</Typography>
      </MenuItem>
    );
  });

  return (
    <div>
      {renderConfirmation()}
      <IconButton onClick={handleOpen}>
        <Badge
          style={{ width: '35px', color: '#ff682c' }}
          max={999}
          badgeContent={queue.length ? queue.length : 0}
        >
          <PrintIcon style={{ color: '4E564E' }} />
        </Badge>
      </IconButton>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={productsClasses.productsPrintQueue}
      >
        <Typography variant="h4" className={generalClasses.header}>
          Print Queue
          <IconButton
            onClick={handleClose}
            aria-label="close"
            style={{
              float: 'right',
              color: '#28C4FC',
              padding: '8px 0px',
              margin: '0px 10px 0px -20px',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
        <div className={productsClasses.productsPrintQueueContent}>
          <div
          // className="print_queue__product-queue"
          >
            <div style={{ width: '80%', margin: 'auto' }}>
              <Grid container style={{ marginBottom: 30, width: '100%' }}>
                <Grid
                  item
                  xs={showLocationDropdown ? 8 : 12}
                  style={
                    showLocationDropdown
                      ? { textAlign: 'left' }
                      : { textAlign: 'center' }
                  }
                >
                  <CustomColorTypography
                    variant={showLocationDropdown ? 'h6' : 'h4'}
                    className={productsClasses.productsPrintFooter}
                    style={{ textAlign: 'inherit' }}
                  >
                    Order QR Codes Will Be Printed
                  </CustomColorTypography>
                  <ToggleButtonGroup
                    value={sorted}
                    exclusive="true"
                    onChange={handleToggle}
                    style={{ backgroundColor: '#FFFFFF' }}
                  >
                    <ToggleButton
                      className={generalClasses.toggle}
                      value="added"
                      aria-label="order added to queue"
                    >
                      Order Added to Queue
                    </ToggleButton>
                    <ToggleButton
                      className={generalClasses.toggle}
                      value="alphabetical"
                      aria-label="alphabetical order"
                    >
                      Alphabetical Order
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                {showLocationDropdown ? (
                  <Grid item xs={4} style={{ textAlign: 'left' }}>
                    <CustomColorTypography
                      variant={'h6'}
                      className={productsClasses.productsPrintFooter}
                      style={{ textAlign: 'inherit' }}
                    >
                      Location
                    </CustomColorTypography>
                    <FormControl
                      variant="filled"
                      style={{ width: '100%', minWidth: '190px' }}
                    >
                      <InputLabel id="location-list-label">
                        Location List
                      </InputLabel>
                      <Select
                        labelId="location-list-label"
                        id="location-list"
                        value={selectedLocation}
                        onChange={(e) => setSelectedLocation(e.target.value)}
                        label="Location List"
                      >
                        <MenuItem aria-label="None" value="">
                          <em>Please Select One Of The Following Locations</em>
                        </MenuItem>
                        {locationOptions}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : undefined}
              </Grid>
              <div className={productsClasses.productPrintQueueList}>
                <List>{renderPrintList(queue)}</List>
              </div>
              <Grid container style={{ marginTop: 10 }}>
                <Grid item xs={12}>
                  <div style={{ textAlign: 'right', margin: '20px 0px' }}>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      style={{ marginLeft: '15px', backgroundColor: '#FFFFFF' }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleOpenDisclaimer}
                      disabled={!queue.length}
                      variant="contained"
                      style={{
                        marginLeft: '15px',
                        color: '#FF0000',
                        backgroundColor: '#FFFFFF',
                      }}
                    >
                      Clear Queue
                    </Button>
                    <Button
                      href={`${baseUrl}/api/user/queue/print?u=${RequestorUserID}&sort=${sorted === 'alphabetical' ? '' : sorted}&loc=${selectedLocation}`}
                      target="_blank"
                      // onClick={handlePrintPreview}
                      autoFocus
                      variant="contained"
                      style={{
                        marginLeft: '15px',
                        color: '#228B22',
                        backgroundColor: '#FFFFFF',
                      }}
                    >
                      Print
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          {/* <div className="print_queue__qr-code-list">
            <PrintableQRcodes queue={queue} ref={componentRef} />
          </div> */}
        </div>
      </Dialog>
    </div>
  );
};

export default PrintProducts;
