import React, { useContext, useEffect, useState } from 'react';
import { AdminTaxContext } from '../../../Context/AdminTaxContextProvider';
import { InfoButtonContextProvider } from '../../Shared/InfoButton';

// Styles
import '../../styles/MND.css';

//Context
import { AlertContext } from '../../../Context/AlertContextProvider';

//Actions
import { updateTaxRate } from '../../../Actions';

// MUI
// import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
// import InfoIcon from "@material-ui/icons/Info";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';

// const infoButtonLabel = "How does this apply?"
// const infoButtonHeader = "Tax Rate"
const detailsButtonHeader = 'Modification Details';
const infoButtonMessage = [
  'The tax rate value will be applied to cost of goods from manufacturer/distributor before any margins are applied.',
  'If you do not wish to incorporate taxes make this value 0 (zero).',
];

const TaxUpdate = () => {
  const { tax, setUpdate } = useContext(AdminTaxContext);
  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);
  const [taxRate, setTaxRate] = useState(0);

  useEffect(() => {
    if (tax.length) {
      setTaxRate(tax[0].tax_rate);
    }
  }, [tax]);

  const handleUpdateTaxRate = (e) => {
    setTaxRate(e.target.value);
  };

  const handleUpdate = () => {
    updateTaxRate(taxRate)
      .then(() => {
        setMessage('Successfully update tax rate');
        setMessageType('success');
        handleAlertOpen();
        setUpdate(true);
      })
      .catch(() => {
        setMessage('Error updating tax rate');
        setMessageType('error');
        handleAlertOpen();
      });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   handleUpdate()
  // }

  const renderChangeInfo = (data) => {
    if (data.length) {
      const taxData = data[0];
      return [
        `LAST UPDATED BY: \xa0\xa0\xa0\xa0\xa0  ${taxData.last_updated_by_name}`,
        `LAST MODIFIED USER: \xa0\xa0\xa0\xa0\xa0 ${taxData.last_updated_by_user_name}`,
        `LAST MODIFIED: \xa0\xa0\xa0\xa0\xa0 ${taxData.last_modified_dttm}`,
      ];
    }
  };

  const renderInternalTax = (data) => {
    if (data.length) {
      return (
        <div>
          <div className="mnd__header">
            <Typography className="mnd__header-text" variant="h4">
              Internal Tax
            </Typography>
          </div>
          <Grid container spacing={3} style={{ marginTop: 30 }}>
            <Grid item xs={7} lg={8} style={{ paddingLeft: 40 }}>
              <TextField
                className="mnd__textfield"
                variant="filled"
                name="internaltax"
                type="number"
                label={
                  <Typography variant="headline" component="small">
                    Internal sales tax
                  </Typography>
                }
                placeholder="Default Rate"
                value={taxRate}
                onChange={handleUpdateTaxRate}
                size="small"
              />
              <InfoButtonContextProvider
                buttonLabel="Modification Details"
                heading={detailsButtonHeader}
                messages={renderChangeInfo(tax)}
              />
            </Grid>
            <Grid
              item
              xs={5}
              lg={4}
              style={{ display: 'flex', alignContent: 'Bottom' }}
            >
              <Button
                style={{ height: 45, color: '#FF682C', marginRight: 10 }}
                // className="admin__tax-update-btn"
                variant="contained"
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Grid>
            {/* <Grid item xs={12} style={{marginTop:50}}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="h5">Internal Tax (%)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {infoButtonMessage}
                </Typography>
              </AccordionDetails>
            </Accordion>
            </Grid> */}
          </Grid>
        </div>
      );
    } else {
      <div>Loading...</div>;
    }
  };

  // const renderTaxDetails = (data) => {
  //   // return data.length ? <div> Tax Data </div> : <div>Loading...</div>;
  //   if (data.length) {
  //     return (
  //       <div>
  //         <div className="mnd__header">
  //         <Typography
  //           className="header-text"
  //           variant="h4">
  //           Internal Tax Adjustment
  //         </Typography>
  //       </div>
  //       <form onSubmit={handleSubmit}>
  //       <TableContainer className="cost-adjustment-paper">
  //     <Table>
  //       <TableHead>
  //       </TableHead>
  //       <TableBody>
  //         <TableRow className="mnd__table-row">
  //           {/* <TableCell alig="center">
  //           Tax Rate (Percentage)</TableCell> */}
  //           <TableCell className="mnd__table-cell" align="center" width="170px">
  //             <InfoButtonContextProvider
  //             buttonLabel="Internal Tax Adjustment (%)"
  //             heading = {infoButtonHeader}
  //             messages={infoButtonMessage}/>
  //           </TableCell>
  //           {/* <TableCell className="mnd__table-cell" align="center" width="170px">
  //             <InfoButtonContextProvider
  //             buttonLabel="Modification Details"
  //             heading = {detailsButtonHeader}
  //             messages={renderChangeInfo(tax)}/>
  //           </TableCell> */}
  //           <TableCell className="mnd__table-cell" align="center" width="170px">
  //           <TextField
  //             variant="filled"
  //             name="taxrate"
  //             type="number"
  //             label="Internal Tax Adjustment (%)"
  //             placeholder="Internal Tax Adjustment"
  //             value={taxRate}
  //             onChange={handleUpdateTaxRate}
  //             size="small"
  //           />
  //           <InfoButtonContextProvider
  //             buttonLabel="Modification Details"
  //             heading = {detailsButtonHeader}
  //             messages={renderChangeInfo(tax)}/>
  //           </TableCell>
  //           <TableCell className="mnd__table-cell" align="center" width="170px">
  //           <Button
  //           className="admin__tax-update-btn"
  //           variant="contained"
  //           onClick={handleUpdate}
  //           >
  //             Update
  //           </Button>
  //           </TableCell>
  //         </TableRow>
  //       </TableBody>
  //     </Table>
  //   </TableContainer>
  //   </form>
  //   </div>
  //     );
  //   } else {
  //     return <div>Loading...</div>;
  //   }
  // };

  return (
    <div>
      {/* <Paper className="admin__tax-header">
        
        <Typography
          className="admin__tax-header-text"
          variant="h6"
          align="center"
        >
          <InfoButtonContextProvider heading = "Tax Rate:" message={"Tax Rate: This value will be applied to cost of goods from manufacturer/distributor before any margins are applied." + <p /> + "If you do not wish to incorporate taxes leave this value as 0 (zero)."}/>
          
        </Typography>
        <Typography
          className="admin__tax-header-text"
          variant="h6"
          align="center"
        >
          If you do not wish to incorporate taxes leave this value as 0 (zero).
        </Typography>
      </Paper> */}
      {/* <Card>
            <CardContent> */}

      {renderInternalTax(tax)}
      <Accordion style={{ marginTop: 75 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Internal Tax (%)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{infoButtonMessage}</Typography>
        </AccordionDetails>
      </Accordion>
      {/* </CardContent>
      </Card> */}
    </div>
  );
};

export { TaxUpdate };
export default TaxUpdate;
