import axios from 'axios';

const axiosConfig = axios.interceptors.request.use(
  (config) => {
    const userData = localStorage.getItem('userDetails');
    const RequestorUserID = userData ? JSON.parse(userData).user_id : undefined;
    const accessToken = localStorage.getItem('accessToken');
    const authType = localStorage.getItem('authType');
    config.headers.RequestorUserID = RequestorUserID;
    config.headers.Authorization = `Bearer ${accessToken}`;
    config.headers.AuthType = authType;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosConfig;
