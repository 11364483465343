import React, { createContext, useState, useEffect, useContext } from 'react';
// MUI
import { CircularProgress, Backdrop, makeStyles } from '@material-ui/core';

export const ProgressContext = createContext();

export const ProgressProvider = (props) => {
  const [open, setOpen] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      color: '#FF682C',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    backdrop: {
      zIndex: 2,
    },
  }));
  const classes = useStyles();

  const handleProgressClose = () => {
    setOpen(false);
  };

  const handleProgressOpen = () => {
    setOpen(true);
  };

  return (
    <ProgressContext.Provider
      value={{ handleProgressOpen, handleProgressClose }}
    >
      {props.children}
      <Backdrop open={open} className={classes.backdrop}>
        <CircularProgress size={70} className={classes.root} />
      </Backdrop>
    </ProgressContext.Provider>
  );
};
