import React, { useContext, useState, useEffect } from 'react';

// styles
import '../../styles/Admin.css';

// Actions
import {
  updateRole,
  deleteRole,
  editRolePrefix,
  setSampleAutoCheckoutStatus,
} from '../../../Actions';

// MUI
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

// dependents
import { AdminContext } from '../../../Context/AdminContextProvider';
import { AlertContext } from '../../../Context/AlertContextProvider';
import { AppContext } from '../../../Context/AppContextProvide';

const EditRole = (props) => {
  const [users] = useState(props.user);
  const [roleName, setRoleName] = useState(props.role.role_name);
  const [roleDescription, setRoleDescription] = useState(
    props.role.role_description
  );
  const [role, setRole] = useState(props.role);
  const { setUpdate } = useContext(AdminContext);
  const {
    handleAlertOpen,
    setMessageType,
    setMessage,
    setUserEditOpen,
    userEditOpen,
  } = useContext(AlertContext);
  const { setRolePrefix } = useContext(AppContext);
  const [switchState, setSwitchState] = useState(!!props.role.prefix);
  const [checkoutSwitchState, setCheckoutSwitchState] = useState(
    !!props.role.auto_approve_sample_checkout
  );
  const [reminderSwitchState, setReminderSwitchState] = useState(
    !!props.role.sample_reminder
  );
  const switchToolTip =
    'Toggle to show role prefix before price tag on the product page. (E.g "' +
    props.role.role_name +
    ' Price")';
  const checkoutSwitchToolTip =
    'Allow this role to checkout samples without requesting permission';
  const reminderSwitchToolTip = `Allow this role to receive reminders about samples they have checked out`;

  useEffect(() => {
    setSwitchState(!!props.role.prefix);
    setCheckoutSwitchState(!!props.role.auto_approve_sample_checkout);
    setReminderSwitchState(!!props.role.sample_reminder);
    setRole(props.role);
    setRoleName(props.role.role_name);
    setRoleDescription(props.role.role_description);
  }, [props.role]);

  const handleUpdateRole = (roleId) => {
    if (switchState === true) {
      setRolePrefix('1');
      editRolePrefix(props.role.role_id, '1');
    } else if (switchState === false) {
      setRolePrefix('0');
      editRolePrefix(props.role.role_id, '0');
    }

    if (checkoutSwitchState === true) {
      setSampleAutoCheckoutStatus(props.role.role_id, '1');
    } else if (checkoutSwitchState === false) {
      setSampleAutoCheckoutStatus(props.role.role_id, '0');
    }

    updateRole(
      roleId,
      roleName,
      roleDescription,
      switchState,
      checkoutSwitchState,
      reminderSwitchState
    )
      .then((result) => {
        setMessage('Updated role');
        setMessageType('success');
        handleAlertOpen();
        setUpdate(true);
      })
      .catch((err) => {
        setMessage('Failed to update role');
        setMessageType('error');
        handleAlertOpen();
      });
  };

  const handleErrorMessage = (userRoleIds) => {
    setMessage('Role attached to user: ' + userRoleIds + ', cannot delete!');
    setMessageType('error');
    handleAlertOpen();
  };

  const handleDeleteRole = (roleId) => {
    const userId = users.find((u) => u.role_id === roleId);
    userId
      ? handleErrorMessage(userId.userName)
      : deleteRole(roleId)
          .then((result) => {
            setMessage('Role deleted');
            setMessageType('success');
            handleAlertOpen();
            setUpdate(true);
          })
          .catch((err) => {
            setMessage('Failed to delete role');
            setMessageType('error');
            handleAlertOpen();
          });
  };

  const handleClose = () => {
    setUserEditOpen(false);
    setUpdate(true);
  };

  const handleSwitchChange = (event) => {
    setSwitchState(event.target.checked);
    // if (Number(event.target.checked) === 1) {
    //   editRolePrefix(props.role.role_id, '1')
    // };
    // if (Number(event.target.checked) === 0) {
    //   editRolePrefix(props.role.role_id, '0')
    // }
  };

  const handleCheckoutSwitchChange = (event) => {
    setCheckoutSwitchState(event.target.checked);
  };

  const renderRole = (role) => {
    return role.role_id ? (
      <Dialog open={userEditOpen} onClose={handleClose}>
        <div>
          <Paper className="admin__edit-user-form">
            <Typography className="header-text" variant="h4">
              Role
            </Typography>
            <table className="admin__create-user-table">
              <tbody className="admin__create-user-table-body">
                <tr className="admin__create-user-table-row">
                  <td className="admin__create-user-table-divider">
                    <TextField
                      label="Role Name"
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="role"
                      value={roleName}
                      onChange={(e) => setRoleName(e.target.value)}
                    ></TextField>
                  </td>
                </tr>
                <tr className="admin__create-user-table-row">
                  <td className="admin__create-user-table-divider">
                    <TextField
                      fullWidth
                      label="Role Description"
                      variant="outlined"
                      type="text"
                      name="role description"
                      multiline
                      value={roleDescription}
                      onChange={(e) => setRoleDescription(e.target.value)}
                    ></TextField>
                  </td>
                </tr>
                <tr className="admin__create-user-table-row">
                  <td className="admin__create-user-table-divider">
                    <FormControl>
                      <FormLabel>Enable Price Label Role Prefix?</FormLabel>
                      <Grid
                        className="mnd__tax-switch"
                        component="label"
                        container
                        spacing={1}
                      >
                        <Grid item>No</Grid>
                        <Grid item>
                          <Tooltip arrow={true} title={switchToolTip}>
                            <Switch
                              checked={switchState}
                              onChange={(e) => setSwitchState(e.target.checked)}
                              color="primary"
                              size="small"
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  <td className="admin__create-user-table-divider">
                    <FormControl>
                      <FormLabel>Enable Auto Sample Checkouts?</FormLabel>
                      <Grid
                        className="mnd__tax-switch"
                        component="label"
                        container
                        spacing={1}
                      >
                        <Grid item>No</Grid>
                        <Grid item>
                          <Tooltip arrow={true} title={checkoutSwitchToolTip}>
                            <Switch
                              checked={checkoutSwitchState}
                              onChange={(e) =>
                                setCheckoutSwitchState(e.target.checked)
                              }
                              color="primary"
                              size="small"
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  <td className="admin__create-user-table-divider">
                    <FormControl>
                      <FormLabel>Enable Sample Reminders?</FormLabel>
                      <Grid
                        className="mnd__tax-switch"
                        component="label"
                        container
                        spacing={1}
                      >
                        <Grid item>No</Grid>
                        <Grid item>
                          <Tooltip arrow={true} title={reminderSwitchToolTip}>
                            <Switch
                              checked={reminderSwitchState}
                              onChange={(e) =>
                                setReminderSwitchState(e.target.checked)
                              }
                              color="primary"
                              size="small"
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </FormControl>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="admin__form-actions">
              <Button onClick={handleClose} variant="contained">
                Cancel
              </Button>
              <Button
                onClick={() => handleUpdateRole(role.role_id)}
                className="admin__update-user"
                style={{ marginLeft: 20 }}
                variant="contained"
              >
                Update
              </Button>
              <Button
                onClick={() => handleDeleteRole(role.role_id)}
                className="admin__delete-user"
                style={{ marginLeft: 20 }}
                variant="contained"
              >
                Delete
              </Button>
            </div>
          </Paper>
        </div>
      </Dialog>
    ) : (
      <div>Select Role</div>
    );
  };

  return <div className="admin-edit-user-paper">{renderRole(role)}</div>;
};

export default EditRole;
