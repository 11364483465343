import React, { useContext, useEffect, useState, useRef } from 'react';

//Actions
import { confirmUserReg } from '../../Actions';

// MUI
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

// Context
import { AlertContext } from '../../Context/AlertContextProvider';

const ConfirmUser = () => {
  const resetToken = window.location.href.split('/')[4];
  const [dialogOpen, setDialogOpen] = useState(true);
  const [dialogMessage, setDialogMessage] = useState(
    'Your account has been activated'
  );
  const [dialogHeader, setDialogHeader] = useState('Success!');
  // const { setDialogOpen, setDialogHeader, setDialogMessage} = useContext(AlertContext);

  useEffect(() => {
    getResponse();
  }, [dialogOpen, dialogHeader, dialogMessage]);

  const handleDialogClose = () => {
    setDialogOpen(false);
    window.location.href = '/signin';
  };

  const getResponse = () => {
    confirmUserReg(resetToken)
      .then((result) => {
        switch (result.data.message) {
          case 'Incorrect/expired token':
            setDialogHeader('Registration Error');
            setDialogMessage('Registration Error, please contact support');
            setDialogOpen(true);
            break;
          case 'Account already registered':
            setDialogHeader('Registration Error');
            setDialogMessage('Account already registered');
            setDialogOpen(true);
            break;
          case 'Your account has been verified, thank you!':
            setDialogHeader('Success!');
            setDialogMessage('Your account has been activated');
            break;
          default:
            setDialogHeader('Success!');
            setDialogMessage('Your account has been activated');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const alert = () => {
    return (
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        Width={true}
      >
        <DialogTitle style={{ backgroundColor: '#1a2d3d', color: '#28C4FC' }}>
          {dialogHeader}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleDialogClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return <div>{alert()}</div>;
};

export default ConfirmUser;
