import React, { useContext, useEffect, useState } from 'react';
import { CompanyStatsContext } from '../../../Context/CompaniesStatsContextProvider';
import { ProductStatsContext } from '../../../Context/ProductsStatsContextProvider';
import { HubSpokeContext } from '../../../Context/HubSpokeContextProvider';

// dependends
import { ProductsViewedContext } from '../../../Context/ProductsViewedContextProvider';
import { AppContext } from '../../../Context/AppContextProvide';
import MostViewedManufacturers from './MostViewedManufacturers';
import MostViewedProducts from './MostViewedProducts';
import FavoriteHistory from './FavoriteHistory';
import CheckoutsHistory from './CheckoutHistory';
// import RecentlyViewedProducts from "./RecentlyViewedProducts";
import ViewsByDay from './ViewsByDay';
import StatCards from './StatCards';

// MUI
import {
  Tab,
  Tabs,
  Paper,
  makeStyles,
  Box,
  NativeSelect,
  Typography,
} from '@material-ui/core';

const Stats = () => {
  const { recentlyViewedProducts } = useContext(ProductsViewedContext);
  const { productStats, productLikes, productCheckouts } =
    useContext(ProductStatsContext);
  const { companyStats } = useContext(CompanyStatsContext);
  const { spokeList, setSelectedSpoke } = useContext(HubSpokeContext);
  // const [mostRecentViewedProduct, setMostRecentViewProduct] = useState([]);
  // const [mostViewedProduct, setMostViewedProduct] = useState([]);
  // const [mostViewedManufacturer, setMostViewedManufacturer] = useState([]);
  // const [mostLikedProduct, setMostLikedProduct] = useState([]);
  // const [mostCheckedoutProduct, setMostCheckedoutProduct] = useState([]);
  const [value, setValue] = useState('statcards');

  const useStyles = makeStyles((theme) => ({
    header: {
      background: '#142E3E',
      textAlign: 'center',
      borderRadius: 0,
      color: '#28C4FC',
      padding: 0,
      marginBottom: 50,
    },
    headerText: {
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        fontSize: 30,
      },
      [theme.breakpoints.only('sm')]: {
        fontSize: 20,
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: 15,
      },
    },
    headerColor: {
      color: '#28C4FC',
      textAlign: 'center',
      marginLeft: '10em',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },
    headerPaper: {
      marginTop: 20,
      background: '#142E3E',
      textAlign: 'right',
    },
    tabs: {
      [theme.breakpoints.up('lg')]: {
        fontSize: 30,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 10,
      },
      [theme.breakpoints.only('sm')]: {
        fontSize: 10,
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: 10,
      },
    },
    spokeSelect: {
      background: '#DCDCDC',
      padding: 3,
      marginTop: 10,
      marginRight: 10,
      borderRadius: 2,
      float: 'right',
    },
  }));
  const classes = useStyles();

  // function a11yProps(index) {
  //   return {
  //     id: `wrapped-tab-${index}`,
  //     "aria-controls": `wrapped-tabpanel-${index}`,
  //   };
  // }

  // useEffect(() => {
  //   if (recentlyViewedProducts.length)
  //     setMostRecentViewProduct(recentlyViewedProducts[0]);
  //   if (productStats.length) setMostViewedProduct(productStats[0]);
  //   if (companyStats.length) setMostViewedManufacturer(companyStats[0]);
  //   if (productLikes.length) setMostLikedProduct(productLikes[0]);
  //   setMostCheckedoutProduct(productCheckouts[0]);
  // }, [recentlyViewedProducts, productStats, companyStats]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSpokeChange = (event) => {
    setSelectedSpoke(event.target.value);
  };

  const renderTabs = () => {
    return (
      <Paper className={classes.headerPaper}>
        <div>
          {spokeList.length > 0 ? (
            <div>
              <NativeSelect
                className={classes.spokeSelect}
                onChange={handleSpokeChange}
                defaultValue={'Select Remote Site Data'}
                variant="filled"
              >
                <option disabled>Select Remote Site Data</option>
                <option value={''}>None</option>
                {spokeList.map((site) => {
                  return (
                    <option key={site} value={site}>
                      {site}
                    </option>
                  );
                })}
              </NativeSelect>
              <Typography variant="h5" className={classes.headerColor}>
                Company Statistics
              </Typography>{' '}
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* Full screen menu */}
        <Tabs
          TabIndicatorProps={{
            style: { backgroundColor: '#FF682C', color: '#FF682C' },
          }}
          className={classes.header}
          value={value}
          onChange={handleChange}
          centered
        >
          <Tab value="statcards" label="Stat Cards" className={classes.tabs} />
          <Tab
            value="mvp"
            label="Most Viewed Products"
            className={classes.tabs}
          />
          <Tab
            value="mvm"
            label="Most Viewed Manufacturers"
            className={classes.tabs}
          />
          <Tab value="vbd" label="Views By Day" className={classes.tabs} />
          <Tab value="fav" label="Favorites" className={classes.tabs} />
          <Tab value="checkouts" label="Checkouts" className={classes.tabs} />
        </Tabs>
      </Paper>
    );
  };

  // function TabPanel(props) {
  //   const { children, value, index, ...other } = props;

  //   return (
  //       <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`wrapped-tabpanel-${index}`}
  //       aria-labelledby={`wrapped-tab-${index}`}
  //       {...other}
  //       >
  //       {value === index && (
  //           <Box p={0}>
  //           <div>{children}</div>
  //           </Box>
  //       )}
  //       </div>
  //   );
  //   }

  return (
    <div className="home__stats-container">
      {renderTabs()}
      {/* {window.scrollTo(0, 0)} */}
      {value === 'statcards' ? (
        <StatCards
          mostRecentViewedProduct={recentlyViewedProducts[0]}
          mostViewedProduct={productStats[0]}
          mostViewedManufacturer={companyStats[0]}
          mostLikedProduct={productLikes[0]}
          mostCheckedoutProduct={productCheckouts[0]}
        />
      ) : (
        <></>
      )}
      {value === 'mvp' ? (
        <MostViewedProducts mostViewedProducts={productStats} />
      ) : (
        <></>
      )}
      {value === 'mvm' ? (
        <MostViewedManufacturers
          mostViewedManufacturers={companyStats}
          spokeList={spokeList}
        />
      ) : (
        <></>
      )}
      {value === 'vbd' ? (
        <ViewsByDay recentlyViewedProducts={recentlyViewedProducts} />
      ) : (
        <></>
      )}
      {value === 'fav' ? <FavoriteHistory /> : <></>}
      {value === 'checkouts' ? <CheckoutsHistory /> : <></>}
    </div>
  );

  // return (
  //   <div className="home__stats-container">
  //     {renderTabs()}
  //     {window.scrollTo(0, 0)}
  //     <TabPanel value={value} index="statcards">
  //       <StatCards
  //         mostRecentViewedProduct={mostRecentViewedProduct}
  //         mostViewedProduct={mostViewedProduct}
  //         mostViewedManufacturer={mostViewedManufacturer}
  //         mostLikedProduct = {mostLikedProduct}
  //         mostCheckedoutProduct = {mostCheckedoutProduct}
  //       />
  //     </TabPanel>
  //     <TabPanel value={value} index="mvp">
  //       <MostViewedProducts mostViewedProducts={productStats} />
  //     </TabPanel>
  //     <TabPanel value={value} index="mvm">
  //       <MostViewedManufacturers mostViewedManufacturers={companyStats} spokeList={spokeList}/>
  //     </TabPanel>
  //     <TabPanel value={value} index="vbd">
  //       <ViewsByDay recentlyViewedProducts={recentlyViewedProducts} />
  //     </TabPanel>
  //     <TabPanel value={value} index="fav">
  //       <FavoriteHistory />
  //     </TabPanel>
  //     <TabPanel value={value} index="checkouts">
  //       <CheckoutsHistory />
  //     </TabPanel>
  //   </div>
  // );
};

export default Stats;
