import { Button, Grid, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import jsQR from 'jsqr';
import React, { useEffect, useRef, useState } from 'react';

export default function Scanner(props) {
  const [isCameraAllowed, setIsCameraAllowed] = useState(-1);
  const video = useRef();
  const interval = useRef(null);
  const canvas = useRef();

  const getImageDataFromCanvas = () => {
    if (
      isCameraAllowed !== 1 ||
      canvas.current.width === 0 ||
      canvas.current.height === 0
    )
      return;

    const context = canvas.current.getContext('2d', {
      willReadFrequently: true,
    });

    if (!context) return;

    const sx = (video.current.videoWidth - canvas.current.width) / 2;
    const sy = (video.current.videoHeight - canvas.current.height) / 2;
    context.drawImage(
      video.current,
      sx,
      sy,
      canvas.current.width,
      canvas.current.height,
      0,
      0,
      canvas.current.width,
      canvas.current.height
    );
    const data = context.getImageData(
      0,
      0,
      canvas.current.width,
      canvas.current.height
    );
    const code = jsQR(data.data, data.width, data.height, {
      inversionAttempts: 'dontInvert',
    });

    if (code?.data) {
      stopStreamAndClose();
      window.location.href = code?.data;
    }
  };

  const stopStream = () => {
    clearInterval(interval?.current);

    if (!video.current.srcObject) return;

    video.current.srcObject.getTracks().forEach((t) => {
      t.stop();
    });

    video.current.pause();
    video.current.srcObject = null;
    props.handleOnClose();
  };

  const stopStreamAndClose = () => {
    clearInterval(interval?.current);

    if (!video.current.srcObject) {
      props.handleOnClose();
      return;
    }

    video.current.srcObject.getTracks().forEach((t) => {
      t.stop();
    });

    video.current.pause();
    video.current.srcObject = null;
    props.handleOnClose();
  };

  const startStream = () => {
    if (
      !('mediaDevices' in navigator) &&
      !navigator.mediaDevices.getUserMedia
    ) {
      setIsCameraAllowed(0);
      return;
    }

    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const videoDevices = devices.filter((device) =>
          device.kind.includes('video')
        );
        const backFacingDevices = videoDevices.filter((device) =>
          device.label.toLowerCase().includes('back')
        );

        return backFacingDevices.length > 0
          ? backFacingDevices[backFacingDevices.length - 1]
          : undefined;
      })
      .then((videoDevice) => {
        const constraints = {
          video: {
            facingMode: 'environment',
            deviceId: videoDevice?.deviceId,
          },
          audio: false,
        };

        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          video.current.srcObject = stream;
        });
      })
      .catch((err) => {
        setIsCameraAllowed(0);
      });
  };

  useEffect(() => {
    if (!props.isOpen) stopStream();

    if (props.isOpen && isCameraAllowed !== 0 && !video.current.srcObject)
      startStream();
  }, [props.isOpen]);

  useEffect(() => {
    if (props.isOpen && isCameraAllowed === 0) return;

    interval.current = setInterval(() => {
      getImageDataFromCanvas();
    }, 1000);

    return () => {
      clearInterval(interval?.current);
    };
  }, [isCameraAllowed]);

  const handleOnVideoLoad = () => {
    const length = Math.floor(video.current.videoWidth * 0.45);
    canvas.current.width = canvas.current.height = length;
    setIsCameraAllowed(1);
  };

  const handleVideoFeedStopped = () => {
    setIsCameraAllowed(0);
  };

  useEffect(() => {
    video.current.srcObject?.getTracks().forEach((t) => {
      t.addEventListener('ended', handleVideoFeedStopped);
    });

    return () => {
      video.current.srcObject?.getTracks().forEach((t) => {
        t.removeEventListener('ended', handleVideoFeedStopped);
      });
    };
  });

  const showVideoFeed = props.isOpen && isCameraAllowed === 1;

  const cameraAwaiting =
    "To use the QR scanner, you will need to allow access to your device's camera. If you have not already allowed this access, you will be prompted each time you open the scanner.";
  const cameraDenied =
    "To use the QR scanner, you will need to allow access to your device's camera. If you have denied this access previously and want to use this function, you will need to change your browser settings to allow camera access and reload the page.";

  return (
    <div>
      <Grid
        container
        style={{
          width: '100%',
          color: '#28C4FC',
          backgroundColor: '#142E3E',
          borderRadius: '5px 5px 0px 0px',
        }}
      >
        <Grid item xs={8} sm={8} md={8} lg={9}>
          <Typography variant="h5" style={{ margin: '15px' }}>
            QR Scanner
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={3} style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            style={{ margin: '12px 15px' }}
            onClick={() => stopStreamAndClose()}
          >
            <Close></Close>
          </Button>
        </Grid>
      </Grid>
      <div style={{ position: 'relative' }}>
        {!showVideoFeed ? (
          <Typography variant="subtitle1" style={{ margin: '20px 15px' }}>
            {isCameraAllowed === -1 ? cameraAwaiting : cameraDenied}
          </Typography>
        ) : undefined}
        <video
          ref={video}
          autoPlay={true}
          muted={true}
          playsInline={true}
          onLoadedMetadata={() => handleOnVideoLoad()}
          style={{
            display: showVideoFeed ? 'block' : 'none',
            maxWidth: '100%',
            width: '100%',
            height: 'auto',
            margin: 'auto',
          }}
        ></video>
        <span
          style={{
            display: showVideoFeed ? 'block' : 'none',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            width: '50%',
          }}
        >
          <svg viewBox="0 0 100 100" width="100%">
            <path
              d="M25,2 L2,2 L2,25"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M2,75 L2,98 L25,98"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M75,98 L98,98 L98,75"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M98,25 L98,2 L75,2"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </span>
        <canvas
          ref={canvas}
          style={{
            display: 'none',
          }}
        ></canvas>
      </div>
    </div>
  );
}
