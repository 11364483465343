import React, { useContext, useEffect, useState, useRef } from 'react';
import QRcode from 'qrcode.react';
import { withRouter } from 'react-router-dom';

/// context
import { ProductContext } from '../../Context/ProductContext';
import { AppContext } from '../../Context/AppContextProvide';
import { AlertContext } from '../../Context/AlertContextProvider';
import { PrintQueueContext } from '../../Context/PrintQueueContextProvider';

/// styles
import '../styles/Product.css';
import MockProduct from '../../Util/MockProduct';

// MUI
import NativeSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Autocomplete } from '@material-ui/lab';
import { Fab, Grid, makeStyles, TextField } from '@material-ui/core';

// icon
import PrintIcon from '@material-ui/icons/Print';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import FavoriteSharpIcon from '@material-ui/icons/FavoriteSharp';
import StyleIcon from '@material-ui/icons/Style';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import ContactMailIcon from '@material-ui/icons/ContactMail';

// dependents
import UpdateProduct from './UpdateProduct';
import { getUser } from '../../helpers';

// actions
import {
  updatePrintQueue,
  updateUserRole,
  getRoomCalcSwitchPosition,
  getUsePromoPrice,
  requestSampleCheckout,
  getUserCheckoutStatus,
  getCustomerCheckoutStatus,
  returnSample,
  approveCheckout,
  checkin,
  likeProduct,
  unlikeProduct,
  likeProductStatus,
  cancelSampleCheckout,
  getProductDescription,
  getProdCheckoutSwitchPosition,
  getProdFavSwitchPosition,
  guestCheckout,
  getCustomerUserInfo,
  getRoomvoToggle,
} from '../../Actions';
import ProductStatsDialog from './ProductStatsDialog';
import CreateLeadForm from '../LeadManagement/CreateLeadForm';
import { Camera, Info, Launch } from '@material-ui/icons';
import ScanningModal from '../LandingPage/Scanner/ScanningModal';
import { useThirdPartyScript } from '../../Util/loadExternalScript';

// cost disclaimer
const costDisclaimer = 'Call for price';

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: 600,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'absolute',
    backgroundColor: '#142E3E',
    alignItems: 'center',
    width: '100%',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'absolute',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  headerText: {
    color: '#28C4FC !important',
    marginLeft: 100,
  },
  button: {
    backgroundColor: '#EEEEEE',
    color: '#ff682c',
    margin: 10,
  },
  tableHeader: {
    fontSize: 30,
    '@media (max-width: 1090px)': {
      fontSize: 15,
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '97%',
  },
  closeButton: {
    marginRight: 15,
  },
  autoComplete: {
    width: 170,
  },
}));

const Product = (props) => {
  const {
    params: { productId },
  } = props.match;
  const { user, roleId, roles, setRoleId } = useContext(AppContext);
  const { setUpdate } = useContext(PrintQueueContext);

  const { product, setPath, productDisclaimer, taxDisclaimer, progress } =
    useContext(ProductContext);
  const [calcPrice, setCalcPrice] = useState(0);
  const { handleAlertOpen, setMessageType, setMessage } =
    useContext(AlertContext);

  const classes = useStyles();
  const [open] = useState(false);
  const [color, setColor] = useState('');
  const [price, setPrice] = useState(0);
  const [cost, setCost] = useState(0);
  const [uom, setUom] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [discontinuedFlag, setDiscontinuedFlag] = useState();
  const [roomCalcFlag, setRoomCalcFlag] = useState(0);
  const [sellingUnitQuantity, setSellingUnitQuantity] = useState();
  const [sellingUnitUom, setSellingUnitUom] = useState();
  const [thickness, setThickness] = useState();
  const [wearLayer, setWearLayer] = useState();
  const [ounceWeight, setOunceWeight] = useState();
  const [lastUpdated, setLastUpdated] = useState();
  const [promoPriceStatus, setPromoPriceStatus] = useState();
  const [promoCostLabel, setPromoCostLabel] = useState(false);
  const [retailPrice, setRetailPrice] = useState();
  const [tempCost, setTempCost] = useState();
  const [priceLabel, setPriceLabel] = useState();
  const [promoLabel, setPromoLabel] = useState('Promo');
  const [labelFlag, setLabelFlag] = useState(false);
  const today = new Date().toISOString().slice(0, 10);
  const [favIconColor, setFavIconColor] = useState('#616161');
  const [checkoutIconLabel, setCheckouIconLabel] = useState('Checkout Sample');
  const [favIconLabel, setFavIconLabel] = useState('Like');
  const [checkoutIconColor, setCheckoutIconColor] = useState('');
  const [checkoutStatusInd, setCheckoutStatusInd] = useState('');
  const [userAccessType, setUserAccessType] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [checkoutId, setCheckoutId] = useState();
  const [checkoutList, setCheckoutList] = useState([]);
  const [pendingOpen, setPendingOpen] = useState(false);
  const [userId] = useState(getUser().user_id);
  const [mobileOpen, setMobileOpen] = useState(undefined);
  const [likeStatusList, setLikeStatusList] = useState([]);
  const [productSku, setProductSku] = useState();
  const header = ['Name', 'Location', 'Status', 'Days', 'Approve?'];
  const [value, setValue] = useState(0);
  const [locationId, setLocationId] = useState(
    props.location.search.split('=')[1]
  );
  const [description, setDescription] = useState(null);
  const [productImage, setProductImage] = useState(null);
  const [prodFavSwitchState, setProdFavSwitchState] = useState(true);
  const [prodCheckoutSwitchState, setProdCheckoutSwitchState] = useState(true);
  const [checkoutName, setCheckoutName] = useState({});
  const [checkoutEmail, setCheckoutEmail] = useState({});
  const [checkoutPhone, setCheckoutPhone] = useState({});
  const [userData, setUserData] = useState([]);
  const [tempName, setTempName] = useState(null);
  const [tempEmail, setTempEmail] = useState(null);
  const [tempPhone, setTempPhone] = useState(null);
  const [tempUserId, setTempUserId] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [promoEndDate, setPromoEndDate] = useState();
  const [showStatsDialog, setShowStatsDialog] = useState(false);
  const [showLeadDialog, setShowLeadDialog] = useState(false);
  const [showScannerModal, setShowScannerModal] = useState(false);
  const [roomvoToggle, setRoomvoToggle] = useState(false);

  const roomvoIntegration = useThirdPartyScript(
    roomvoToggle
      ? 'https://cdn.roomvo.com/static/scripts/b2b/showroompricing.js'
      : '',
    true,
    'text/javascript'
  );

  useEffect(() => {
    getCustomerUserInfo()
      .then((result) => {
        setUserData(result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getProdCheckoutSwitchPosition()
      .then((result) => {
        setProdCheckoutSwitchState(result.data.data[0].app_value);
      })
      .catch((err) => {
        console.log(err);
      });
    getProdFavSwitchPosition()
      .then((result) => {
        setProdFavSwitchState(result.data.data[0].app_value);
      })
      .catch((err) => {
        console.log(err);
      });

    if (getUser().access_type_cd === undefined) {
      setCheckoutList([]);
      setCheckoutIconColor('#616161');
      setCheckouIconLabel('Sign in to check out sample');
      setFavIconLabel('Sign in to like this product');
      setFavIconColor('#616161');
    }
    setUserAccessType(getUser().access_type_cd);
    getProductDescription(productId)
      .then((result) => {
        if (result.data.data.length > 0) {
          setDescription(result.data.data[0].product_description);
          setProductImage(result.data.data[0].product_image);
        } else {
          setDescription();
          setProductImage();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    checkoutStatus();
    if (userAccessType !== 'a')
      getUsePromoPrice()
        .then(
          (result) => {
            setPromoPriceStatus(result.data.data[0].app_value_text);
          },
          [value]
        )
        .catch((err) => {
          console.log(err);
        });

    sessionStorage.setItem('productId', JSON.stringify(productId));
    // import('react-facebook-pixel')
    //   .then((x) => x.default)
    //   .then((ReactPixel) => {
    //     ReactPixel.init('603545620913963') // facebookPixelId
    //     ReactPixel.pageView()

    //     withRouter.events.on('routeChangeComplete', () => {
    //       ReactPixel.pageView()
    //     })
    //   })

    getRoomCalcSwitchPosition()
      .then((result) => {
        setRoomCalcFlag(Number(result.data.data[0].active));
      })
      .catch((err) => {
        console.log(err);
      });

    if (user.access_type_cd === 'c' || user.access_type_cd === undefined) {
      setPath(`${productId}${props.location.search}`);
    } else if (
      sessionStorage.getItem('roleId') === null &&
      roleId === undefined
    ) {
      setPath(`${productId}/${user.role_id}${props.location.search}`);
    } else if (roleId === undefined) {
      setPath(
        `${productId}/${sessionStorage.getItem('roleId')}${props.location.search}`
      );
    } else {
      setPath(`${productId}/${roleId}${props.location.search}`);
    }

    if (product.product_uuid) {
      likeProductStatus(productId)
        .then((result) => {
          let firstProdSku = product.options[0].product_sku;
          if (result.data.status.length > 0) {
            setLikeStatusList(result.data.status);
            if (
              result.data.status.find(function (product) {
                if (product.sku === firstProdSku) {
                  return product.sku;
                }
              }).sku === product.options[0].product_sku
            ) {
              setFavIconColor('#FF0000');
              setFavIconLabel('Liked!');
            } else {
              setFavIconColor('#616161');
              setFavIconLabel('Like');
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setPromoEndDate(product.options[0].promotion.effective_end_date);
      setProductSku(product.options[0].product_sku);
      setDiscontinuedFlag(product.options[0].discontinued);
      setColor(product.options[0].color);
      if (
        (product.options[0].retail_price === null &&
          product.options[0].promotion.retail_price === null) ||
        (product.options[0].retail_price === 0 &&
          product.options[0].promotion.retail_price === null)
      ) {
        setPrice(costDisclaimer);
      } else if (product.options[0].promotion) {
        if (
          product.options[0].promotion.retail_price != null &&
          product.options[0].promotion.effective_end_date >= today &&
          product.options[0].promotion.effective_start_date <= today &&
          promoPriceStatus === '1'
        ) {
          setCost(product.options[0].promotion.cost);
          setPrice(product.options[0].promotion.retail_price);
          setPriceLabel(product.options[0].promotion.retail_price);
          setRetailPrice(product.options[0].retail_price);
          setPromoLabel('Promo');
          setPromoCostLabel(true);
        } else {
          setPrice(product.options[0].retail_price);
          setPriceLabel(product.options[0].retail_price);
          setRetailPrice(product.options[0].retail_price);
        }
      } else {
        setPrice(product.options[0].retail_price);
        setPriceLabel(product.options[0].retail_price);
        setRetailPrice(product.options[0].retail_price);
      }

      if (
        product.options[0].cost === null ||
        Number(product.options[0].cost) === 0
      ) {
        setCost(costDisclaimer);
      } else if (product.options[0].promotion) {
        if (
          // product.options[0].promotion.cost != null &&
          product.options[0].promotion.effective_end_date >= today &&
          product.options[0].promotion.effective_start_date <= today &&
          promoPriceStatus === '1'
        ) {
          setCost('$' + product.options[0].promotion.cost);
          setTempCost('$' + product.options[0].cost);
          setLabelFlag(false);
          setPromoCostLabel(true);
        } else {
          setCost('$' + product.options[0].cost);
          setTempCost('$' + product.options[0].cost);
          setPromoCostLabel(false);
          setLabelFlag(true);
        }
      } else {
        setCost('$' + product.options[0].cost);
        setTempCost('$' + product.options[0].cost);
        setPromoCostLabel(false);
      }
      setUom(product.options[0].uom);
      setSellingUnitQuantity(product.options[0].selling_unit_quantity);
      setSellingUnitUom(product.options[0].selling_unit_uom);

      setThickness(product.options[0].thickness);
      setWearLayer(product.options[0].wear_layer);
      setOunceWeight(product.options[0].ounce_weight);
      setLastUpdated(product.options[0].last_modified_dttm);
    }
  }, [
    productId,
    setPath,
    product,
    props.location.search,
    roleId,
    user.access_type_cd,
    user.role_id,
  ]);

  useEffect(() => {
    getRoomvoToggle()
      .then((result) => {
        const toggle = parseInt(result.data) === 1;
        setRoomvoToggle(toggle);
      })
      .catch((err) => {
        setRoomvoToggle(false);
      });
  }, []);

  const handleClick = (index) => {
    mobileOpen === index ? setMobileOpen(undefined) : setMobileOpen(index);
  };

  const handleApproveCheckout = (productId, sampleCheckoutId) => {
    approveCheckout(productId, sampleCheckoutId)
      .then(() => {
        checkoutStatus();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckin = (productId, sampleCheckoutId) => {
    checkin(productId, sampleCheckoutId)
      .then(() => {
        checkoutStatus();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckedIn = (productId, sampleCheckoutId) => {
    console.log("Doesn't do anything yet!");
  };

  const handleCheckedOut = (productId, sampleCheckoutId) => {
    checkin(productId, sampleCheckoutId)
      .then(() => {
        checkoutStatus();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancelCheckout = (sampleCheckoutId) => {
    cancelSampleCheckout(sampleCheckoutId)
      .then(() => {
        setCheckouIconLabel('Sample Check Out');
        setCheckoutStatusInd('ci');
        setCheckoutIconColor('#616161');
        checkoutStatus();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    window.location.href = '/signin';
  };

  const handlePendingClose = () => {
    setPendingOpen(false);
  };

  const handleCheckoutAlertopen = () => {
    setAlertOpen(true);
  };

  const checkoutStatus = () => {
    userAccessType === 'c' ? (
      getCustomerCheckoutStatus(productId)
        .then((result) => {
          setCheckoutList({ checkout_status: result.data.checkout_status });
          setCheckoutId(result.data.sample_checkout_id);
          switch (result.data.checkout_status) {
            case 'Pending':
              setCheckouIconLabel('Sample Checkout Pending');
              setCheckoutIconColor('#FF682C');
              setCheckoutStatusInd('p');
              break;
            case 'Checked Out':
              setCheckouIconLabel('Return Sample');
              setCheckoutIconColor('#23B246');
              setCheckoutStatusInd('co');
              break;
            case 'Returned':
              setCheckouIconLabel('Sample Return Pending');
              setCheckoutIconColor('#FF682C');
              setCheckoutStatusInd('r');
              break;
            case 'Checked In':
              setCheckouIconLabel('Sample Check Out');
              setCheckoutStatusInd('ci');
              setCheckoutIconColor('#616161');
              break;
            case 'Past Due':
              setCheckouIconLabel('Sample Past Due');
              setCheckoutIconColor('#FF0000');
              setCheckoutStatusInd('co');
              break;
            default:
              setCheckouIconLabel('Sample Check Out');
              setCheckoutStatusInd('ci');
              setCheckoutIconColor('#616161');
          }
        })
        .catch((err) => {
          console.log(err);
        })
    ) : userAccessType === 'a' ||
      userAccessType === 'ro' ||
      userAccessType === 'rw' ? (
      getUserCheckoutStatus(productId)
        .then((result) => {
          setCheckoutList(result.data.additional_user_checkout_status);
          if (result.data.checkout_status === '') {
            setCheckouIconLabel('Check Out Sample');
            setCheckoutStatusInd('ci');
            setCheckoutIconColor('#616161');
          } else {
            setCheckoutId(result.data.sample_checkout_id);
            switch (result.data.checkout_status) {
              case 'Pending':
                setCheckouIconLabel('Sample Checkout Pending');
                setCheckoutIconColor('#FF682C');
                setCheckoutStatusInd('p');
                break;
              case 'Checked Out':
                setCheckouIconLabel('Return Sample');
                setCheckoutIconColor('#23B246');
                setCheckoutStatusInd('co');
                break;
              case 'Returned':
                setCheckouIconLabel('Sample Return Pending');
                setCheckoutIconColor('#FF682C');
                setCheckoutStatusInd('r');
                break;
              case 'Checked In':
                setCheckouIconLabel('Check Out Sample');
                setCheckoutStatusInd('ci');
                setCheckoutIconColor('#616161');
                break;
              case 'Past Due':
                setCheckouIconLabel('Sample Past Due');
                setCheckoutIconColor('#FF0000');
                setCheckoutStatusInd('co');
                break;
              default:
                setCheckouIconLabel('Check Out Sample');
                setCheckoutStatusInd('ci');
                setCheckoutIconColor('#616161');
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
    ) : (
      <></>
    );
  };

  const calculatePrice = (input, price, selling_unit_quantity) => {
    setQuantity(input);
    if (
      selling_unit_quantity === undefined ||
      selling_unit_quantity == '' ||
      selling_unit_quantity == null
    ) {
      selling_unit_quantity = 1;
    }

    let qty = input;

    if (input < 0) {
      qty = -1 * input;
    }

    qty = Math.ceil(qty / selling_unit_quantity) * selling_unit_quantity;

    setCalcPrice(qty * price);
  };

  const handleSizeChange = (e) => {
    likeProductStatus(productId)
      .then((result) => {
        setLikeStatusList(result.data.status);
      })
      .catch((err) => {
        console.log(err);
      });
    const tempColor = e.target.value;
    setColor(tempColor);
    const sizeOption = product.options.filter(
      (option) => option.color === tempColor
    )[0];

    if (
      sizeOption.promotion &&
      sizeOption.promotion.retail_price !== null &&
      promoPriceStatus === '1' &&
      sizeOption.promotion.effective_end_date >= today &&
      sizeOption.promotion.effective_start_date <= today
    ) {
      setPrice(sizeOption.promotion.retail_price);
      setPriceLabel(sizeOption.promotion.retail_price);
      setRetailPrice(sizeOption.retail_price);
      setPromoLabel('Promo');
    } else if (
      sizeOption.promotion &&
      sizeOption.retailPrice !== null &&
      sizeOption.promotion.retail_price === null &&
      promoPriceStatus === '1' &&
      sizeOption.promotion.effective_end_date >= today &&
      sizeOption.promotion.effective_start_date <= today
    ) {
      setPrice(sizeOption.retail_price);
      setPriceLabel(sizeOption.retail_price);
      setRetailPrice(sizeOption.retail_price);
      setPromoLabel('Promo');
    } else {
      setPrice(sizeOption.retail_price);
      setPriceLabel(sizeOption.retail_price);
      setRetailPrice(sizeOption.retail_price);
      setPromoLabel('');
    }

    if (
      sizeOption.promotion &&
      sizeOption.promotion.retail_price !== null &&
      promoPriceStatus === '1' &&
      sizeOption.promotion.effective_end_date >= today &&
      sizeOption.promotion.effective_start_date <= today
    ) {
      setCost('$' + sizeOption.promotion.cost);
      setTempCost('$' + sizeOption.cost);
      setPromoCostLabel(true);
      setLabelFlag(false);
    } else if (
      sizeOption.promotion &&
      sizeOption.retail_price !== null &&
      sizeOption.promotion.retail_price === null &&
      promoPriceStatus === '1' &&
      sizeOption.promotion.effective_end_date >= today &&
      sizeOption.promotion.effective_start_date <= today
    ) {
      setCost('$' + sizeOption.promotion.cost);
      setTempCost('$' + sizeOption.cost);
      setPromoCostLabel(true);
      setLabelFlag(false);
    } else {
      if (Number(sizeOption.cost) === 0) {
        setCost(costDisclaimer);
        setPromoCostLabel(false);
      } else {
        setCost('$' + sizeOption.cost);
        setTempCost('$' + sizeOption.cost);
        setPromoCostLabel(false);
        setLabelFlag(true);
      }
    }

    setSellingUnitQuantity(sizeOption.selling_unit_quantity);
    setSellingUnitUom(sizeOption.selling_unit_uom);
    setUom(sizeOption.uom);
    setDiscontinuedFlag(sizeOption.discontinued);
    switch (true) {
      case likeStatusList.length > 0:
        if (
          likeStatusList.find((line) => {
            return line.sku === sizeOption.product_sku;
          })
        ) {
          setFavIconColor('#FF0000');
          setFavIconLabel('Liked!');
        } else {
          setFavIconColor('#616161');
          setFavIconLabel('Like');
        }
      default:
    }

    setThickness(sizeOption.thickness);
    setWearLayer(sizeOption.wear_layer);
    setOunceWeight(sizeOption.ounce_weight);
    setLastUpdated(sizeOption.last_modified_dttm);
    setProductSku(sizeOption.product_sku);
  };

  const handleRoleChange = (e) => {
    setRoleId(e.target.value);
    if (user.access_type_cd === 'c' || user.access_type_cd === undefined) {
      setPath(`${productId}${props.location.search}`);
    } else {
      setPath(`${productId}/${e.target.value}${props.location.search}`);
    }
    updateUserRole(e.target.value)
      .then((result) => {
        sessionStorage.setItem(
          'roleId',
          Number(result.config.data.split(':')[1].split('}')[0])
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdatePrintQueue = (product_uuid) => {
    updatePrintQueue([product_uuid])
      .then(() => {
        setUpdate(true);
        setMessageType('success');
        setMessage('Print Queue Updated');
        handleAlertOpen();
      })
      .catch((err) => {
        setMessageType('error');
        setMessage('Error Updating Print Queue');
        handleAlertOpen();
      });
  };

  const renderAlertIcon = (product) => {
    // if (product.options.find(date => date.product_effective_end !=  null) ) {
    if (product.options.find((date) => date.discontinued === 1)) {
      return (
        <Tooltip title="There is at least one discontinued product">
          <InfoIcon color="secondary" />
        </Tooltip>
      );
    } else {
      return <div></div>;
    }
  };

  const handleUserCheckoutActions = () => {
    checkoutStatusInd === 'co'
      ? returnSample(checkoutId)
          .then(() => {
            checkoutStatus();
          })
          .catch((err) => {
            console.log(err);
          })
      : checkoutStatusInd === 'p'
        ? handleApproveCheckout(
            productId,
            checkoutList.find((e) => e.user_id === userId).sample_checkout_id
          )
        : checkoutStatusInd === 'r'
          ? handleCheckin(
              productId,
              checkoutList.find((e) => e.user_id === userId).sample_checkout_id
            )
          : requestSampleCheckout(productId, locationId)
              .then(() => {
                checkoutStatus();
              })
              .catch((err) => {
                console.log(err);
              });
  };

  const onClickCheckout = () => {
    userAccessType === 'c'
      ? checkoutStatusInd === 'co'
        ? returnSample(checkoutId)
            .then(() => {
              checkoutStatus();
            })
            .catch((err) => {
              console.log(err);
            })
        : requestSampleCheckout(productId, locationId)
            .then(() => {
              checkoutStatus();
            })
            .catch((err) => {
              console.log(err);
            })
      : userAccessType === 'a' ||
          userAccessType === 'rw' ||
          userAccessType === 'ro'
        ? setPendingOpen(true)
        : handleCheckoutAlertopen();
  };

  const onClickfavorite = (product_id, product_sku) => {
    userAccessType === 'c' ||
    userAccessType === 'ro' ||
    userAccessType === 'rw' ||
    userAccessType === 'a'
      ? favIconLabel === 'Like'
        ? likeProduct(product_id, product_sku, locationId)
            .then(() => {
              setFavIconColor('#FF0000');
              setFavIconLabel('Liked!');
            })
            .catch((err) => {
              console.log(err);
            })
        : unlikeProduct(product_id, product_sku)
            .then(() => {
              setFavIconLabel('Like');
              setFavIconColor('#616161');
            })
            .catch((err) => {
              console.log(err);
            })
      : handleCheckoutAlertopen();
  };

  const handleCheckoutName = (e) => {
    setCheckoutName({ name: e });
  };

  const handleNameAutoComplete = (e, data) => {
    if (data === null) {
      setCheckoutName({ name: '' });
    } else {
      setDisabled(true);
      setTempUserId(data.user_id);
      setTempName({ name: data.name });
      setTempEmail({ email: data.email });
      setTempPhone({ phone: data.phone });
      setCheckoutName({ name: data.name });
      setCheckoutEmail({ email: data.email });
      setCheckoutPhone({ phone: data.phone });
    }
  };

  const handleCheckoutEmail = (e) => {
    setCheckoutEmail({ email: e });
  };

  const handleEmailAutoComplete = (e, data) => {
    if (data === null) {
      setCheckoutEmail({ email: '' });
    } else {
      setDisabled(true);
      setTempUserId(data.user_id);
      setTempName({ name: data.name });
      setTempEmail({ email: data.email });
      setTempPhone({ phone: data.phone });
      setCheckoutName({ name: data.name });
      setCheckoutEmail({ email: data.email });
      setCheckoutPhone({ phone: data.phone });
    }
  };

  const handleCheckoutPhone = (e) => {
    setCheckoutPhone({ phone: e });
  };

  const handlePhoneAutoComplete = (e, data) => {
    if (data === null) {
      setCheckoutPhone({ phone: '' });
    } else {
      setDisabled(true);
      setTempUserId(data.user_id);
      setTempName({ name: data.name });
      setTempEmail({ email: data.email });
      setTempPhone({ phone: data.phone });
      setCheckoutName({ name: data.name });
      setCheckoutEmail({ email: data.email });
      setCheckoutPhone({ phone: data.phone });
    }
  };

  const handleGuestCheckoutSubmit = () => {
    let data = {
      userId: tempUserId,
      productId: productId,
      checkoutName: checkoutName.name,
      checkoutEmail: checkoutEmail.email,
      checkoutPhone: checkoutPhone.phone,
    };

    // switch(userData.some(person => checkoutEmail.email === person.email) ||
    // userData.some(person => checkoutPhone.phone === person.phone)) {
    //   case true:
    //     data = {
    //       userId: tempUserId,
    //       productId: productId,
    //       checkoutName: checkoutName.name,
    //       checkoutEmail: checkoutEmail.email,
    //       checkoutPhone: checkoutPhone.phone
    //     }
    //     break;
    //     default:
    //       data = {
    //         userId: null,
    //         productId: productId,
    //         checkoutName: checkoutName.name,
    //         checkoutEmail: checkoutEmail.email,
    //         checkoutPhone: checkoutPhone.phone
    //       }
    //     }

    guestCheckout(data).then(() => {
      getCustomerUserInfo()
        .then((result) => {
          setUserData(result.data.data);
          checkoutStatus();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleClear = () => {
    setDisabled(false);
    setCheckoutEmail({});
    setCheckoutName({});
    setCheckoutPhone({});
    setTempName(checkoutName.name);
    setTempEmail(checkoutEmail.email);
    setTempPhone(checkoutPhone.phone);
    setTempUserId(null);
  };

  const canGuestCheckout = () => {
    if (checkoutName && checkoutEmail && checkoutPhone) {
      return false;
    }
    return true;
  };

  const renderAlert = () => {
    return (
      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Sample Checkout</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h6">
              To add products to your favorites list or to check out product
              samples, please create an account. If you already have an account,
              please sign in. <br />
              <br /> Thanks
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleAlertClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderPending = () => {
    return (
      <Dialog
        open={pendingOpen}
        onClose={handlePendingClose}
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="product__pending-container-full"
      >
        <div className={classes.root}>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <div></div>
              <div>
                <Typography variant="h4" className={classes.headerText}>
                  Sample Checkout
                </Typography>
              </div>
              <div className={classes.closeButton}>
                <Button
                  variant="outlined"
                  onClick={handlePendingClose}
                  className="product__pending-header-button"
                >
                  X
                </Button>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Toolbar />
            <div className={classes.drawerContainer}>
              <List>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="h5">Your Checkout Status</Typography>
                    }
                    secondary={
                      userAccessType === 'c' ? (
                        <Button onClick={handleUserCheckoutActions}>
                          <Chip
                            icon={
                              <LibraryAddCheckIcon
                                style={{ color: checkoutIconColor }}
                              />
                            }
                            label={checkoutIconLabel}
                            variant="default"
                            // className="product__pending-header-chip"
                          />
                        </Button>
                      ) : checkoutList[
                          checkoutList.findIndex((e) => e.user_id === userId)
                        ] !== undefined ? (
                        checkoutList.length > 0 &&
                        checkoutList.length > 0 &&
                        checkoutList[
                          checkoutList.findIndex((e) => e.user_id === userId)
                        ].status_name === 'Pending' &&
                        checkoutList.find((u) => u.user_id === userId) ? (
                          <Button onClick={handleUserCheckoutActions}>
                            <Chip
                              icon={
                                <LibraryAddCheckIcon
                                  style={{ color: checkoutIconColor }}
                                />
                              }
                              label={checkoutIconLabel}
                              variant="default"
                              onDelete={() => {
                                handleCancelCheckout(checkoutId);
                              }}
                              // className="product__pending-header-chip"
                            />
                          </Button>
                        ) : (
                          <Button onClick={handleUserCheckoutActions}>
                            <Chip
                              icon={
                                <LibraryAddCheckIcon
                                  style={{ color: checkoutIconColor }}
                                />
                              }
                              label={checkoutIconLabel}
                              variant="default"
                              // className="product__pending-header-chip"
                            />
                          </Button>
                        )
                      ) : (
                        <Button onClick={handleUserCheckoutActions}>
                          <Chip
                            icon={
                              <LibraryAddCheckIcon
                                style={{ color: checkoutIconColor }}
                              />
                            }
                            label={checkoutIconLabel}
                            variant="default"
                            // className="product__pending-header-chip"
                          />
                        </Button>
                      )
                    }
                  />
                </ListItem>
                <Divider />
                {/* <ListItem>
              
            </ListItem> */}
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="h5">Guest Checkout</Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <Autocomplete
                    disabled={disabled}
                    freeSolo
                    value={tempName}
                    options={userData}
                    getOptionLabel={(option) =>
                      option.name !== null ? option.name : ''
                    }
                    className={classes.autoComplete}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Name"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    )}
                    onInputChange={(e, data) => handleCheckoutName(data)}
                    onChange={handleNameAutoComplete}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <Autocomplete
                    disabled={disabled}
                    freeSolo
                    value={tempEmail}
                    options={userData}
                    getOptionLabel={(option) =>
                      option.email !== null ? option.email : ''
                    }
                    className={classes.autoComplete}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Email"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    )}
                    onInputChange={(e, data) => handleCheckoutEmail(data)}
                    onChange={handleEmailAutoComplete}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon />
                  </ListItemIcon>
                  <Autocomplete
                    disabled={disabled}
                    freeSolo
                    value={tempPhone}
                    options={userData}
                    getOptionLabel={(option) =>
                      option.phone !== null ? option.phone : ''
                    }
                    className={classes.autoComplete}
                    onInputChange={(e, data) => handleCheckoutPhone(data)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Phone"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    )}
                    onChange={handlePhoneAutoComplete}
                  />
                </ListItem>
                <ListItem>
                  <Button
                    variant="contained"
                    autoFocus
                    style={{ margin: '10px 0px', marginRight: '20px' }}
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      margin: '10px 0px',
                      backgroundColor: '#4caf50',
                      color: '#FFFFFF',
                    }}
                    onClick={handleGuestCheckoutSubmit}
                    disabled={canGuestCheckout()}
                  >
                    Submit
                  </Button>
                </ListItem>
              </List>
            </div>
          </Drawer>
          <main className={classes.content}>
            <Toolbar />
            <TableContainer>
              <Table aria-label="sample-table" stickyHeader>
                <TableHead>
                  <TableRow>
                    {header.map((column, index) => (
                      <TableCell key={index}>
                        <Typography className={classes.tableHeader}>
                          {column}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {checkoutList.length > 0
                    ? checkoutList.map((row, index) => {
                        // setMaterialMarginRate([row["material_class"]]: row["margin"]})
                        return (
                          <TableRow key={index}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.location_name}</TableCell>
                            <TableCell>{row.status_name}</TableCell>
                            <TableCell>{row.days_checked_out}</TableCell>
                            <TableCell>
                              {row.status_name === 'Pending' ? (
                                <Button
                                  className="product__icon-buttons"
                                  onClick={() =>
                                    handleApproveCheckout(
                                      productId,
                                      row.sample_checkout_id
                                    )
                                  }
                                >
                                  <Chip
                                    icon={
                                      <LibraryAddCheckIcon
                                        style={{ color: '#EED202' }}
                                      />
                                    }
                                    label={row.status_name}
                                    variant="outlined"
                                    onDelete={() => {
                                      handleCancelCheckout(
                                        row.sample_checkout_id
                                      );
                                    }}
                                  />
                                </Button>
                              ) : row.status_name === 'Returned' ? (
                                <Button
                                  className="product__icon-buttons"
                                  onClick={() =>
                                    handleCheckin(
                                      productId,
                                      row.sample_checkout_id
                                    )
                                  }
                                >
                                  <Chip
                                    icon={
                                      <LibraryAddCheckIcon
                                        style={{ color: '#EED202' }}
                                      />
                                    }
                                    label={row.status_name}
                                    variant="outlined"
                                  />
                                </Button>
                              ) : row.status_name === 'Checked In' ? (
                                <Button
                                  className="product__icon-buttons"
                                  onClick={() =>
                                    handleCheckedIn(
                                      productId,
                                      row.sample_checkout_id
                                    )
                                  }
                                >
                                  <Chip
                                    icon={
                                      <LibraryAddCheckIcon
                                        style={{ color: '#2db83d' }}
                                      />
                                    }
                                    label={row.status_name}
                                    variant="outlined"
                                  />
                                </Button>
                              ) : row.status_name === 'Checked Out' ? (
                                <Button
                                  className="product__icon-buttons"
                                  onClick={() =>
                                    handleCheckedOut(
                                      productId,
                                      row.sample_checkout_id
                                    )
                                  }
                                >
                                  <Chip
                                    icon={
                                      <LibraryAddCheckIcon
                                        style={{ color: '#2db83d' }}
                                      />
                                    }
                                    label={row.status_name}
                                    variant="outlined"
                                  />
                                </Button>
                              ) : (
                                <></>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : ''}
                </TableBody>
              </Table>
            </TableContainer>
          </main>
        </div>
      </Dialog>
    );
  };

  const renderPendingMobile = () => {
    return (
      <Dialog
        open={pendingOpen}
        onClose={handlePendingClose}
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="product__pending-container-mobile"
      >
        <DialogTitle
          style={{
            textAlign: 'center',
            backgroundColor: '#142E3E',
            color: '#28C4FC',
          }}
        >
          <span style={{ color: '#28C4FC' }}>
            <Typography variant="h4" className="product__pending-dialog-header">
              Checkout
              <Button
                variant="outlined"
                onClick={handlePendingClose}
                className="product__pending-header-button"
              >
                X
              </Button>
            </Typography>
          </span>
        </DialogTitle>
        <DialogContent>
          <List component="nav" aria-labelledby="nested-list-subheader">
            <ListItem>
              <ListItemText
                primary={<Typography variant="h5">Your Checkout</Typography>}
                secondary={
                  userAccessType === 'c' ? (
                    <Button
                      onClick={handleUserCheckoutActions}
                      className="product__pending-header-chip"
                    >
                      <Chip
                        icon={
                          <LibraryAddCheckIcon
                            style={{ color: checkoutIconColor }}
                          />
                        }
                        label={checkoutIconLabel}
                        variant="default"
                      />
                    </Button>
                  ) : checkoutList[
                      checkoutList.findIndex((e) => e.user_id === userId)
                    ] !== undefined ? (
                    checkoutList.length > 0 &&
                    checkoutList[
                      checkoutList.findIndex((e) => e.user_id === userId)
                    ].status_name === 'Pending' &&
                    checkoutList.find((u) => u.user_id === userId) ? (
                      <Button
                        onClick={handleUserCheckoutActions}
                        className="product__pending-header-chip"
                      >
                        <Chip
                          icon={
                            <LibraryAddCheckIcon
                              style={{ color: checkoutIconColor }}
                            />
                          }
                          label={checkoutIconLabel}
                          variant="default"
                          onDelete={() => {
                            handleCancelCheckout(checkoutId);
                          }}
                        />
                      </Button>
                    ) : (
                      <Button
                        onClick={handleUserCheckoutActions}
                        className="product__pending-header-chip"
                      >
                        <Chip
                          icon={
                            <LibraryAddCheckIcon
                              style={{ color: checkoutIconColor }}
                            />
                          }
                          label={checkoutIconLabel}
                          variant="default"
                        />
                      </Button>
                    )
                  ) : (
                    <Button
                      onClick={handleUserCheckoutActions}
                      className="product__pending-header-chip"
                    >
                      <Chip
                        icon={
                          <LibraryAddCheckIcon
                            style={{ color: checkoutIconColor }}
                          />
                        }
                        label={checkoutIconLabel}
                        variant="default"
                      />
                    </Button>
                  )
                }
              />
            </ListItem>
            <List>
              <ListItem
                button
                onClick={() => handleClick(true)}
                className="checkout__list-menu"
              >
                <ListItemText primary="Guest Checkout" />
                {mobileOpen === true ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              {mobileOpen === true ? (
                <Collapse in={true} timeout="auto" unmountOnExit>
                  <List style={{ backgroundColor: '#FFF' }}>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography variant="h5">Guest Checkout</Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <Autocomplete
                        disabled={disabled}
                        freeSolo
                        value={tempName}
                        options={userData}
                        getOptionLabel={(option) =>
                          option.name !== null ? option.name : 'no name'
                        }
                        className={classes.autoComplete}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Name"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                          />
                        )}
                        onInputChange={(e, data) => handleCheckoutName(data)}
                        onChange={handleNameAutoComplete}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <EmailIcon />
                      </ListItemIcon>
                      <Autocomplete
                        disabled={disabled}
                        freeSolo
                        value={tempEmail}
                        options={userData}
                        getOptionLabel={(option) =>
                          option.email !== null ? option.email : 'no email'
                        }
                        className={classes.autoComplete}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Email"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                          />
                        )}
                        onInputChange={(e, data) => handleCheckoutEmail(data)}
                        onChange={handleEmailAutoComplete}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <PhoneIcon />
                      </ListItemIcon>
                      <Autocomplete
                        disabled={disabled}
                        freeSolo
                        value={tempPhone}
                        options={userData}
                        getOptionLabel={(option) =>
                          option.phone !== null ? option.phone : 'no phone'
                        }
                        className={classes.autoComplete}
                        onInputChange={(e, data) => handleCheckoutPhone(data)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Phone"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                          />
                        )}
                        onChange={handlePhoneAutoComplete}
                      />
                    </ListItem>
                    <ListItem>
                      <Button
                        variant="contained"
                        autoFocus
                        style={{ margin: '10px 0px', marginRight: '20px' }}
                        onClick={handleClear}
                      >
                        Clear
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          margin: '10px 0px',
                          backgroundColor: '#4caf50',
                          color: '#FFFFFF',
                        }}
                        onClick={handleGuestCheckoutSubmit}
                        disabled={canGuestCheckout()}
                      >
                        Submit
                      </Button>
                    </ListItem>
                    <Divider />
                  </List>
                </Collapse>
              ) : (
                <></>
              )}
            </List>
            {checkoutList.length > 0
              ? checkoutList.map((pendingProduct, index) => {
                  return (
                    <List key={index}>
                      <ListItem
                        button
                        onClick={() => handleClick(index)}
                        className="checkout__list-menu"
                      >
                        <ListItemText primary={pendingProduct.name} />
                        {mobileOpen === index ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      {mobileOpen === index ? (
                        <Collapse in={true} timeout="auto" unmountOnExit>
                          <List style={{ backgroundColor: '#FFF' }}>
                            <ListItem>
                              <ListItemIcon>
                                <MyLocationIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={pendingProduct.location_name}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemIcon>
                                <StyleIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={pendingProduct.status_name}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemIcon>
                                {pendingProduct.days_checked_out > 10 &&
                                pendingProduct.days_checked_out <= 15 ? (
                                  <DateRangeIcon style={{ color: '#EED202' }} />
                                ) : pendingProduct.days_checked_out >= 16 ? (
                                  <DateRangeIcon style={{ color: '#D0342C' }} />
                                ) : (
                                  <DateRangeIcon />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                primary={pendingProduct.days_checked_out}
                              />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              {pendingProduct.status_name === 'Pending' ? (
                                <Button
                                  className="product__icon-buttons"
                                  onClick={() => {
                                    handleApproveCheckout(
                                      productId,
                                      pendingProduct.sample_checkout_id
                                    );
                                  }}
                                >
                                  <Chip
                                    icon={
                                      <LibraryAddCheckIcon
                                        style={{ color: '#EED202' }}
                                      />
                                    }
                                    label={pendingProduct.status_name}
                                    variant="outlined"
                                    onDelete={() => {
                                      handleCancelCheckout(
                                        pendingProduct.sample_checkout_id
                                      );
                                    }}
                                  />
                                </Button>
                              ) : pendingProduct.status_name === 'Returned' ? (
                                <Button
                                  className="product__icon-buttons"
                                  onClick={() => {
                                    handleCheckin(
                                      productId,
                                      pendingProduct.sample_checkout_id
                                    );
                                  }}
                                >
                                  <Chip
                                    icon={
                                      <LibraryAddCheckIcon
                                        style={{ color: '#EED202' }}
                                      />
                                    }
                                    label={pendingProduct.status_name}
                                    variant="outlined"
                                  />
                                </Button>
                              ) : pendingProduct.status_name ===
                                'Checked In' ? (
                                <Button
                                  className="product__icon-buttons"
                                  onClick={() => {
                                    handleCheckedIn(
                                      productId,
                                      pendingProduct.sample_checkout_id
                                    );
                                  }}
                                >
                                  <Chip
                                    icon={
                                      <LibraryAddCheckIcon
                                        style={{ color: '#2db83d' }}
                                      />
                                    }
                                    label={pendingProduct.status_name}
                                    variant="outlined"
                                  />
                                </Button>
                              ) : pendingProduct.status_name ===
                                'Checked Out' ? (
                                <Button
                                  className="product__icon-buttons"
                                  onClick={() => {
                                    handleCheckedOut(
                                      productId,
                                      pendingProduct.sample_checkout_id
                                    );
                                  }}
                                >
                                  <Chip
                                    icon={
                                      <LibraryAddCheckIcon
                                        style={{ color: '#2db83d' }}
                                      />
                                    }
                                    label={pendingProduct.status_name}
                                    variant="outlined"
                                  />
                                </Button>
                              ) : (
                                <></>
                              )}
                            </ListItem>
                          </List>
                        </Collapse>
                      ) : (
                        <></>
                      )}
                    </List>
                  );
                })
              : ''}
          </List>
        </DialogContent>
      </Dialog>
    );
  };

  const renderOptions = (options) => {
    return (
      <NativeSelect
        className="product__view-options"
        value={color}
        onChange={handleSizeChange}
        displayEmpty
        placeholder="Select Option"
        variant="outlined"
      >
        {options.map((option, i) => {
          const { color } = option;
          return option.discontinued === 1 ? (
            <MenuItem className="product__options" key={i} value={color}>
              {color}
              <Tooltip title="Product discontinued">
                <InfoIcon color="secondary" />
              </Tooltip>
            </MenuItem>
          ) : (
            <MenuItem className="product__options" key={i} value={color}>
              {color}
            </MenuItem>
          );
        })}
      </NativeSelect>
    );
  };

  const handlePriceLabelChange = () => {
    if (promoLabel === 'Promo') {
      setPriceLabel(retailPrice);
      setPromoLabel('');
      setLabelFlag(true);
    } else {
      setPriceLabel(price);
      setPromoLabel('Promo');
      setLabelFlag(false);
    }
  };

  const renderDollarSign = () => {
    return price === null || price < 0.00001 || price === costDisclaimer ? (
      <Typography variant="h5">{costDisclaimer}</Typography>
    ) : promoCostLabel === true && promoPriceStatus === '1' ? (
      // <div>
      //    <Typography className="price-text">{`$ ${retailPrice} /${uom}`}</Typography>
      <div>
        <Button onClick={handlePriceLabelChange}>
          <Typography className="price-text-default">{`${promoLabel} $ ${priceLabel} /${uom}`}</Typography>
        </Button>
        <Typography>Promo ends - {promoEndDate}</Typography>
      </div>
    ) : (
      <Typography className="price-text-default">{`$ ${price} /${uom}`}</Typography>
    );
  };

  const renderRoomCalc = () => {
    return price === null || price < 0.00001 || price === costDisclaimer ? (
      <td>{costDisclaimer}</td>
    ) : (
      <td>{'$' + parseFloat(calcPrice).toFixed(2)}</td>
    );
  };

  const renderPrice = () => {
    return discontinuedFlag !== 0 ? (
      <tr>
        <td className="product__price">
          <Typography variant="h4" style={{ color: '#e1315a' }}>
            {'Discontinued'}
          </Typography>
        </td>
      </tr>
    ) : (
      <tr>
        <td className="product__price">
          <Typography
            className="product__price-currency"
            variant="overline"
          ></Typography>
          {renderDollarSign()}
          {/* <Typography
            className="product__price-uom"
            variant="caption"
          >
            / {uom}
          </Typography> */}
        </td>
      </tr>
    );
  };

  const renderProductInfo = (data) => {
    if (data.product_uuid) {
      const {
        disclaimer,
        manufacturer_name,
        material_class,
        options,
        product_uuid,
        style,
        trade_name,
        size_code,
        role,
        location,
      } = data;

      const renderPriceHeader = () => {
        return Number(role.role_prefix) === 0 ||
          role.role_prefix === undefined ? (
          <Typography variant="body1" gutterBottom>
            Price
          </Typography>
        ) : user.user_id && user.access_type_cd !== 'c' ? (
          <NativeSelect
            value={!roleId ? sessionStorage.getItem('roleId') : roleId}
            onChange={handleRoleChange}
            displayEmpty
            placeholder="Select Option"
            disableUnderline
          >
            {roles.length !== 0
              ? roles.map((role) => {
                  return (
                    <MenuItem
                      key={role.role_id}
                      value={role.role_id}
                      data={JSON.stringify(role)}
                    >{`${role.role_name} Price`}</MenuItem>
                  );
                })
              : roles.map((role) => {
                  return (
                    <MenuItem
                      key={role.role_id}
                      value={role.role_id}
                      data={JSON.stringify(role)}
                    >{`${role.role_name} Price`}</MenuItem>
                  );
                })}
          </NativeSelect>
        ) : (
          <Typography variant="body1" gutterBottom>
            {role.role_name} Price
          </Typography>
        );
      };

      let absoluteLogoLink = description;
      if (description !== null) {
        if (!description.toLowerCase().startsWith('http')) {
          absoluteLogoLink = '//' + absoluteLogoLink;
        }
      }

      // const { company, options, product_uuid, style, style_name, type } = data;
      return (
        <div className="container">
          <Fab
            size="medium"
            variant="extended"
            style={{
              zIndex: '20',
              position: 'fixed',
              bottom: '0px',
              right: '0px',
              padding: '0px 12px',
              color: '#28C4FC',
              backgroundColor: '#142E3E',
              margin: '20px',
            }}
            onClick={() => setShowScannerModal(true)}
          >
            <Camera fontSize="small" style={{ marginRight: '5px' }} />
            Scan QR
          </Fab>
          <ScanningModal
            isOpen={showScannerModal}
            handleOnClose={() => setShowScannerModal(false)}
          />
          {renderAlert()}
          <div key={product_uuid} className="product">
            <div className="product-info">
              <Paper className="product__view-details" elevation={0}>
                <div className="product__price-container">
                  <table className="product__view-price-table">
                    <thead>
                      <tr>{location ? <th>{location}</th> : <td></td>}</tr>
                      <tr>
                        <th>{renderPriceHeader()}</th>
                      </tr>
                    </thead>
                    <tbody className="product__price-tbody">
                      {renderPrice()}
                      <tr>
                        <td>
                          <Typography variant="caption">
                            {taxDisclaimer}
                          </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="product__details-container">
                  <table className="product_view-details-table">
                    <tbody>
                      {sellingUnitQuantity !== 0 && sellingUnitQuantity ? (
                        <tr>
                          <td className="product__td">Sold in Quantities of</td>
                          <td>
                            {sellingUnitQuantity} {sellingUnitUom}
                          </td>
                        </tr>
                      ) : undefined}
                      {thickness !== 0 && thickness ? (
                        <tr>
                          <td className="product__td">Thickness</td>
                          <td>{thickness}</td>
                        </tr>
                      ) : undefined}
                      {wearLayer !== 0 && wearLayer ? (
                        <tr>
                          <td className="product__td">Wear Layer</td>
                          <td>{wearLayer}</td>
                        </tr>
                      ) : undefined}
                      {ounceWeight !== 0 && ounceWeight ? (
                        <tr>
                          <td className="product__td">Ounce Weight</td>
                          <td>{ounceWeight}</td>
                        </tr>
                      ) : undefined}
                      {manufacturer_name ? (
                        <tr>
                          <td className="product__td">Manufacturer</td>
                          <td>{manufacturer_name}</td>
                        </tr>
                      ) : undefined}
                      {/* <tr>
                      <td font-style="italic" font-weight="200">{manufacturer_name}</td>
                      </tr> */}
                      <tr>
                        <td className="product__td">Material</td>
                        <td>{material_class}</td>
                      </tr>
                      <tr>
                        <td className="product__td">Style Number</td>
                        <td>{style}</td>
                      </tr>
                      <tr>
                        <td className="product__td">Style Name</td>
                        <td>
                          <div className="product__mobile--view">
                            {trade_name}
                          </div>
                        </td>
                      </tr>
                      {size_code !== 0 && size_code ? (
                        <tr>
                          <td className="product__td">Size</td>
                          <td>{size_code}</td>
                        </tr>
                      ) : undefined}
                      <tr>
                        <td className="product__td">
                          Options {renderAlertIcon(product)}
                        </td>
                        <td className="options_dd">{renderOptions(options)}</td>
                      </tr>
                      {cost !== `$null` &&
                      user.user_id &&
                      user.access_type_cd !== 'c' &&
                      user.access_type_cd !== 'ro' &&
                      promoCostLabel === true &&
                      labelFlag === false ? (
                        <tr>
                          <td className="product__td">
                            {promoCostLabel === true && promoPriceStatus === '1'
                              ? 'Promo Cost'
                              : 'Cost'}
                          </td>
                          <td>{cost}</td>
                        </tr>
                      ) : undefined}
                      {user.user_id &&
                      user.access_type_cd !== 'c' &&
                      user.access_type_cd !== 'ro' &&
                      labelFlag === true ? (
                        <tr>
                          <td className="product__td">Cost</td>
                          <td>{tempCost}</td>
                        </tr>
                      ) : undefined}
                      {user.user_id && user.access_type_cd !== 'c' ? (
                        <tr>
                          <td className="product__td">Last Updated</td>
                          <td>{lastUpdated}</td>
                        </tr>
                      ) : undefined}
                      {roomCalcFlag === 1 ? (
                        <tr>
                          <td className="product__td">Room Size</td>
                          <td>
                            <input
                              style={{
                                width: '80px',
                                height: '28px',
                                padding: '3px 5px',
                              }}
                              type="number"
                              min="1"
                              value={quantity}
                              onChange={(e) =>
                                calculatePrice(
                                  e.target.value,
                                  price,
                                  sellingUnitQuantity
                                )
                              }
                            />
                          </td>
                        </tr>
                      ) : (
                        ''
                      )}
                      {roomCalcFlag === 1 ? (
                        <tr>
                          <td className="product__td">Estimated Total $</td>
                          {renderRoomCalc()}
                        </tr>
                      ) : (
                        ''
                      )}
                      <tr>
                        <td colSpan="2">
                          <Divider />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" style={{ textAlign: 'center' }}>
                          {description ? (
                            <Button
                              variant="contained"
                              href={absoluteLogoLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                width: '40%',
                                minWidth: '250px',
                                padding: '8px 10px',
                                backgroundColor: '#FFFFFF',
                                margin: '5px 10px',
                              }}
                            >
                              <Launch style={{ marginRight: '6px' }}></Launch>{' '}
                              See product online
                            </Button>
                          ) : undefined}
                          {roomvoToggle && roomvoIntegration === 'ready' ? (
                            <Button
                              variant="contained"
                              style={{
                                width: '40%',
                                minWidth: '250px',
                                padding: '8px 10px',
                                backgroundColor: '#FFFFFF',
                                margin: '5px 10px',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                window.roomvo.startStandaloneVisualizer(
                                  '',
                                  productSku
                                );
                              }}
                            >
                              Roomvo Visualizer
                            </Button>
                          ) : undefined}
                        </td>
                      </tr>
                      {productImage ? (
                        <tr>
                          <td colSpan="2">
                            <div
                              style={{
                                width: '15em',
                                margin: 'auto',
                                marginTop: 30,
                                boxShadow: '0px 3px 10px 1px #ADAAAB',
                              }}
                            >
                              <Card>
                                <CardMedia
                                  component="img"
                                  image={productImage}
                                  alt="Product Image"
                                  height="240"
                                  title="Product Image"
                                />
                                <CardContent>
                                  <Typography variant="body1" align="center">
                                    {`${manufacturer_name} -  ${trade_name}`}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </div>
                          </td>
                        </tr>
                      ) : undefined}
                      {/* {disclaimer && (
                      <tr>
                        <td></td>
                        <td className="product-disclaimer">* {disclaimer}</td>
                      </tr>
                      )} */}
                    </tbody>
                  </table>
                  {disclaimer && (
                    <div className="product-disclaimer">* {disclaimer}</div>
                  )}
                </div>
              </Paper>

              <div className="print-button">
                {user.user_id && user.access_type_cd !== 'c' && (
                  <IconButton
                    onClick={(e) => handleUpdatePrintQueue(product_uuid)}
                  >
                    <Badge badgeContent="+" className="product__badge">
                      <PrintIcon className="product__badge" />
                    </Badge>
                  </IconButton>
                )}
              </div>
              <div style={{ marginTop: '20px', textAlign: 'center' }}>
                {user.user_id && user.access_type_cd !== 'c' && (
                  <UpdateProduct
                    productDescription={description}
                    productImage={productImage}
                    open={open}
                  />
                )}
                {user.access_type_cd === 'a' ? (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => setShowStatsDialog(true)}
                      style={{
                        backgroundColor: '#FFFFFF',
                        width: '200px',
                        margin: '10px',
                      }}
                    >
                      View Product Stats
                    </Button>
                    <ProductStatsDialog
                      product={product}
                      isOpen={showStatsDialog}
                      showLocationSelection={true}
                      handleOnClose={() => setShowStatsDialog(false)}
                    />
                  </>
                ) : undefined}
                {user.user_id && user.access_type_cd !== 'c' ? (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => setShowLeadDialog(true)}
                      style={{
                        backgroundColor: '#FFFFFF',
                        width: '200px',
                        margin: '10px',
                      }}
                    >
                      Quick Lead Tools
                    </Button>
                    <Dialog open={showLeadDialog}>
                      <DialogContent>
                        <CreateLeadForm
                          user={user}
                          isOpen={showLeadDialog}
                          formType={'both'}
                          isModal={true}
                          handleOnClose={() => setShowLeadDialog(false)}
                        />
                      </DialogContent>
                    </Dialog>
                  </>
                ) : undefined}
              </div>
              <div>
                <span className="product__icon-container">
                  {Number(prodFavSwitchState) === 1 && (
                    <Button
                      className="product__icon-buttons"
                      onClick={() => onClickfavorite(productId, productSku)}
                    >
                      <Chip
                        icon={
                          <FavoriteSharpIcon style={{ color: favIconColor }} />
                        }
                        label={favIconLabel}
                        fontSize="large"
                        className="product__like_icon"
                        variant="outlined"
                      />
                    </Button>
                  )}
                  {Number(prodCheckoutSwitchState) === 1 &&
                    (userAccessType === 'c' ? (
                      <Button
                        className="product__icon-buttons"
                        onClick={onClickCheckout}
                      >
                        <Chip
                          icon={
                            <LibraryAddCheckIcon
                              style={{ color: checkoutIconColor }}
                            />
                          }
                          label={checkoutIconLabel}
                          variant="outlined"
                        />
                      </Button>
                    ) : checkoutList[
                        checkoutList.findIndex((e) => e.user_id === userId)
                      ] !== undefined ? (
                      checkoutList.length > 0 &&
                      checkoutList[
                        checkoutList.findIndex((e) => e.user_id === userId)
                      ].status_name === 'Pending' &&
                      checkoutList.find((u) => u.user_id === userId) ? (
                        <Button
                          className="product__icon-buttons"
                          onClick={onClickCheckout}
                        >
                          <Chip
                            icon={
                              <LibraryAddCheckIcon
                                style={{ color: checkoutIconColor }}
                              />
                            }
                            label={checkoutIconLabel}
                            variant="outlined"
                            onDelete={() => {
                              handleCancelCheckout(checkoutId);
                            }}
                          />
                        </Button>
                      ) : (
                        <Button
                          className="product__icon-buttons"
                          onClick={onClickCheckout}
                        >
                          <Chip
                            icon={
                              <LibraryAddCheckIcon
                                style={{ color: checkoutIconColor }}
                              />
                            }
                            label={checkoutIconLabel}
                            variant="outlined"
                            className="product__checkout_icon"
                          />
                        </Button>
                      )
                    ) : (
                      <Button
                        className="product__icon-buttons"
                        onClick={onClickCheckout}
                      >
                        <Chip
                          icon={
                            <LibraryAddCheckIcon
                              style={{ color: checkoutIconColor }}
                            />
                          }
                          label={checkoutIconLabel}
                          variant="outlined"
                          className="product__checkout_icon"
                        />
                      </Button>
                    ))}
                  {/* <LibraryAddCheckIcon fontSize="large" className="product__checkout_icon"/> */}
                </span>
              </div>
              <div className="qrcode">
                <QRcode
                  value={`${window.location.href}`}
                  size={160}
                  bgColor={'#DFDCDC'}
                />
              </div>
            </div>
          </div>
          <div className="notes">
            <p className="product-notes">{productDisclaimer}</p>
          </div>
          <div className="footer">
            <Typography variant="subtitle2">
              <span>&#169;</span> {new Date().getFullYear()} Powered by{' '}
              <a
                href="https://showroompricing.com"
                target="_blank"
                rel="noreferrer noopener"
                style={{ textDecoration: 'none', color: '#000' }}
              >
                Showroom Pricing.
              </a>
              <span> Patent pending </span>
            </Typography>
          </div>
          {renderPending()}
          {renderPendingMobile()}
        </div>
      );
    } else {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Fab
            size="medium"
            variant="extended"
            style={{
              zIndex: '20',
              position: 'fixed',
              bottom: '0px',
              right: '0px',
              padding: '0px 12px',
              color: '#28C4FC',
              backgroundColor: '#142E3E',
              margin: '20px',
            }}
            onClick={() => setShowScannerModal(true)}
          >
            <Camera fontSize="small" style={{ marginRight: '5px' }} />
            Scan QR
          </Fab>
          <ScanningModal
            isOpen={showScannerModal}
            handleOnClose={() => setShowScannerModal(false)}
          />
          <MockProduct prog={progress} />
        </div>
      );
    }
  };

  return renderProductInfo(product);
};

export default withRouter(Product);
